import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {IonSlides, ModalController, NavParams} from '@ionic/angular';

@Component({
    selector: 'page-image-modal',
    templateUrl: './image-modal.html',
    styleUrls: ['./image-modal.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImageModalPage implements OnInit {

    @ViewChild('pinchZoom', {static: false}) pinch;
    img        : string;
    properties : any;

    constructor(
        private modalController: ModalController,
        private navParams: NavParams,
    ) {
        this.properties = {
            overflow: 'hidden',
            disableZoomControl: 'auto',
            limitPan: true,
            fullImage: {path: this.img, minScale: 3},
        };
    }

    ngOnInit() {
        this.img = this.navParams.get('img');

    }

    zoom() {
        this.pinch.toggleZoom();
    }

    close() {
        this.pinch.destroy();
        this.modalController.dismiss()
            .then(() => {});
    }

}
