<ion-slides #slides [pager]="isBannerSlider()" [options]="sliderOptions"
            (ionSlidesDidLoad)="slideDidLoaded(slides, slider)"
            (ionSlideDidChange)="slideDidChanged(slides, slider)">
    <ion-slide *ngFor="let item of _items; index as i;">
        <aspect-ratio [ratio]="{w:1920,h:1080}">
            <ion-card class="box-shadow" [class.banner]="isBannerSlider()" (click)="onClick(item)"
                      [ngStyle]="formatBackground(item['src'])">
                <ng-container [ngSwitch]="type">
                    <div *ngSwitchCase="'banner'"></div>
                    <div *ngSwitchCase="'grid'" class="grid-container">
                        <ion-icon [src]="item.icon" *ngIf="item.icon" class="icon-large"></ion-icon>
                        <ion-text [innerHTML]="item.title"></ion-text>
                    </div>
                </ng-container>
            </ion-card>
        </aspect-ratio>
    </ion-slide>
</ion-slides>
