import {Injectable, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import * as Color from 'color';
import {Storage} from '@ionic/storage-angular';
import {DateService} from '../core/services/dateService';
import * as Constants from '../configs/constants';
// import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';

const defaults = {
    primary: '#3880ff',
    secondary: '#0cd1e8',
    tertiary: '#7044ff',
    success: '#10dc60',
    warning: '#ffce00',
    danger: '#f04141',
    dark: '#222428',
    medium: '#989aa2',
    light: '#f4f5f8',
};

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    constructor(
        @Inject(DOCUMENT)
        private document    : Document,
//        private statusBar   : StatusBar,
        private storage     : Storage ,
        private dateService : DateService,
    ) {
        this.init();
    }

    async init() {
        const storage = await this.storage.create();
        this.storage = storage;       
    }

    retrieveTheme() {
        // console.log('retrieve theme : ');
        // this.storage.get('theme').then(cssText => {  // <--- GET SAVED THEME
        //     console.log('saved theme : ', cssText);
        //     if (cssText !== undefined && cssText !== null) {
        //         this.setGlobalCSS(cssText);
        //     }
        // });
    }

    // Override all global variables with a new theme
    setTheme(theme) {
        const cssText = this.CSSTextGenerator(theme);
        this.setGlobalCSS(cssText);
        this.storage.set('theme', cssText).then(() => {}); // <--- SAVE THEME HERE
    }

    // Define a single CSS variable
    setVariable(name, value) {
        this.document.documentElement.style.setProperty(name, value);
    }

    private setGlobalCSS(css: string) {
        this.document.documentElement.style.cssText = css;
    }

    contrast(color, ratio = 0.8) {
        color = Color(color);
        return color.isDark() ? color.lighten(ratio) : color.darken(ratio);
    }

    CSSTextGenerator(colors) {
        colors = {...defaults, ...colors};

        const {
            primary,
            secondary,
            tertiary,
            success,
            warning,
            danger,
            dark,
            medium,
            light,
        } = colors;

        const shadeRatio = 0.1;
        const tintRatio = 0.15;

        let primaryDark = colors.primaryDark;

        return `
            --ion-color-base: ${light};
            --ion-color-contrast: ${dark};

            --ion-color-primary: ${primary};
            --ion-color-primary-rgb: ${Color(primary).rgb().string()};
            --ion-color-primary-contrast: ${colors.contrast};
            --ion-color-primary-contrast-rgb: 255,255,255;
            --ion-color-primary-shade: ${Color(primary).darken(shadeRatio)};
            --ion-color-primary-tint: ${Color(primary).lighten(tintRatio)};
            --ion-color-primary-dk: ${primaryDark};
        `;
        // unused :
        // --ion-color-primary-contrast: ${this.contrast(primary)};
    }

    /* ==================================================================================================== */
    /* Operations */

    configTheme() {
        // // get current theme by date
        let theme = Constants.THEME.normal;
        if (this.dateService.inRange(Constants.LENT_START_DAY,Constants.LENT_END_DAY)) {
            theme = Constants.THEME.lent;
        }
        if (this.dateService.inRange(Constants.EASTER.RANGE.STAGE_1.START_DAY,Constants.EASTER.RANGE.STAGE_1.END_DAY)) {
            theme = Constants.THEME.easterStage1;
        }
        if (this.dateService.inRange(Constants.EASTER.RANGE.STAGE_2.START_DAY,Constants.EASTER.RANGE.STAGE_2.END_DAY)) {
            theme = Constants.THEME.easterStage2;
        }
        if (this.dateService.inRange(Constants.EASTER.RANGE.STAGE_3.START_DAY,Constants.EASTER.RANGE.STAGE_3.END_DAY)) {
            theme = Constants.THEME.easterStage3;
        }
        // set theme
        this.setTheme(theme);
        // this.statusBar.backgroundColorByHexString(theme.primary);
    }

}
