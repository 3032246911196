import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'isList'
})
export class IsListPipe implements PipeTransform {

    transform(items: any): any {
        return items.some(item => item.type.includes('list'));
    }

}
