import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
    /* ==================================================================================================== */
    /* Initial Routes */
    // { path: '', redirectTo: '/lentCard/tabs/LentOverview', pathMatch: 'full'},
    // { path: '', redirectTo: '/home/tabs', pathMatch: 'full'},
    { path: '', redirectTo: '/home/tabs/main', pathMatch: 'full'},
    // { path: '', redirectTo: '/home/tabs/festival', pathMatch: 'full'},
    // { path: '', redirectTo: '/home/tabs/easter', pathMatch: 'full'},


    /* ==================================================================================================== */
    /* Home Routes */

    // Group 0 : Home Pages
    { path: 'home',               loadChildren: () => import('./pages/home/home-tabs/home-tabs.module').then(m => m.TabsHomePageModule) },

    /* ==================================================================================================== */
    /* General Routes */

    // Group 1 : News Pages
    { path: 'notifications',      loadChildren: () => import('./pages/notifications/notification-list/notification-list.module').then(m => m.NotificationListModule) },
    { path: 'notifications/:id',  loadChildren: () => import('./pages/notifications/notification-detail/notification-detail.module').then(m => m.NotificationDetailModule) },
    { path: 'hymn',               loadChildren: () => import('./pages/hymn/hymn-list/hymn-list.module').then(m => m.HymnListPageModule) },
    { path: 'hymn/:id',           loadChildren: () => import('./pages/hymn/hymn-detail/hymn-detail.module').then(m => m.HymnDetailPageModule) },

    // Group 2: Life Pages
    { path: 'bible',              loadChildren: () => import('./pages/verse/verse.module').then(m => m.VerseModule) },
    { path: 'verses',             loadChildren: () => import('./pages/verse/daily-verse-list/daily-verse-list.module').then(m => m.DailyVerseListPageModule) },
    { path: 'devotionCollection', loadChildren: () => import('./pages/devotion/devotion-collection/devotion-collection.module').then(m => m.DevotionCollectionModule) },
    { path: 'devotion-series',    loadChildren: () => import('./pages/devotion/devotion-personal-list/devotion-personal-list.module').then(m => m.DevotionPersonalListModule) },
    { path: 'devotion',           loadChildren: () => import('./pages/devotion/devotion-daily-detail/devotion-daily-detail.module').then(m => m.DevotionDailyDetailModule) },
    { path: 'devotion/:id',       loadChildren: () => import('./pages/devotion/devotion-daily-detail/devotion-daily-detail.module').then(m => m.DevotionDailyDetailModule) },
    { path: 'prayer',             loadChildren: () => import('./pages/prayer/prayer-main/prayer-main.module').then(m => m.PrayerMainModule) },

    // Group 3: Church Pages
    { path: 'pastor',             loadChildren: () => import('./pages/sharing/pastor-sharing/pastor-sharing.module').then(m => m.PastorSharingModule) },
    { path: 'announcement',       loadChildren: () => import('./pages/announcement/announcement.module').then(m => m.AnnouncementModule) },
    { path: 'vh',                 loadChildren: () => import('./pages/sharing/vh-sharing-list/vh-sharing-list.module').then(m => m.VertiHoriSharingListModule) },
    { path: 'vh/:id',             loadChildren: () => import('./pages/sharing/vh-sharing-detail/vh-sharing-detail.module').then(m => m.VertiHoriSharingDetailModule) },
    { path: 'festivals',          loadChildren: () => import('./pages/church/festivals/festival-list.module').then(m => m.FestivalListModule) },

    // Group 4: e Pages
    // { path: 'channel',           loadChildren: () => import('./pages/channel/channel-list/channel-list.module').then(m => m.ChannelListPageModule) },
    // { path: 'channel/:id',       loadChildren: () => import('./pages/channel/channel-detail/channel-detail.module').then(m => m.ChannelDetailPageModule) },

    // Group 5: Settings Pages
    { path: 'faq',                loadChildren: () => import('./pages/faq/faq.module').then(m => m.FAQModule) },
    { path: 'feedback',           loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackModule) },
    { path: 'settings',           loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule) },
    { path: 'disclaimer',         loadChildren: () => import('./pages/disclaimer/disclaimer.module').then(m => m.DisclaimerModule) },
    { path: 'login',              loadChildren: () => import('./pages/member/login/login.module').then(m => m.LoginModule) },

    // Group 6: Member Pages
    { path: 'profile',            loadChildren: () => import('./pages/member/profile/profile.module').then(m => m.ProfileModule) },
    { path: 'card',               loadChildren: () => import('./pages/card/card.module').then(m => m.CardModule) },
    { path: 'verify',             loadChildren: () => import('./pages/member/verify/verify.module').then(m => m.VerifyModule) },

    // Group 999: Other Pages
    { path: 'seed',               loadChildren: () => import('./pages/seed/seed.module').then(m => m.SeedModule) },
    { path: 'iframe',             loadChildren: () => import('./pages/iframe/iframe.module').then(m => m.IframeModule) },
    { path: 'web',                loadChildren: () => import('./pages/web/web.module').then(m => m.WebModule) },

    /* ==================================================================================================== */
    /* Festival Routes */
    { path: 'lentWeb',            loadChildren: () => import('./pages/festival/web-lent/web-lent.module').then(m => m.LentWebModule)                    },
    { path: 'lentCard',           loadChildren: () => import('./pages/festival/card-lent/card-lent.module').then(m => m.LentCardModule)                 },
    { path: 'lentSummary',        loadChildren: () => import('./pages/festival/summary-lent/summary-lent.module').then(m => m.LentSummaryModule)        },
    { path: 'lentSummary/:id',    loadChildren: () => import('./pages/festival/summary-lent/summary-lent.module').then(m => m.LentSummaryModule)        },
    { path: 'lentResult',         loadChildren: () => import('./pages/festival/result-lent/result-lent.module').then(m => m.LentResultModule)           },
    { path: 'lentTutorial',       loadChildren: () => import('./pages/festival/tutorial-lent/tutorial-lent.module').then(m => m.LentTutorialPageModule) },
    { path: 'lentLanding',        loadChildren: () => import('./pages/festival/landing-lent/landing-lent.module').then(m => m.LentLandingPageModule)    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
