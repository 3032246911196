<ion-slides #slides [pager]="multiple" [options]="setupSlideOption()"
            (ionSlidesDidLoad)="slideDidLoaded(slides, slider)"
            (ionSlideDidChange)="slideDidChanged(slides, slider)">
    <ion-slide *ngFor="let item of _items | orderBy: '-order'; trackBy: trackById;">
        <ng-container *ngIf="item | isBanner">
            <app-banner [item]="item"
                        (openLink)="clickLink($event)"></app-banner>
        </ng-container>
    </ion-slide>
</ion-slides>
