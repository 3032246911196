import {Component, Input} from '@angular/core';

@Component({
    selector: 'block-grid',
    templateUrl: './block-grid.component.html',
    styleUrls: ['./block-grid.component.scss'],
})
export class BlockGridComponent {

    _color: string;
    _icon: string;
    _title: string;

    @Input()
    set color(value: string) {
        this._color = (value !== undefined && value !== null) ? value : '';
    }
    @Input()
    set icon(value: string) {
        this._icon = (value !== undefined && value !== null) ? value : '';
    }
    @Input()
    set title(value: string) {
        this._title = (value !== undefined && value !== null) ? value : '';
    }

    constructor() {}

}
