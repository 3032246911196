import { Injectable } from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore, doc, docData } from '@angular/fire/firestore';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {Banner} from '../../models/banner.model';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BannerService extends BaseService {

    collection_key : string;

    constructor(
        // protected http        : HTTP,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
        this.collection_key = Constants.COLLECTION_BANNERS;
    }

    getBanners(filter: boolean, current: string): Observable<any> {
        return docData(doc(this.fireStore, this.collection_key + "/" + this.dataService.getAPIEnv())).pipe(
            map(values => {
                return values['home']
                    .map(value => Banner.init(value))
                    .sort(this.dataService.compareValues(Constants.SORT_ORDER));
            }),
            map(items => {
                return (filter) ?
                    items.filter(item =>
                        item.src !== null && item.src.length > 0 &&
                        item.show && this.dateService.validDate(item.start_time, item.end_time)) :
                    items;
            }),
            // tap(items => this.logFSResult<Banner>(this.collection_key, items)),
            distinctUntilChanged(),
        );

        // return this.fireStore
        //     .collection<Banner>(this.collection_key)
        //     .doc(this.dataService.getAPIEnv())
        //     .snapshotChanges().pipe(
        //         map(values => {
        //             return values.payload.data()['home']
        //                 .map(value => Banner.init(value))
        //                 .sort(this.dataService.compareValues(Constants.SORT_ORDER));
        //         }),
        //         map(items => {
        //             return (filter) ?
        //                 items.filter(item =>
        //                     item.src !== null && item.src.length > 0 &&
        //                     item.show && this.dateService.validDate(item.start_time, item.end_time)) :
        //                 items;
        //         }),
        //         // tap(items => this.logFSResult<Banner>(this.collection_key, items)),
        //         distinctUntilChanged(),
        //     );
    }

}
