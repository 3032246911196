<ng-template #loadingTemplate>
    <div class="empty-list">
        載入中⋯⋯
    </div>
</ng-template>
<ng-template #emptyTemplate>
    <div class="empty-vlist">
        暫無內容
        <span class="empty-label">
            請下拉更新。
        </span>
    </div>
</ng-template>
