import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ModalService} from '../../providers/modalService';
import {Alert} from '../../models/alert.model';

@Component({
    selector: 'alert-item',
    templateUrl: './alert-item.component.html',
    styleUrls: ['./alert-item.component.scss'],
})
export class AlertItemComponent implements AfterViewInit {

    @Input('item') _item: Alert;
    @ViewChild('alertContainer') content: ElementRef;
    imgCollection: HTMLCollection;
    anchorCollection: HTMLCollection;

    constructor(
        private modalService: ModalService,
    ) {
    }

    ngAfterViewInit() {
        // handle img click
        this.imgCollection = this.content.nativeElement.getElementsByTagName('img');
        for (let index = 0; index < this.imgCollection.length; index++) {
            let targetElement = (<HTMLImageElement>this.imgCollection[index]);
            if (targetElement.classList.contains('zoomable')) {
                const clickListener = () => {
                    this.modalService.presentImageModal(targetElement.src).then(() => {});
                }
                targetElement.addEventListener('click', clickListener);
            }
        }

        // handle anchor color
        this.anchorCollection = this.content.nativeElement.getElementsByTagName('a');
        for (let index = 0; index < this.anchorCollection.length; index++) {
            let targetElement = (<HTMLAnchorElement>this.anchorCollection[index]);
            targetElement.style.setProperty('color', this._item.linkColor);
            console.log('target: ', targetElement);
        }
    }

}
