import {Pipe, PipeTransform} from '@angular/core';
import {Banner} from '../../models/banner.model';

@Pipe({
    name: 'typeof'
})
export class TypeofPipe implements PipeTransform {

    // transform(value: unknown, ...args: unknown[]): unknown {
    //     return null;
    // }

    transform(value: any): any {
        console.log("Pipe works ", value instanceof Banner);
        return value instanceof Banner;
    }

}
