import {Injectable} from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore, collection, collectionData, doc, docData } from '@angular/fire/firestore';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, take, tap} from 'rxjs/operators';
// import { getDocs } from 'firebase/firestore';

@Injectable({
    providedIn: 'root'
})
export class SettingService extends BaseService {

    constructor(
        // protected http        : HTTP,
        protected httpClient  : HttpClient,
//         protected fireStore   : AngularFirestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
        protected fireStore : Firestore,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
    }

    getAppSettings(): Observable<any> {
        const docRef = doc(this.fireStore, Constants.COLLECTION_APP_SETTINGS + '/' + this.dataService.getAPIEnv());
        console.log(docData(docRef));
        return docData(docRef);
//         return this.fireStore
//             .collection<any>(Constants.COLLECTION_APP_SETTINGS)
//             .doc(this.dataService.getAPIEnv())
//             .snapshotChanges().pipe(
//                 map(values => values.payload.data()),
// //                tap(items => this.logFSResult<any>(Constants.COLLECTION_APP_SETTINGS, [items])),
//                 distinctUntilChanged(),
//             );
    }

    getAppMenu(): Observable<any> {
        let docPath = `${Constants.COLLECTION_APP_MENU}-${this.dataService.getAPIEnv()}`;
        const collectionRef = collection(this.fireStore, docPath);
        return collectionData(collectionRef);

//         return this.fireStore
//             .collection<any>(docPath)
//             .snapshotChanges().pipe(
//                 map(values => values.map(value => value.payload.doc.data())),
// //                tap(output => console.log('api env: ', docPath)),
//                 // tap(items => this.logFSResult<any>(Constants.COLLECTION_APP_MENU, items)),
//                 distinctUntilChanged(),
//             );
    }

    getFlags(): Observable<any> {
        const docRef = doc(this.fireStore, Constants.COLLECTION_APP_SETTINGS + "/" + this.dataService.getAPIEnv())
        return docData(docRef).pipe(
            map(values => values.payload.data()['flagControl']),
            distinctUntilChanged(),
        );

        // return this.fireStore
        //     .collection<any>(Constants.COLLECTION_APP_SETTINGS)
        //     .doc(this.dataService.getAPIEnv())
        //     .snapshotChanges().pipe(
        //         map(values => values.payload.data()['flagControl']),
        //         distinctUntilChanged(),
        //     );
    }

    getApiControls(): Observable<any> {
        const docRef = doc(this.fireStore, Constants.COLLECTION_API_CONTROLS + "/" + this.dataService.getAPIEnv() )
        return docData(docRef);
        // return this.fireStore
        //     .collection<any>(Constants.COLLECTION_API_CONTROLS)
        //     .doc(this.dataService.getAPIEnv())
        //     .snapshotChanges().pipe(
        //         map(values => values.payload.data()),
        //         // tap(items => this.logFSResult<any>(Constants.COLLECTION_APP_SETTINGS, [items])),
        //         distinctUntilChanged(),
        //     );
    }

}
