import * as moment from 'moment';
import * as Constants from '../configs/constants';

export class Sharing {
    id          : number;
    date        : string;
    dateMonth   : string;
    link        : string;
    title       : string;
    content     : string;
    excerpt     : string;
    feature_img : string;

    constructor(id?: number, date?: string, link?: string, title?: string, content?: string, excerpt?: string, feature_img?: string) {
        this.id          = id;
        this.date        = date;
        this.link        = link;
        this.title       = title;
        this.content     = content;
        this.excerpt     = excerpt;
        this.feature_img = feature_img;
        this.dateMonth   = '';
    }

    static init(value: any) {
        let instance = new Sharing();
        if (value == null) return instance;
        instance.id          = value.id;
        instance.date        = instance.formatDate(value.date) || '';
        instance.dateMonth   = instance.formatDate(value.date, Constants.DATE_FORMAT_YM) || '';
        instance.link        = value.link                      || '';
        instance.title       = value.title.rendered            || '';
        instance.content     = value.content.rendered          || '';
        instance.excerpt     = value.excerpt.rendered          || '';
        instance.feature_img = value.fimg_url                  || '';
        return instance;
    }

    formatDate(date:string, format: string = Constants.TIMESTAMP_FORMAT_EN) {
        return moment(date).format(format);
    }
}
