import { Injectable } from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore, collection, collectionData, doc, docData, addDoc, deleteDoc, updateDoc, snapToData } from '@angular/fire/firestore';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {Grid} from '../../models/grid.model';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GridService extends BaseService {

    collection_key : string;

    constructor(
        // protected http        : HTTP,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
        this.collection_key = Constants.COLLECTION_GRIDS;
    }

    getHomeGrids(): Observable<Grid[]> {
        return docData(doc(this.fireStore, this.collection_key + "/" + this.dataService.getAPIEnv())).pipe(
            map(values => {
                return values['home']
                    .filter(value => value.show)
                    .map(value => Grid.init(value))
                    .sort(this.dataService.compareValues(Constants.SORT_ORDER));
            }),
            distinctUntilChanged(),
        );
        // return this.fireStore
        //     .collection<Grid>(this.collection_key)
        //     .doc(this.dataService.getAPIEnv())
        //     .snapshotChanges().pipe(
        //         map(values => {
        //             return values.payload.data()['home']
        //                 .filter(value => value.show)
        //                 .map(value => Grid.init(value))
        //                 .sort(this.dataService.compareValues(Constants.SORT_ORDER));
        //         }),
        //         distinctUntilChanged(),
        //     );
    }

    getEventGrids(filtered: boolean): Observable<Grid[]> {
        return docData(doc(this.fireStore, this.collection_key + "/" + this.dataService.getAPIEnv())).pipe(
            map(values => {
                return values['event']
                    .filter(value => (filtered) ? value.show : true)
                    .map(value => Grid.init(value))
                    .sort(this.dataService.compareValues(Constants.SORT_ORDER));
            }),
            distinctUntilChanged(),
        );

        // return this.fireStore
        //     .collection<Grid>(this.collection_key)
        //     .doc(this.dataService.getAPIEnv())
        //     .snapshotChanges().pipe(
        //         map(values => {
        //             return values.payload.data()['event']
        //                 .filter(value => (filtered) ? value.show : true)
        //                 .map(value => Grid.init(value))
        //                 .sort(this.dataService.compareValues(Constants.SORT_ORDER));
        //         }),
        //         // map(items => {
        //         //     return (filtered) ?
        //         //         items.filter(item =>
        //         //             item.src !== null && item.src.length > 0 &&
        //         //             item.show) :
        //         //         items;
        //         // }),
        //         distinctUntilChanged(),
        //     );
    }

}
