export class Notification {
    id           : number;
    level        : number;
    topic        : string;
    label        : string;
    title        : string;
    excerpt      : string;
    content      : string;
    bg           : string;
    link         : string;
    show         : boolean;
    read         : boolean;
    release_time : string;
    expired_time : string;
    fgColor      : string;
    icon         : string;
    revisions    : number;

    constructor(id?: number, level?: number, topic?: string, label?: string, title?: string, excerpt?: string, content?: string, bg?: string, link?: string, show?: boolean, read?: boolean, release_time?: string, expired_time?: string) {
        this.id           = id;
        this.level        = level;
        this.topic        = topic;
        this.label        = label;
        this.title        = title;
        this.excerpt      = excerpt;
        this.content      = content;
        this.bg           = bg;
        this.link         = link;
        this.show         = show;
        this.read         = read;
        this.release_time = release_time;
        this.expired_time = expired_time;
        this.fgColor      = '';
        this.icon         = '';
        this.revisions    = 0;
    }

    static init(value: any) {
        let instance = new Notification();
        instance.id           = value.id;
        instance.level        = (value.level > 0) ? value.level : (value.level === 0) ? 0 : -1;
        instance.topic        = value.topic        || '';
        instance.label        = value.label        || '';
        instance.title        = value.title        || '';
        instance.excerpt      = value.excerpt      || '';
        instance.content      = value.content      || '';
        instance.bg           = value.bg           || '';
        instance.link         = value.link         || '';
        instance.show         = value.show         || false;
        instance.read         = value.read         || false;
        instance.release_time = value.release_time || '';
        instance.expired_time = value.expired_time || '';
        instance.fgColor      = value.fgColor      || '';
        instance.icon         = value.icon         || '';
        instance.revisions++;
        return instance;
    }
}
