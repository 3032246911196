import {AfterViewInit, Directive, ElementRef, HostListener, Renderer2} from '@angular/core';
import {ModalService} from '../../providers/modalService';

@Directive({
    selector: '[zoomable]'
})
export class ZoomableImageDirective implements AfterViewInit {

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private modalService: ModalService,
    ) {
    }

    ngAfterViewInit(): void {
    }

    @HostListener('click', ['$event'])
    clickEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        let imageElement = (<HTMLImageElement>this.elementRef.nativeElement)
        this.modalService.presentImageModal(imageElement.src).then(() => {});
    }

}
