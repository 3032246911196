export class Page {
    id         : number  ;
    parent_id  : number  ;
    order      : number  ;
    show       : boolean ;
    open       : boolean ;
    online     : boolean ;
    native     : boolean ;
    menu_title : string  ;
    page_title : string  ;
    icon       : string  ;
    url        : string  ;
    children   : Page[]  ;

    constructor(id?: number, parent_id?: number, order?: number, show?: boolean, open?: boolean, online?: boolean, native?: boolean, menu_title?: string, page_title?: string, icon?: string, url?: string, children?: Page[]) {
        this.id         = id;
        this.parent_id  = parent_id;
        this.order      = order;
        this.show       = show;
        this.open       = open;
        this.online     = online;
        this.native     = native;
        this.menu_title = menu_title;
        this.page_title = page_title;
        this.icon       = icon;
        this.url        = url;
        this.children   = children;
    }

    static init(value: any) {
        let instance = new Page();
        instance.id         = (value.id > 0) ? value.id : (value.id === 0) ? 0 : -1;
        instance.parent_id  = (value.parent_id > 0) ? value.parent_id : (value.parent_id === 0) ? 0 : -1;
        instance.order      = value.order;
        instance.show       = value.show       || false ;
        instance.open       = value.open       || false ;
        instance.online     = value.online     || false ;
        instance.native     = value.native     || false ;
        instance.menu_title = value.menu_title || ''    ;
        instance.page_title = value.page_title || ''    ;
        instance.icon       = value.icon       || ''    ;
        instance.url        = value.url        || ''    ;
        instance.children   = value.children   || []    ;
        return instance;
    }

}
