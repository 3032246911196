import {Injectable} from '@angular/core';
import {
    ActionSheetController,
    AlertController,
    LoadingController, MenuController, ModalController, PopoverController,
    ToastController
} from '@ionic/angular';
// import {SocialSharePage} from '../pages/social-share/social-share';
// import {ListPopoverPage} from '../pages/popovers/list-popover/list-popover';

@Injectable({
    providedIn: 'root',
})
export class MessageService {

    isPresenting: boolean = false;
    alerts      : any;
    // currentAlert: any;
    canDeactivate: boolean;

    constructor(
        public actionSheetCtrl : ActionSheetController,
        public alertCtrl       : AlertController,
        public loadingCtrl     : LoadingController,
        public toastCtrl       : ToastController,
        public popoverCtrl     : PopoverController,
        public modalCtrl       : ModalController,
        public menuCtrl        : MenuController,
    ) {
        this.alerts = [];
        this.canDeactivate = true;
    }

    /* ==================================================================================================== */
    /* Dismiss */

    async dismissTop() {
        // close action sheet
        try {
            const element = await this.actionSheetCtrl.getTop();
            if (element !== undefined) {
                element.dismiss();
                this.canDeactivate = false;
            }
        } catch (error) {
        }

        // close alert
        try {
            const element = await this.alertCtrl.getTop();
            if (element !== undefined) {
                element.dismiss();
                this.canDeactivate = false;
            }
        } catch (error) {
        }

        // close loading
        try {
            const element = await this.loadingCtrl.getTop();
            if (element !== undefined) {
                element.dismiss();
                this.canDeactivate = false;
            }
        } catch (error) {
        }

        // close popover
        try {
            const element = await this.popoverCtrl.getTop();
            if (element !== undefined) {
                element.dismiss();
                this.canDeactivate = false;
            }
        } catch (error) {
        }

        // close modal
        try {
            const element = await this.modalCtrl.getTop();
            if (element !== undefined) {
                element.dismiss();
                this.canDeactivate = false;
            }
        } catch (error) {
            console.log(error);

        }

        // close side menu
        try {
            const element = await this.menuCtrl.getOpen();
            if (element !== undefined) {
                this.menuCtrl.close();
                this.canDeactivate = false;
            }
        } catch (error) {
        }

        return this.canDeactivate;
    }

    /* ==================================================================================================== */
    /* Alert */

    async getTopAlert() {
        return await this.alertCtrl.getTop()
    }

    async presentGeneralAlert(header, subHeader, message, buttons: any = [], backdropDismiss: boolean = false, cssClass: string = '') {
        // handle alert buttons
        if (!buttons.length) {
            buttons.push('好的');
        }

        // handle alert options
        let alertOptions: any = {
            header: header,
            subHeader: subHeader,
            message: message,
            buttons: buttons,
            backdropDismiss: backdropDismiss,
            cssClass: (buttons.length == 2) ? 'alert-confirmation' : cssClass,
        };

        this.dismissAllAlerts();
        let alert = await this.alertCtrl.create(alertOptions);
        this.alerts.push(alert);
        return await alert.present();
    }
    async presentOptionsAlert(header, subheader, message, buttons) {
        this.isPresenting = true;
        return await this.alertCtrl.create({
            header: header,
            subHeader: subheader,
            message: message,
            buttons: buttons,
            backdropDismiss: false,
        }).then(alert => {
            alert.present().then(() => {
                if (!this.isPresenting) {
                    alert.dismiss();
                }
            });
        });
    }
    async presentTimeoutAlert(header, subheader, message, buttons) {
        return await this.alertCtrl.create({
            header: header,
            subHeader: subheader,
            message: message,
            buttons: buttons,
            backdropDismiss: false,
        }).then(alert => {
            alert.present().then(() => {
                if (!this.isPresenting) {
                    alert.dismiss();
                }
            });
        });
    }
    async presentPromptAlert(header, message, inputs, buttons) {
        let alertOptions = {
            header: header,
            message: message,
            inputs: inputs,
            buttons: buttons,
        };

        this.dismissAllAlerts();
        let alert = await this.alertCtrl.create(alertOptions);
        this.alerts.push(alert);
        return await alert.present();
    }

    async presentUpdateArchiveAlert(header, message) {
        return new Promise(async (resolve) => {
            let alertOptions = {
                header: header,
                message: message,
                buttons: [
                    {
                        text: '稍後',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => resolve(false)
                    },
                    {
                        text: '前往',
                        handler: () => resolve(true)
                    }
                ],
            };
            let alert = await this.alertCtrl.create(alertOptions);
            await alert.present();
        });
    }

    dismissAllAlerts() {
        for (let alert of this.alerts) {
            if (alert) {
                alert.dismiss()
                    .then(() => {});
            }
        }
        this.alerts = [];
    }

    /* ==================================================================================================== */
    /* Loading */

    async presentNewLoading() {
        const loading = await this.loadingCtrl.create({
            message: '載入中...',
        });
        return loading.present();
    }

    async presentLoading() {
        this.isPresenting = true;
        return await this.loadingCtrl.create({
            message: "載入中...",
        }).then(loading => {
            loading.present().then(() => {
                if (!this.isPresenting) {
                    loading.dismiss();
                }
            });
        });
    }

    async presentLoadingWithTimeout() {
        // when present more than 7s,
        // dismiss with followUp.
    }

    async presentCustomLoading(msg) {
        this.isPresenting = true;
        return await this.loadingCtrl.create({
            message: msg,
            duration: 2000
        }).then(loading => {
            loading.present().then(() => {
                if (!this.isPresenting) {
                    loading.dismiss();
                }
            });
        });
    }

    async dismissLoading() {
        this.isPresenting = false;
        // return this.loadingCtrl.getTop().then(v => v ? this.loadingCtrl.dismiss() : null);
        return await this.loadingCtrl.dismiss();
    }

    /* ==================================================================================================== */
    /* Toast */

    async presentToast(message) {
        const toast = await this.toastCtrl.create({
            message,
            duration: 5000,
        });
        return await toast.present();
    }

    /* ==================================================================================================== */
    /* Popover */

    // async presentPopover(params: any) {
    //     // const pop = await this.popoverCtrl.create({
    //     //     component: SocialSharePage,
    //     //     componentProps: {
    //     //         popoverController: this.popoverCtrl,
    //     //         params: params,
    //     //     }
    //     // });
    //     // return await pop.present();
    // }

}
