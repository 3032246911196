import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';

@Component({
    selector: 'meeting-item',
    templateUrl: './meeting-item.component.html',
    styleUrls: ['./meeting-item.component.scss'],
})
export class MeetingItemComponent {

    /* ==================================================================================================== */
    /* Model */

    @Input() _size : number;
    @Input() _item : Notification;
    @Input()
    set size(value: number) {
        this._size = (value !== undefined && value !== null) ? value : 16;
    }
    @Input()
    set item(value: Notification) {
        this._item = value;
    }

    @Output('link') openLink = new EventEmitter();
    @Output('detail') detailClicked = new EventEmitter();

    constructor(
        private dateService: DateService,
        private dataService: DataService,
    ) {
    }

    /* ==================================================================================================== */
    /* View */

    formatCard() {
        return {'border-left': `10px solid ${this._item['fgColor']}`};
    }

    clickLink(link: string) {
        this.openLink.emit(link);
    }


}


