import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import {throwError} from 'rxjs';
// import firebase from 'firebase/compat/app';
import { Firestore, collection, collectionData } from '@angular/fire/firestore';
/* ==================================================================================================== */
/* Services */
import {DateService} from '../core/services/dateService';
import {DataService} from '../core/services/dataService';
import {SettingService} from './apiServices/settingService';
import {VerseService} from './apiServices/verseService';
import {AlertService} from './apiServices/alertService';
import {MeetingService} from './apiServices/meetingService';
import {NotificationService} from './apiServices/notificationService';
import {BannerService} from './apiServices/bannerService';
import {GridService} from './apiServices/gridService';
import {DevotionService} from './apiServices/devotionService';
import {PrayerService} from './apiServices/prayerService';
import {PosterService} from './apiServices/posterService';
import {HymnService} from './apiServices/hymnService';
import {AnnouncementService} from './apiServices/announcementService';
import {SharingService} from './apiServices/sharingService';
import {FestivalService} from './apiServices/festivalService';

/* ==================================================================================================== */
/* Models */

// const httpOptions = {
//     headers: new HttpHeaders({
//     })
// };

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    constructor(
        // private http               : HTTP,
        private httpClient         : HttpClient,
//        private fireStore          : AngularFirestore,
        private fireStore          : Firestore,
        public dateService         : DateService,
        public dataService         : DataService,
        public settingsService     : SettingService,
        public verseService        : VerseService,
        public alertService        : AlertService,
        public meetingService      : MeetingService,
        public notificationService : NotificationService,
        public bannerService       : BannerService,
        public gridService         : GridService,
        public devotionService     : DevotionService,
        public prayerService       : PrayerService,
        public posterService       : PosterService,
        public hymnService         : HymnService,
        public announcementService : AnnouncementService,
        public sharingService      : SharingService,
        public festivalService     : FestivalService,
    ) {
//        firebase.database.enableLogging(true);
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${JSON.stringify(error.error)}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }


    unsubscribeListeners(key: string) {
        let collectionRef = collection(this.fireStore, key);
        let listener$ = collectionData(collectionRef).subscribe();
        // let listener = this.fireStore
        //     .collection(key)
        //     .snapshotChanges()
        //     .subscribe(() => {});
        listener$.unsubscribe();
    }

    /* ==================================================================================================== */

    /* Utils */

}
