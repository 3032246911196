import * as Constants from '../configs/constants';
import {Post} from './post.model';

export class Poster extends Post {

    img: string;

    constructor(id?: number, categories?: number[], slug?: string, title?: string, content?: string, excerpt?: string, featured_media?: number, link?: string, date?: string, status?: string) {
        super(id, categories, slug, title, content, excerpt, featured_media, link, date, status);
    }

    static init(post: any) {
        let instance = new Poster();
        instance.id = post.id;
        instance.categories = post.categories;
        instance.title = decodeURI(post.title.rendered);
        instance.content = '';
        instance.excerpt = '';
        instance.img  = post.fimg_url;
        instance.link = post.link;
        instance.date = instance.formatDate(post.modified, Constants.TIMESTAMP_FORMAT_EN);
        instance.status = post.status;
        return instance;
    }
}
