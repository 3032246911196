import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {PinchZoomModule} from '../../../modules/ivypinch/pinch-zoom.module';
import {RouterModule, Routes} from '@angular/router';

import {ImageModalPage} from './image-modal';

const routes: Routes = [
    {
        path: '',
        component: ImageModalPage,
    }
];

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        PinchZoomModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        ImageModalPage,
    ],
})
export class ImageModalPageModule {
}
