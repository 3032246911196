import * as Constants from '../configs/constants';
import {Post} from './post.model';

export class HymnPost extends Post {

    constructor(id?: number, categories?: number[], slug?: string, title?: string, content?: string, excerpt?: string, featured_media?: number, link?: string, date?: string, status?: string) {
        super(id, categories, slug, title, content, excerpt, featured_media, link, date, status);
    }

    static init(post: any) {
        let instance = new HymnPost();
        instance.id             = post.id;
        instance.categories     = post.categories;
        instance.slug           = instance.handleSlug(decodeURI(post.slug));
        instance.title          = post.title.rendered;
        instance.content        = instance.stripTags(post.content.rendered);
        instance.excerpt        = instance.stripLikes(post.excerpt.rendered);
        instance.featured_media = post.featured_media;
        instance.link           = post.link;
        instance.date           = instance.formatDate(post.date, Constants.TIMESTAMP_FORMAT_EN);
        instance.status         = post.status;
        return instance;
    }

    stripTags(str: string) {
        return str
            .replace(/<span class='badge badge-secondary'>(\+)\d+|[0]<\/span>/g,'')
            // .replace(/<iframe.*\/iframe>/g,'')
            .trim();
    }

    stripLikes(str: string) {
        return str
            .replace(/(\+)\d+|[0]/g,'')
            .trim();
    }

    handleSlug(str: string) {
        if (str.includes('-')) {
            return str
                .substring(0, str.indexOf('-'))
                .toUpperCase();
        } else {
            return '';
        }
    }


}
