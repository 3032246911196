import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {ModalService} from '../../providers/modalService';
import {IonSlides} from '@ionic/angular';

@Component({
    selector: 'card-slider',
    templateUrl: './card-slider.component.html',
    styleUrls: ['./card-slider.component.scss'],
})
export class CardSliderComponent implements AfterViewInit {

    @ViewChild('slides', {static: true}) slider: IonSlides;
    @HostBinding('class.first-slide-active') isFirstSlide = true;
    @HostBinding('class.last-slide-active') isLastSlide = false;

    sliderOptions: any;
    @Input('items') _items: any;
    @Input('type') type: string;
    @Output('click') itemClicked = new EventEmitter();
    @Output('link') openLink = new EventEmitter();

    defaultOptions : any;

    constructor(
        private modalService: ModalService,
    ) {
        this.defaultOptions = {
            initialSlide: 0,
            spaceBetween: 10,
            slidesPerView: 1,
            zoom: false,
            centeredSlides: true,
            // loop: true,
            autoplay: {
                delay: 10000,
                disableOnInteraction: false,
            },
            lazy: {
                loadPrevNext: true,
            },
            preloadImages: true,
            updateOnImagesReady: true,
        };
    }


    ngAfterViewInit(): void {
        // ViewChild is set
        this.slider.isBeginning().then(isBeginning => {
            this.isFirstSlide = isBeginning;
        });
        this.slider.isEnd().then(isEnd => {
            this.isLastSlide = isEnd;
        });

        // Subscribe to changes
        this.slider.ionSlideWillChange.subscribe(changes => {
            this.slider.isBeginning().then(isBeginning => {
                this.isFirstSlide = isBeginning;
            });
            this.slider.isEnd().then(isEnd => {
                this.isLastSlide = isEnd;
            });
            this.slider.getActiveIndex().then(index => {
                // console.log('index: ', index);
                // console.log('isLastSlide: ', this.isLastSlide);
            });
        });
    }

    slideDidLoaded(slides: any, slider: IonSlides) {
    }
    slideDidChanged(slides: any, slider: IonSlides) {
        // slider.getSwiper().then(swiper => {
            // if (swiper.realIndex === 0) {
            //
            // }
        // });
    }

    @Input()
    set options(options) {
        this.sliderOptions = {...this.defaultOptions, ...options};
    }

    isBannerSlider() {
        return this.type === 'banner';
    }

    onClick(item) {
        this.itemClicked.emit(item);
        if (item.hasOwnProperty('src') && item.url === undefined) {
           this.modalService.presentImageModal(item.src).then(() => {});
        } else {
            this.openLink.emit(item.url);
        }
    }

    formatBackground(bg) : any {
        if (bg.includes('#')) {
            return {'background': bg};
        } else {
            return {'background-image': 'url('+ bg +')'};
        }
    }

}
