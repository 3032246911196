import {Injectable} from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore, query, collection, collectionData, where, limit, collectionChanges, orderBy } from '@angular/fire/firestore';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {Verse} from '../../models/verse.model';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VerseService extends BaseService {

    collection_key: string;

    constructor(
        // protected http        : HTTP,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
        this.collection_key = Constants.COLLECTION_DAILY_VERSE;
    }

    getDailyVerses(): Observable<Verse[]> {
        let today = this.dateService.getTodayDate();
        let lowerDateBound = this.dateService.momentDate(today).add(-1, 'days').format(Constants.TIMESTAMP_FORMAT_EN);
        const q = query(collection(this.fireStore, this.collection_key), where('release_time', '>', lowerDateBound), limit(3));
        return collectionData(q).pipe(
            map(items => items.map(item => Verse.init(item))),
            // tap(items => this.logFSResult<Verse>(this.collection_key, items)),
            distinctUntilChanged(),
        );
        // return this.fireStore
        //     .collection<Verse>(this.collection_key, ref => ref
        //         .where('release_time', '>', lowerDateBound)
        //         .limit(3)
        //     )
        //     .valueChanges()
        //     .pipe(
        //         map(items => items.map(item => Verse.init(item))),
        //         // tap(items => this.logFSResult<Verse>(this.collection_key, items)),
        //         distinctUntilChanged(),
        //     );
    }

    getVerses(granularity: string = 'year'): Observable<Verse[]> {
        const q = query(collection(this.fireStore, this.collection_key), orderBy("id", "desc"));
        return collectionData(q).pipe(
            map(items => items
                .map(value => Verse.init(value))
                .filter(item => this.dateService.isSame(item.date, granularity))
            ),
            tap(items => this.dataService.setData(this.collection_key, items)),
            distinctUntilChanged(),
        );
        // return this.fireStore
        //     .collection<Verse>(this.collection_key, ref => ref.orderBy('id', 'desc'))
        //     .snapshotChanges().pipe(
        //         map(items => items
        //             .map(value => Verse.init(value.payload.doc.data()))
        //             .filter(item => this.dateService.isSame(item.date, granularity))
        //         ),
        //         tap(items => this.dataService.setData(this.collection_key, items)),
        //         distinctUntilChanged(),
        //     );
    }

}
