import * as Constants from '../configs/constants';
import * as moment from 'moment';

export class User {
    id            : number;
    username      : string;
    email         : string;
    phone         : string;
    fullname      : string;
    family        : string;
    unit          : string;
    offering_code : string;
    identity      : string;
    barcode       : string;
    balance       : number;
    access_token  : string;
    refresh_token : string;
    last_login    : string;
    last_update   : string;

    constructor(id?: number, username?: string, email?: string, phone?: string, fullname?: string, family?: string, unit?: string, offering_code?: string, identity?: string, barcode?: string, balance?: number, access_token?: string, refresh_token?: string, last_login?: string, last_update?: string) {
        this.id            = id;
        this.username      = username;
        this.email         = email;
        this.phone         = phone;
        this.fullname      = fullname;
        this.family        = family;
        this.unit          = unit;
        this.offering_code = offering_code;
        this.identity      = identity;
        this.barcode       = barcode;
        this.balance       = balance;
        this.access_token  = access_token;
        this.refresh_token = refresh_token;
        this.last_login    = last_login;
        this.last_update   = last_update;
    }

    static init(value: any) {
        let instance = new User();
        if (value == null) return instance;
        instance.id            = value.id || -1;
        instance.username      = value.username || '';
        instance.email         = value.email || '';
        instance.phone         = value.phone || '';
        instance.fullname      = value.fullname || '';
        instance.family        = value.family || '';
        instance.unit          = value.unit || '';
        instance.offering_code = value.offering_code || '';
        instance.identity      = value.identity || Constants.IDENTITY.GUEST;
        instance.barcode       = value.barcode || '';
        instance.balance       = value.balance || 0;
        instance.access_token  = value.access_token || '';
        instance.refresh_token = value.refresh_token || '';
        instance.last_login    = (value.last_login > 0) ? instance.formatDate(value.last_login) : '';
        instance.last_update   = (value.last_update > 0) ? instance.formatDate(value.last_update) : '';
        return instance;
    }

    print() {
        return `
        id            : ${this.id            }\n
        username      : ${this.username      }\n
        email         : ${this.email         }\n
        phone         : ${this.phone         }\n
        fullname      : ${this.fullname      }\n
        family        : ${this.family        }\n
        unit          : ${this.unit          }\n
        offering_code : ${this.offering_code }\n
        identity      : ${this.identity      }\n
        barcode       : ${this.barcode       }\n
        balance       : ${this.balance       }\n
        access_token  : ${this.access_token  }\n
        refresh_token : ${this.refresh_token }\n
        last_login    : ${this.last_login    }\n
        last_update   : ${this.last_update   }\n
        `;
    }

    formatDate(date:string, format: string = Constants.TIMESTAMP_FORMAT_EN) {
        return moment(date).format(format);
    }

    simplify() {
        return {
            fullname: this.fullname,
            email: this.email,
            phone: this.phone,
            family: this.family,
            unit: this.unit,
        };
    }

    toObject() {
        return {
            id            : this.id,
            username      : this.username,
            email         : this.email,
            phone         : this.phone,
            fullname      : this.fullname,
            family        : this.family,
            unit          : this.unit,
            offering_code : this.offering_code,
            identity      : this.identity,
            barcode       : this.barcode,
            balance       : this.balance,
            access_token  : this.access_token,
            refresh_token : this.refresh_token,
            last_login    : this.last_login,
            last_update   : this.last_update,
        };
    }

    toSimpleObject() {
        return {
            id            : this.id,
            username      : this.username,
            email         : this.email,
            fullname      : this.fullname,
            family        : this.family,
            unit          : this.unit,
        };
    }

}
