import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'video-item',
    templateUrl: './video-item.component.html',
    styleUrls: ['./video-item.component.scss'],
})
export class VideoItemComponent {

    /* ==================================================================================================== */
    /* Model */

    @Input('items') _items: any;
    @Input() _size : number;
    @Input()
    set size(value: number) {
        this._size = (value !== undefined && value !== null) ? value : 16;
    }

    @Output('link') openLink = new EventEmitter();
    @Output('detail') detailClicked = new EventEmitter();

    constructor(
    ) {
    }

    /* ==================================================================================================== */
    /* View */

    trackById(index, item) {
        return item.id;
    }

    clickLink(link: string) {
        this.openLink.emit(link);
    }


}


