import {ResourceBase} from './resource-base.model';

export class GrowthCategory extends ResourceBase {

    size: number

    constructor(id?: number, order?: number, category?: string, key?: string, value?: string, title?: string, subtitle?: string, content?: string, excerpt?: string, thumbnail?: string, url?: string, release_time?: string, expired_time?: string, show?: boolean, buttons?: any) {
        super(id, order, category, key, value, title, subtitle, content, excerpt, thumbnail, url, release_time, expired_time, show, buttons);
        this.size = 1;
    }

    static init(value: any) {
        let instance = new GrowthCategory();
        if (value === null) return instance;
        instance.id       = (value.id > 0) ? value.id : (value.id === 0) ? 0 : -1;
        instance.order    = (value.order > 0) ? value.order : (value.order === 0) ? 0 : -1;
        instance.category = value.category || '';
        instance.key      = value.key      || '';
        instance.value    = value.value    || '';
        instance.show     = value.show     || false;
        instance.size     = (value.size > 0) ? value.size : 1;
        return instance;
    }

}
