import {
    AfterContentChecked,
    AfterContentInit,
    Component,
    ContentChildren,
    QueryList, ViewChild
} from '@angular/core';
import {TabItemComponent} from '../tab-item/tab-item.component';
import {Observable} from 'rxjs';
import {delay, map, startWith} from 'rxjs/operators';
import {IonContent} from '@ionic/angular';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit, AfterContentChecked {

    @ContentChildren(TabItemComponent) tabs: QueryList<TabItemComponent>;
    @ViewChild(IonContent, {static: true}) ionContent: IonContent;

    tabItems$: Observable<TabItemComponent[]>;
    activeTab: TabItemComponent;

    constructor() {}

    ngAfterContentInit(): void {
        this.tabItems$ = this.tabs.changes
            .pipe(
                startWith(""),
                delay(0),
                map(() => this.tabs.toArray())
            )
    }

    ngAfterContentChecked(): void {
        if (!this.activeTab) {
            Promise.resolve()
                .then(() => this.activeTab = this.tabs.first);
        }
    }

    selectTab(tabItem: TabItemComponent) {
        if (this.activeTab === tabItem) {
            return;
        }

        if (this.activeTab) {
            this.activeTab.isActive = false;
        }
        this.activeTab = tabItem;
        tabItem.isActive = true;

        this.ionContent.scrollToTop(300)
            .then(() => {});
    }

    trackByFn(index, item) {
        return item;
    }

}
