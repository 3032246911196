<ion-header class="ion-no-border ion-no-margin ion-no-padding">
    <ion-toolbar [color]="'primary'">
        <ion-buttons slot="start" (click)="close()">
            <ion-icon name="arrow-back" [size]="'large'"></ion-icon>
        </ion-buttons>
        <ion-title id="toolbar-title" class="bar-title">{{ params['title'] }}</ion-title>
        <ion-buttons slot="end">
            <ion-button>
                <ion-icon slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="video-wrapper">
        <iframe [src]="url" width="1280" height="720"
                frameborder="0" align="middle" allowfullscreen="allowfullscreen"></iframe>
    </div>
</ion-content>
<ion-footer class="ion-no-margin ion-no-padding">
    <ion-toolbar [color]="'primary'">
        <ion-grid>
            <ion-row>
                <ion-col (click)="close()">
                    <ion-button color="tertiary" shape="round" fill="solid" expand="full">
                        <ion-icon name="close" slot="start"></ion-icon>
                        關閉
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
