import { Injectable } from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore, query, collection, collectionData, orderBy, doc, docData } from '@angular/fire/firestore';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {Filter} from '../../models/filter.model';
import {Notification} from '../../models/notification.model';
import {BehaviorSubject, Observable, of, zip} from 'rxjs';
import {distinctUntilChanged, filter, map, take, tap} from 'rxjs/operators';
import {Level} from '../../models/level.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends BaseService {

    private _notifications = new BehaviorSubject<Notification[]>([]);
    private dataStore : { items : Notification[] } = { items : [] };
    public readonly notifications = this._notifications.asObservable();
    level_key      : string;
    filter_key     : string;
    collection_key : string;

    constructor(
        // protected http        : HTTP,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
        this.filter_key     = Constants.COLLECTION_FILTERS;
        this.level_key      = Constants.COLLECTION_LEVELS;
        this.collection_key = Constants.COLLECTION_NOTIFICATIONS;
        // this.collection_key = Constants.COLLECTION_TESTING;
    }

    getFilters() : Observable<Filter[]> {
        return docData(doc(this.fireStore, this.filter_key + "/" + this.dataService.getAPIEnv())).pipe(
            map(values => values['notification']),
            map(values => values
                .map(value => Filter.init(value))
                .filter(value => value.show)),
            tap(items => this.dataService.setData(this.filter_key, items)),
        );
        // return this.fireStore
        //     .collection<Filter>(this.filter_key)
        //     .doc(this.dataService.getAPIEnv())
        //     .snapshotChanges()
        //     .pipe(
        //         map(values => values.payload.data()['notification']),
        //         map(values => values
        //             .map(value => Filter.init(value))
        //             .filter(value => value.show)),
        //         tap(items => this.dataService.setData(this.filter_key, items)),
        //         // tap(output => console.log('api output: ', output)),
        //         // tap(items => this.logFSResult<Filter>(this.filter_key, items)),
        //     );
    }

    getLevels() : Observable<Level[]> {

        return docData(doc(this.fireStore, this.level_key + "/" + this.dataService.getAPIEnv())).pipe(
            map(values => values['notification']),
            map(values => values
                .map(value => Level.init(value))
                .filter(value => value.show)),
            tap(items => this.dataService.setData(this.level_key, items)),
            // tap(items => this.logFSResult<Level>(this.level_key, items)),
        );
        // return this.fireStore
        //     .collection<Level>(this.level_key)
        //     .doc(this.dataService.getAPIEnv())
        //     .snapshotChanges()
        //     .pipe(
        //         map(values => values.payload.data()['notification']),
        //         map(values => values
        //             .map(value => Level.init(value))
        //             .filter(value => value.show)),
        //         tap(items => this.dataService.setData(this.level_key, items)),
        //         // tap(items => this.logFSResult<Level>(this.level_key, items)),
        //     );
    }

    getNotifications(filterEnabled: boolean): Observable<Notification[]> {
        const q = query(collection(this.fireStore, this.collection_key), orderBy("id", "desc"));
        return collectionData(q).pipe(
            map(items => items
                .map(value => Notification.init(value))
                .filter(item => filterEnabled ? item.show && this.dateService.validDate(item.release_time, item.expired_time) : item),
            ),
            map(items => {
                this.dataService.getData(this.level_key)
                    .then(data => {
                        if (data !== undefined) {
                            items.forEach(item => {
                                let level = data.find(lv => lv.id === item.level);
                                item['fgColor'] = (level !== undefined && item.level >= 0) ? level.color : 'transparent';
                                item['icon']    = (level !== undefined && item.level >= 0) ? level.icon : '';
                            });
                        }
                    });
                return items;
            }),
            tap(items => {
                this.dataService.getData(this.collection_key)
                    .then((data: Notification[]) => {
                        if (data instanceof Array) {
                            this.dataStore.items = this.dataService.mergeFields(items, data, ['read']) as Notification[];
                        } else {
                            this.dataStore.items = items;
                        }
                        this.dataStore.items = items;
                        this._notifications.next(Object.assign({}, this.dataStore).items);
                        this.dataService.setData(this.collection_key, this.dataStore.items)
                            .then(() => {});
                    });
            }),
            // tap(items => this.logFSResult<Notification>(this.collection_key, items)),
            distinctUntilChanged(),            
        );
        // return this.fireStore
        //     // .collection<Notification>(this.collection_key, ref => ref.orderBy('id', 'asc').limit(3))
        //     .collection<Notification>(this.collection_key, ref => ref.orderBy('id', 'desc'))
        //     .snapshotChanges()
        //     .pipe(
        //         map(items => items
        //             .map(value => Notification.init(value.payload.doc.data()))
        //             .filter(item => filterEnabled ? item.show && this.dateService.validDate(item.release_time, item.expired_time) : item),
        //         ),
        //         map(items => {
        //             this.dataService.getData(this.level_key)
        //                 .then(data => {
        //                     if (data !== undefined) {
        //                         items.forEach(item => {
        //                             let level = data.find(lv => lv.id === item.level);
        //                             item['fgColor'] = (level !== undefined && item.level >= 0) ? level.color : 'transparent';
        //                             item['icon']    = (level !== undefined && item.level >= 0) ? level.icon : '';
        //                         });
        //                     }
        //                 });
        //             return items;
        //         }),
        //         tap(items => {
        //             this.dataService.getData(this.collection_key)
        //                 .then((data: Notification[]) => {
        //                     if (data instanceof Array) {
        //                         this.dataStore.items = this.dataService.mergeFields(items, data, ['read']) as Notification[];
        //                     } else {
        //                         this.dataStore.items = items;
        //                     }
        //                     this.dataStore.items = items;
        //                     this._notifications.next(Object.assign({}, this.dataStore).items);
        //                     this.dataService.setData(this.collection_key, this.dataStore.items)
        //                         .then(() => {});
        //                 });
        //         }),
        //         // tap(items => this.logFSResult<Notification>(this.collection_key, items)),
        //         distinctUntilChanged(),
        //     );
    }

    getNotification$() {
        return this.notifications;
    }

    updateNotification$(item: Notification) {
        for (let index = 0; index < this.dataStore.items.length; index++)
            if (this.dataStore.items[index].id === item.id)
                this.dataStore.items[index] = item;
        this._notifications.next(Object.assign({}, this.dataStore).items);
        this.dataService.setData(this.collection_key, this.dataStore.items)
            .then(() => {});
    }

}
