import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
// import {OneSignal} from '@awesome-cordova-plugins/onesignal/ngx';
// import {Device} from '@capacitor/device';
// import { OneSignalPlugin } from 'plugins/onesignal-cordova-plugin/dist';
// import OneSignal from 'plugins/onesignal-cordova-plugin/dist';
import OneSignal from 'onesignal-cordova-plugin';
@Injectable({
    providedIn: 'root',
})
export class PushService {

    constructor(
        private platform: Platform,
//        private fireStore: AngularFirestore,
//          private oneSignal: OneSignal,
    ) {
    }

    /* ==================================================================================================== */
    /* Notifications */

    /* ==================================================================================================== */
    /* OneSignal */

    setup() {
        OneSignal.Debug.setLogLevel(6)
        OneSignal.initialize('0ee47084-ce02-43a6-9245-7d767d60bd17');
        OneSignal.Notifications.addEventListener('click', async (e) => {
            let clickData = await e.notification;
            console.log("Notification Clicked : " + clickData);
        })
      
        OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
            console.log("Notification permission granted " + success);
        })
//        OneSignal.setAppId('0ee47084-ce02-43a6-9245-7d767d60bd17');
//         this.oneSignal.startInit('0ee47084-ce02-43a6-9245-7d767d60bd17', '444919060416'); // Onesignal ID, Firebase project ID
//        OneSignal.setLogLevel(6, 0);
//         this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
//        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
        // OneSignal.setNotificationOpenedHandler(data => {
        //         // let msg = data.payload.body;
        //         // let title = data.payload.title;
        //         // let additionalData = data.payload.additionalData;
        //         console.log('data: ', data);
        //         // console.log('title: ', title);
        //         // console.log('msg: ', msg);
        //         // console.log('additional data: ', additionalData);
        //     });

        // OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        //     console.log(accepted);
        // });

//        this.oneSignal.handleNotificationOpened()
//            .subscribe(data => {
                // let additionalData = data.notification.payload.additionalData;
                // console.log('data: ', data);
                // console.log('additional data: ', additionalData);
//            });
//        this.oneSignal.endInit();
        // this.oneSignal.getPermissionSubscriptionState()
        //     .then(result => console.log('result: ', JSON.stringify(result)));
    }

    /* ==================================================================================================== */
    /* Crashlytics */
}
