export class Alert {
    id           : number;
    position     : string;
    category     : string;
    content      : string;
    color        : string;
    linkColor    : string;
    release_time : string;
    expired_time : string;
    show         : boolean;

    constructor(id?: number, position?: string, category?: string, content?: string, color?: string, linkColor?: string, release_time?: string, expired_time?: string, show?: boolean) {
        this.id           = id;
        this.position     = position;
        this.category     = category;
        this.color        = color;
        this.linkColor    = linkColor;
        this.release_time = release_time;
        this.expired_time = expired_time;
        this.show         = show;
    }

    static init(value: any) {
        let instance = new Alert();
        instance.id           = value.id;
        instance.position     = value.position     || '';
        instance.category     = value.category     || '';
        instance.content      = value.content      || '';
        instance.color        = value.color        || '';
        instance.linkColor    = value.linkColor    || '';
        instance.release_time = value.release_time || '';
        instance.expired_time = value.expired_time || '';
        instance.show         = value.show         || false;
        return instance;
    }
}
