<ion-card class="record-card">
    <ion-card-header>
        <ion-card-title [innerHTML]="title"></ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <div class="record-user">
            <div>姓名：{{ card.owner?.fullname }}</div>
            <div>聯絡電郵：{{ card.owner?.email }}</div>
            <div>聯絡電話：{{ card.owner?.phone }}</div>
            <div>家：{{ card.owner?.family }}</div>
            <div>所屬單位：{{ card.owner?.unit }}</div>
            <div *ngIf="card.owner?.offering_code">十一密碼：{{ card.owner?.offering_code }}</div>
            <div *ngIf="!card.owner?.offering_code">十一密碼（如適用）</div>
        </div>
        <div class="record-total" [innerHTML]="festivalService.getTotal('總金額：')"></div>
    </ion-card-content>
</ion-card>
