export class DevotionCollection {
    id        : number;
    order     : number;
    kind      : string;
    show      : boolean;
    title     : string;
    content   : string;
    link      : string;
    thumbnail : string;

    constructor(id?: number, order?: number, kind?: string, show?: boolean, title?: string, content?: string, link?: string, thumbnail?: string) {
        this.id        = id        || -1;
        this.order     = order     || -1;
        this.kind      = kind      || '';
        this.show      = show      || false;
        this.title     = title     || '';
        this.content   = content   || '';
        this.link      = link      || '';
        this.thumbnail = thumbnail || '';
    }

    static init(value: any) {
        let instance = new DevotionCollection();
        instance.id        = value.id;
        instance.order     = value.order;
        instance.kind      = value.kind;
        instance.show      = value.show;
        instance.title     = value.title;
        instance.content   = value.content;
        instance.link      = value.link;
        instance.thumbnail = value.thumbnail;
        return instance;
    }
}
