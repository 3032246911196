<ion-header class="ion-no-border ion-no-margin ion-no-padding">
    <ion-toolbar [color]="'primary'">
        <ion-title id="toolbar-title" class="bar-title">{{ globalVars.getPageByID(21).page_title }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="main-title">查看今週的靈修資料:</div>
    <ion-list>
        <ion-item class="ion-no-margin ion-no-padding" *ngFor="let item of devotions; index as i;" (click)="openDetail(item.id)">
            <div class="list-item" [innerHTML]="item.title"></div>
        </ion-item>
    </ion-list>

    <div class="main-title">或查看較早的資料:</div>
    <ng-template [ngIf]="!validDate">
        <div class="full-width middle caution">請選擇一個合理的日期。</div>
    </ng-template>
    <ion-datetime #customDate
                  id="customDate" [min]="2014"
                  [cancelText]="'取消'" [doneText]="'確認'"
                  (ionChange)="changeDate(customDate.value)">
    </ion-datetime>
</ion-content>

<ion-footer>
    <ion-row>
        <ion-col>
            <ion-button [color]="'light'" [expand]="'full'" [fill]="'clear'" (click)="dismissModal(false)">
                取消
            </ion-button>
        </ion-col>
        <ion-col>
            <ion-button [color]="'light'" [expand]="'full'" [fill]="'clear'" (click)="fetchContentByDate()"
                        [disabled]="fetchDate?.length === 0 || !validDate">
                確認
            </ion-button>
        </ion-col>
    </ion-row>
</ion-footer>
