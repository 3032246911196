import {Injectable} from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore } from '@angular/fire/firestore';

import _ from 'lodash-es';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {Prayer} from '../../models/prayer.model';
import {Observable, from} from 'rxjs';
import {catchError, distinctUntilChanged, map, retry, take, tap} from 'rxjs/operators';
import { CapacitorHttp } from '@capacitor/core';

const httpOptions = {
    headers: new HttpHeaders({
    })
};

@Injectable({
    providedIn: 'root'
})
export class PrayerService extends BaseService {

    constructor(
        // protected http        : HTTP,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
    }

    private fetchPrayer(url: string) : Observable<Prayer[]> {
        const options = { url: url};
        return from(CapacitorHttp.get(options)).pipe(
        // return this.httpClient.get<Prayer[]>(url, httpOptions).pipe(
            map(response => {
                const items:Prayer[] = response.data;
                return items.map(item => Prayer.init(item))
            }),
            // map(items => items[0]),
            // map(items => Prayer.init(null)),
            distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
            catchError(this.handleError),
            take(1),
            retry(2),
        );
    }

    public getWeeklyPrayer(count: number = 4): Observable<Prayer[]> {
        // return this.fetchPrayer(`${Constants.API_MAIN}/posts?${this.wpEncode(118, count, 'modified')}&offset=4`)
        return this.fetchPrayer(`${Constants.API_MAIN}/posts?${this.wpEncode(118, count, 'modified')}`)
            .pipe(
                map(items => items.slice(0, count)),
                tap(items => items.map(item => item.content = this.extractImgURL(item.content))),
            );
    }

    public getWeeklyItem(count: number = 4): Observable<Prayer[]> {
        // return this.fetchPrayer(`${Constants.API_MAIN}/posts?${this.wpEncode(29, count, 'modified')}&offset=4`)
        return this.fetchPrayer(`${Constants.API_MAIN}/posts?${this.wpEncode(29, count, 'modified')}`)
            .pipe(
                map(items => items.slice(0, count)),
            );
    }

    /* ==================================================================================================== */

    /* Operations */

    private extractImgURL(src: string) : string {
        let snippet = document.createElement("div");
        snippet.innerHTML = src;
        let img = (<HTMLImageElement>snippet.querySelector('img'));
        return img.src;
    }

}
