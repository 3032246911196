import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {Meeting} from '../../models/meeting.model';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import * as Constants from '../../configs/constants';
import {Level} from '../../models/level.model';

@Component({
    selector: 'lent-item',
    templateUrl: './lent-item.component.html',
    styleUrls: ['./lent-item.component.scss'],
})
export class LentItemComponent {

    /* ==================================================================================================== */
    /* Model */

    @Input('items') _items: any;
    @Input() _size : number;
    @Input()
    set size(value: number) {
        this._size = (value !== undefined && value !== null) ? value : 16;
    }

    @Output('link') openLink = new EventEmitter();
    @Output('detail') detailClicked = new EventEmitter();

    constructor(
        private dateService: DateService,
        private dataService: DataService,
    ) {
    }

    /* ==================================================================================================== */
    /* View */

    formatCard() {
        // return {'border-left': `10px solid ${this._item['fgColor']}`};
    }

    clickLink(link: string) {
        this.openLink.emit(link);
    }


}


