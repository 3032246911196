export const environment = {
    production: true,
    filterEnabled: true,
    firebaseConfig: {
        apiKey: "AIzaSyAQgz5_0hX8bqpmHqybbbrgprnEx_XbLOk",
        authDomain: "wkphc-app.firebaseapp.com",
        databaseURL: "https://wkphc-app.firebaseio.com",
        projectId: "wkphc-app",
        storageBucket: "wkphc-app.appspot.com",
        messagingSenderId: "444919060416",
        appId: "1:444919060416:web:fdd3a163d01e46b9ae1a25",
        measurementId: "G-EST022Y307"
    },
    useEmulators: false,
};
