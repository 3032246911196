import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Page} from '../../models/page.model';
// import {ImageAttribute} from 'ionic-image-loader-v5';

@Component({
    selector: 'page-item',
    templateUrl: './page-item.component.html',
    styleUrls: ['./page-item.component.scss'],
})
export class PageItemComponent {

    @Input() _size : number;
    @Input() _item: Page;
    @Input('expanded') expanded: boolean = false;
//     imageAttr : ImageAttribute[] = [];

    @Input()
    set size(value: number) {
        this._size = (value !== undefined && value !== null) ? value : 16;
    }
    @Input()
    set item(value: Page) {
        this._item = value;
        // this.imageAttr.push({
        //     element: 'class',
        //     value: 'item-icon'
        // })
    }

    @Output('toggle') expandClicked = new EventEmitter();
    @Output('go') buttonClicked     = new EventEmitter();

    constructor(
    ) {
    }

    formatCard() {
        return {'border-left': `10px solid ${this._item['fgColor']}`};

    }
    formatBackground() : any {
        let bg = this._item['icon'];
        if (bg !== undefined)  {
            if (bg.includes('#')) {
                return {'background': bg};
            } else {
                return {'background-image': 'url('+ bg +')'};
            }
        } else {
            document.getElementById(`bg-col-${this._item['id']}`)
        }
    }

    /* ==================================================================================================== */
    /* Operations */

    expand() {
        this.expandClicked.emit();
    }

    navigate() {
        this.buttonClicked.emit();
    }

}
