export class Log {
    device_id: string;
    device_model: string;
    os_version: string;
    app_version: string;

    constructor(device_id?: string, device_model?: string, os_version?: string, app_version?: string) {
        this.device_id    = device_id    ;
        this.device_model = device_model ;
        this.os_version   = os_version   ;
        this.app_version  = app_version  ;
    }

    static init(value: any) {
        let instance = new Log();
        if (value == null) return instance;
        instance.device_id    = value.device_id    ;
        instance.device_model = value.device_model ;
        instance.os_version   = value.os_version   ;
        instance.app_version  = value.app_version  ;
        return instance;
    }

    toObject() {
        return {
            device_id    : this.device_id    ,
            device_model : this.device_model ,
            os_version   : this.os_version   ,
            app_version  : this.app_version  ,
        };
    };
}
