/* ==================================================================================================== */
/* API URLS */

export const WKPHC_MAIN   = 'https://www.wkphc.org/';
export const API_MAIN     = 'https://www.wkphc.org/wp-json/wp/v2';
export const API_DEVOTION = 'https://devotion.wkphc.org/wp-json/wp/v2';
export const API_HYMN     = 'https://hymn.wkphc.org/wp-json/wp/v2';
export const API_OAUTH    = 'https://login.wkphc.org/oauth';
export const API_USER     = 'https://login.wkphc.org/wp-json/wp/v2/users';
export const API_MEMBER   = 'https://login.wkphc.org/wp-json/member/v1/route';
export const API_MEMCARD  = 'https://memcard2.wkphc.org/app';
export const URL_RESET_PW = 'https://www.wkphc.org/lostpassword/';

/* ==================================================================================================== */
/* API KEYS */

/* ==================================================================================================== */
/* FIREBASE_COLLECTIONS */

export const COLLECTION_API_CONTROLS          = 'apiControls';
export const COLLECTION_APP_MENU              = 'appMenu';
export const COLLECTION_APP_SETTINGS          = 'settings';
export const COLLECTION_USER_PROFILES         = 'userProfiles';
export const COLLECTION_LEVELS                = 'levels';
export const COLLECTION_FILTERS               = 'filters';
export const COLLECTION_ALERTS                = 'alerts';
export const COLLECTION_MEETINGS              = 'meetings';
export const COLLECTION_NOTIFICATIONS         = 'notifications';
export const COLLECTION_BANNERS               = 'banners';
export const COLLECTION_CHANNELS              = 'channels';
export const COLLECTION_GRIDS                 = 'grids';
export const COLLECTION_HOME_GRIDS            = 'homeGrids';
export const COLLECTION_EVENT_GRIDS           = 'eventGrids';
export const COLLECTION_DAILY_VERSE           = 'dailyVerse';
export const COLLECTION_DEVOTION_COLLECTION   = 'devotionCollection';
export const COLLECTION_CHURCH_FESTIVALS      = 'churchFestivals';
export const COLLECTION_PROFILE_LOGS          = 'profile-logs';
export const COLLECTION_REDEEM_LOGS           = 'redeem-logs';
export const COLLECTION_SERVER_LOGS           = 'server-logs';
export const COLLECTION_FESTIVAL              = 'festival';
export const COLLECTION_FEEDBACK              = 'feedback';

export const COLLECTION_TESTING               = 'testing';

export const COLLECTION_FESTIVAL_SETTINGS     = {
    MAIN_GRID: 'festival-main-grids',
    MAIN_RES: 'festival-main-resources',
};
export const COLLECTION_2020_LENT             = {
    AMBITIONS : '2020-lent-ambitions',
    RECORDS   : '2020-lent-records',
    ARCHIVES  : '2020-lent-archives',
    LOGS      : '2020-lent-logs',
    SETTING   : '2020-lent-setting',
    BACKUP    : '2020-lent-backup',
};
export const COLLECTION_2021_LENT             = {
    // collections
    CARD_INFO : '2021-card-info',
    CARD_KEY  : '2021-card-key',
    CARD      : '2021-lent-cards',
    IDS       : '2021-lent-ids',
    LOGS      : '2021-lent-logs',
    ARCHIVES  : '2021-lent-archives',
    AMBITIONS : '2021-lent-ambitions',

    SETTING   : '2021-lent-setting',
    BACKUP    : '2021-lent-backup',
    // tabs
    T : 'today',
    B : 'being',
    W : 'being_with',
    F : '5loaves2fish',
};

export const COLLECTION_2022_LENT             = {
    // collections
    CARD_INFO : '2022-card-info',
    CARD_KEY  : '2022-card-key',
    CARD      : '2022-lent-cards',
    IDS       : '2022-lent-ids',
    LOGS      : '2022-lent-logs',
    ARCHIVES  : '2022-lent-archives',
    AMBITIONS : '2022-lent-ambitions',

    SETTING   : '2022-lent-setting',
    BACKUP    : '2022-lent-backup',
    // tabs
    T : 'today',
    B : 'being',
    W : 'being_with',
    F : '5loaves2fish',
};


export const COLLECTION_LENT_CURRENT             = {
    // collections
    CARD_INFO : '2024-card-info',
    CARD_KEY  : '2024-card-key',
    CARD      : '2024-lent-cards',
    IDS       : '2024-lent-ids',
    LOGS      : '2024-lent-logs',
    ARCHIVES  : '2024-lent-archives',
    AMBITIONS : '2024-lent-ambitions',
    DEFAULT_AMBITION: '',

    SETTING   : '2024-lent-setting',
    BACKUP    : '2024-lent-backup',
    BOUNDARIES  : 'festival-boundaries',  // Newly added for controling start and ending date from firestore
    // tabs
    T : 'today',
    B : 'being',
    W : 'being_with',
    F : '5loaves2fish',
};

// To Be Removed:

/* ==================================================================================================== */
/* STORAGE KEYS */

export const KEY_APP_SETTINGS         = 'APP_SETTINGS';
export const KEY_CURRENT_USER         = 'CURRENT_USER';
export const KEY_LENT_COLLECTION      = 'LENT_COLLECTION';
export const KEY_NOTIFICATION_STATUS  = 'NOTIFICATION_STATUS';
export const KEY_POSTER               = 'POSTER';
export const KEY_DEVOTION_CONTENT     = 'DEVOTION_CONTENT';
export const KEY_WEEKLY_PRAYER        = 'WEEKLY_PRAYER';
export const KEY_WEEKLY_ITEM          = 'WEEKLY_ITEM';
export const KEY_MONTHLY_ITEM         = 'MONTHLY_ITEM';
export const KEY_HYMN_COLLECTION      = 'HYMN_COLLECTION';
export const KEY_PASTOR_SHARING       = 'PASTOR_SHARING';
export const KEY_VH_SHARING           = 'VH_SHARING';
export const KEY_ANNOUNCEMENT_CONTENT = 'ANNOUNCEMENT_CONTENT';
export const KEY_FORCE_LOGOUT         = 'FORCE_LOGOUT';
export const KEY_SEEN_TUTORIAL        = 'SEEN_TUTORIAL';

// 2021
export const KEY_2021_HYMN_DEVOTION   = '2021_hymn_devotion';

// export const KEY_DEVOTION             = {
//     LIST: 'LIST',
//     CURRENT: 'CURRENT',
// };
export const KEY_HYMNS                = {
    CATEGORIES: 'HYMN_CATEGORIES',
    RANDOM: 'HYMN_RANDOM',
};

/* ==================================================================================================== */
/* STATUS */

export const STATUS = {
    // Posters
    P : 'publish',
    D : 'draft',
    // Members
    ME : 'ME',
    DM : 'DM',
    S  : 'success',
    F  : 'fail',
};

/* ==================================================================================================== */
/* IDENTITY */

export const IDENTITY = {
    LEADER : 'LEADER',
    MEMBER : 'MEMBER',
    GUEST  : 'GUEST',
};

/* ==================================================================================================== */
/* LOG */

export const LOG = {
    system: {
        DEVICE_ID    : 'DEVICE_ID'    ,
        DEVICE_MODEL : 'DEVICE_MODEL' ,
        OS           : 'OS'           ,
        APP          : 'APP'          ,
    },
    action : {
        D : 'DELETE',
        I : 'INSERT',
        U : 'UPDATE',
    },
    type: {
        D : 'debug',
        L : 'log',
        I : 'info',
        W : 'warning',
        E : 'error',
    },
    categories: {
        UNKNOWN: 'unknown',
        DEVOTION: 'devotion',
        PRAYER: 'prayer',
        POSTER: 'poster',
        ANNOUNCEMENT: 'announcement',
        PASTOR: 'pastor',
        VH: 'vh',
    }
};

/* ==================================================================================================== */
/* TYPE */

export const TYPE = {
    resource: {
        D : 'devotion',
        P : 'prayer',
        M : 'meeting',
    },
    record: {
        I: 'increasing',
        D: 'decreasing',
    },
};

/* ==================================================================================================== */
/* UTIL KEYS */

export const SORT_ID      = 'id';
export const SORT_ORDER   = 'order';
export const SORT_DATE    = 'date';
export const SORT_UPDATE  = 'update';

/* ==================================================================================================== */
/* FESTIVAL KEYS */

export const WEEK_LENGTH    = 7;
export const LENT_LENGTH    = 28;
export const LENT_START_DAY = '2024-03-03';
export const LENT_END_DAY   = '2024-03-30';
// ! ---------- Development ---------- ! //
// export const LENT_START_DAY = '2021-02-28';
// export const LENT_END_DAY   = '2021-03-27';
// ! ---------- Development ---------- ! //

/* ==================================================================================================== */
/* SETTINGS */

export const APP_ID               = {ios: 'id1445332509', android: 'org.wkphc.app2'};
export const APP_ENV              = {PROD: 'production', DEV: 'development'};
export const KEY_FONT_SIZE        = 'FONT_SIZE';
export const MAIN_FONT_SIZE       = 16;
export const UNIT_SECOND          = 1000;   // 1 second
export const TIMEOUT_API_BLINK    = 3000;   // 3 seconds
export const TIMEOUT_API_SHORT    = 10000;  // 10 seconds
export const TIMEOUT_API_LONG     = 15000;  // 15 seconds
export const TIMEOUT_SHORT        = 120000; // 120 seconds || 2 minutes
export const TIMEOUT_MEDIUM       = 300000; // 300 seconds || 5 minutes
export const TIMEOUT_LONG         = 900000;
export const TIMEOUT_HALF_HOUR    = 1800000;
export const TIMEOUT_FULL_HOUR    = 3600000;
export const URL_SEPARATOR        = '/';
export const DATE_FORMAT_SHORT    = 'M月D日';
export const DATE_FORMAT_TC       = 'YYYY年M月D日';
export const DATE_FORMAT_EN       = 'YYYY-MM-DD';
export const DATE_FORMAT_YM       = 'YYYY年MM月';
export const DATE_FORMAT_MD       = 'MM-DD';
export const DATE_FORMAT_ID_LONG  = 'YYYYMMDDHHmmss';
export const DATE_FORMAT_ID_SHORT = 'YYYYMMDD';
export const HUMAN_TIME_FORMAT_EN = 'YYYY-MM-DD HH:mm';
export const HUMAN_TIME_FORMAT_TC = 'YYYY年M月D日 HH:mm';
export const TIMESTAMP_FORMAT_EN  = 'YYYY-MM-DD HH:mm:ss';
export const TIMESTAMP_HUMAN      = 'M月D日 h:mm A';

export const THEME = {
    normal: {
        primary: '#996097',
        primaryDark : '#67278B',
        contrast: '#FFFFFF',
    },
    lent: {
        primary: '#430064',
        primaryDark : '#67278B',
        contrast: '#FFFFFF',
    },
    easterStage1: {
        primary : '#7bc7de',
        primaryDark : '#387695',
        contrast: '#FFFFFF',
    },
    easterStage2: {
        primary : '#97CD43',
        primaryDark : '#62843B',
        contrast: '#FFFFFF',
    },
    easterStage3: {
        primary : '#FF8024',
        primaryDark : '#DB5C00',
        contrast: '#FFFFFF',
    },
};

/* ==================================================================================================== */
/* FAMILIES & UNITS */

export const FAMILIES = [
    // '拿家','以家','西家','史家',
    '拿撒勒之家','以馬內利之家','西羅亞之家','史提反之家',
    '康柏團','康勵團','加利利團',
    '錫安之家','應許之家','生命之家','仰望之家',
    '恩典之家','曙光之家','基督少年軍','少年SPORT','拿細耳','但以理',
    '其他',
];

export const UNITS = [
    '不適用',
    '拿栽','以栽','西栽','史栽',
    '家庭佈道隊','親子遊歷佈道隊','學生父母使命隊','以法蓮佈道隊','森林團契',
    'SPORT 大衛團','SPORT 彼得團','SPORT 安得烈團','SPORT 約翰團','SPORT 保羅團','SPORT 以諾','SPORT 約瑟團',
    'Re-creation 約書亞團','Re-creation 雅比斯團','Re-creation 提摩太團','Re-creation嘗恩侍食隊',
    '基青麥田園',
    '社區關懷及使命So-Act',
    '鴿子園',
    '壹同行',
    '職場使命隊MIC',
    '伯利恆之星',
    '綠色使命隊',
    '野外拓展隊',
    '歷奇更生',
    '演藝使命團',
    '關懷．佈道 信望愛',
    '拿細耳團',
    'M&M',
    '棋妙策士',
    '拼‧堅佈道團契BEGIN',
    '真善美DIY使命隊',
    '男人事工Man In God',
    '信仰生命分享組',
    '回家使命隊Bridge To Home',
    '信仰踐行使命隊Gsus4',
    '詩班',
    '敬拜讚美隊',
    '以斯拉敬拜隊',
    '屬靈操練同行隊 S.E.A.T',
    'UNIQUE 尋道者佈道隊',
    '兒童團',
    '輔導及生命成長部',
    '中學生事工部',
    '音響部',
];

/* ==================================================================================================== */
/* HYMNS CATEGORY */

export const WKSONG = [
    {id: 3, color: '#00A6DF' , key: '讚頌', tag: 'A'}       ,
    {id: 4, color: '#97C223' , key: '感謝', tag: 'B'}       ,
    {id: 5, color: '#F58220' , key: '渴慕', tag: 'C'}       ,
    {id: 3, color: '#00A6DF' , key: '認罪', tag: 'D'}       ,
    {id: 4, color: '#DD3F3E' , key: '記念', tag: 'E'}       ,
    {id: 5, color: '#EC008C' , key: '祈求', tag: 'A'}       ,
    {id: 6, color: '#00A6DF' , key: '立志', tag: 'A'}       ,
    {id: 7, color: '#F58220' , key: '盼望', tag: 'A'}       ,
    {id: 8, color: '#DD3F3E' , key: '堅信', tag: 'A'}       ,
    {id: 9, color: '#EC008C' , key: '佈道', tag: 'A'}       ,
    {id: 10, color: '#00A6DF' , key: '彼此激勵', tag: 'A'}       ,
    {id: 11, color: '#97C223' , key: '兒童', tag: 'A'}       ,
    {id: 12, color: '#F58220' , key: '聖誕', tag: 'A'}       ,
    {id: 13, color: '#00A6DF' , key: '復活', tag: 'A'}       ,
    {id: 14, color: '#DD3F3E' , key: '婚禮', tag: 'A'}       ,
    {id: 15, color: '#F58220' , key: '讚美', tag: 'A'}       ,
    {id: 16, color: '#00A6DF' , key: '個人詩歌靈修系列', tag: 'A'}       ,
];

/* ==================================================================================================== */
/* REGEXP */

export const PATTERN = {
    YOUTUBE : /https:\/\/www\.youtube\.com\/embed\/[^"]+/gm,
    CHINESE : /[\u4E00-\u9FFF]/gu,
    EMAIL   : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/m,
    // EMAIL   : /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/gm,
};

/* ==================================================================================================== */
/* REGEXP */

export const VALIDATION = {
    normal        : {message: '' },
    required      : {message: '必須填寫' },
    validUsername : {message: '請輸入有效的e電園帳號' },
    validPassword : {message: '請輸入有效的密碼' },
    validFullname : {message: '請輸入中文全名' },
    validEmail    : {message: '請輸入有效的電郵' },
    validPhone    : {message: '請輸入8位數字的聯絡電話' },
    validCode     : {message: '請輸入5位數字的電腦密碼' },
    validTicket   : {message: '請輸入有效的增值券號碼' },
    saveOnce      : {message: '儲存後，將不能更改' },
    isProtected   : {message: '不能更改' },
    available     : {message: '如適用' },
    different     : {message: '(如未輸入個人資料)' },
};

/* ==================================================================================================== */
/* TEMP */

export const GROWTH = {
    tabs: [
        {id: 0 , order: 0 , key: 'latestRes'   , value: '屬靈資源'} ,
        {id: 1 , order: 1 , key: 'videos'      , value: '最近聚會'} ,
        {id: 2 , order: 2 , key: 'suggestions' , value: '為你推薦'} ,
    ],
    categories: [
        {id: 0 , order: 0 , category: 'latest'      , key: 'life'        , value: '屬靈資源' , show: true , size: 2.5} ,
        {id: 1 , order: 1 , category: 'latest'      , key: 'devotion'    , value: '詩歌靈修' , show: true , size: 1}   ,
        {id: 2 , order: 2 , category: 'videos'      , key: 'meeting'     , value: '最近聚會' , show: true , size: 1.5} ,
        {id: 3 , order: 3 , category: 'videos'      , key: 'worships'    , value: '崇拜'   , show: true , size: 1.5} ,
        {id: 4 , order: 4 , category: 'videos'      , key: 'meetings'    , value: '祈禱會'  , show: true , size: 1.5} ,
        {id: 5 , order: 5 , category: 'videos'      , key: 'songs'       , value: '新歌'   , show: true , size: 1.5} ,
        {id: 6 , order: 6 , category: 'videos'      , key: 'prayers'     , value: '代禱'   , show: true , size: 1.5} ,
        {id: 7 , order: 7 , category: 'suggestions' , key: 'suggestions' , value: '為你推薦' , show: true , size: 1.5} ,
    ],
}
export const EASTER = {
    RANGE : {
        STAGE_1 : {START_DAY: '2024-03-27', END_DAY: '2024-04-13'},
        STAGE_2 : {START_DAY: '2024-04-14', END_DAY: '2024-05-04'},
        STAGE_3 : {START_DAY: '2024-05-05', END_DAY: '2024-05-18'},
    },
    tabs: [
        {id: 0,order: 0, key: "home", value: "復活期"},
        {id: 1,order: 1, key: "videos", value: "影片"},
        {id: 2,order: 2, key: "others", value: "與主同在"}
    ],
    categories: [
        {id: 0, order: 0, category: "latest", key: "devotion", value: "光明之路", show: true},
        {id: 1, order: 1, category: "latest", key: "meeting", value: "最近聚會", show: true},
        {id: 2, order: 2, category: "suggestions", key: "suggestions", value: "為你推薦", show: true},

        {id: 3, order: 0, category: "resources", key: "devotion", value: "光明之路", show: true},
        {id: 4, order: 1, category: "resources", key: "wallpaper", value: "⼗架苦路電腦桌布", show: true},
        {id: 5, order: 2, category: "resources", key: "handcraft", value: "每週⼿⼯製作", show: true},
        {id: 6, order: 3, category: "resources", key: "decoration", value: "家居佈置", show: true},

        {id: 7, order: 0, category: "videos", key: "worships", value: "崇拜", show: true},
        {id: 8, order: 1, category: "videos", key: "meetings", value: "祈禱會", show: true},
        {id: 9, order: 2, category: "videos", key: "songs", value: "新歌", show: true},
        {id: 10,order: 3, category: "videos", key: "prayers", value: "代禱", show: true},

        {id: 11,order: 0, category: "others", key: "testimony", value: "我以新歌⾒證主", show: true},
        {id: 12,order: 1, category: "others", key: "cell", value: "我的救贖主活著", show: true},
        {id: 13,order: 2, category: "others", key: "prayers", value: "願帶福⾳遠處⾛", show: true}
    ],
};

export const QUALIFICATIONS = [
    {id: 0, order: 0, name: '家員', label: 'ME', valid_date: '2009-01-04', expiry_date: '2019-12-31 23:59:59', status: true},
    {id: 1, order: 1, name: '栽培員', label: 'CP', valid_date: '2019-10-02', expiry_date: '2099-12-31 23:59:59', status: true},
    {id: 2, order: 2, name: '組長', label: 'LD', valid_date: '2019-10-02', expiry_date: '2099-12-31 23:59:59', status: true},
    {id: 3, order: 3, name: '幹事', label: 'CO', valid_date: '2019-10-02', expiry_date: '2099-12-31 23:59:59', status: true},
];

// TODO: Calculate Easter Constants
