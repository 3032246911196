import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ImageModalPage} from '../pages/modal/image-modal/image-modal';
import {VideoModalPage} from '../pages/modal/video-modal/video-modal';

@Injectable({
    providedIn: 'root',
})
export class ModalService {

    isPresenting: boolean = false;

    constructor(
        public modalCtrl: ModalController,
    ) {
    }

    /* ==================================================================================================== */
    /* Image Modal */

    async presentImageModal(img: string) {
        this.isPresenting = true;
        return await this.modalCtrl.create({
            component: ImageModalPage,
            componentProps: {
                img: img,
            }
        }).then(modal => {
            modal.present().then(() => {
                if (!this.isPresenting) {
                    modal.dismiss();
                }
            });
        });
    }

    /* ==================================================================================================== */
    /* Video Modal */

    async presentVideoModal(title: string, url: string) {
        const modal = await this.modalCtrl.create({
            component: VideoModalPage,
            componentProps: {
                modalController: this.modalCtrl,
                params: {
                    title : title,
                    url   : url,
                },
            },
        });
        return await modal.present();
    }

    async dismissModal() {
        this.isPresenting = false;
        return await this.modalCtrl.dismiss();
    }

}
