import * as Constants from '../configs/constants';

export class Banner {
    id           : number;
    order        : number;
    title        : string;
    src          : string;
    url          : string;
    release_time : string;
    expired_time : string;
    show         : boolean;
    focus        : boolean;
    start_time   : string;
    end_time     : string;

    constructor(id?: number, order?: number, title?: string, src?: string, url?: string, release_time?: string, expired_time?: string, show?: boolean, focus?: boolean, start_time?: string, end_time?: string) {
        this.id           = id           ;
        this.order        = order        ;
        this.title        = title        ;
        this.src          = src          ;
        this.url          = url          ;
        this.release_time = release_time ;
        this.expired_time = expired_time ;
        this.show         = show         ;
        this.focus        = focus        ;
        this.start_time   = start_time   ;
        this.end_time     = end_time     ;
    }

    static init(value: any) {
        // let instance = new Banner(-1, -1, '', '', '', '', '', false);
        let instance = new Banner();
        if (value === null) return instance;
        instance.id           = (value.id > 0) ? value.id : (value.id === 0) ? 0 : -1;
        instance.order        = (value.order > 0) ? value.order : (value.order === 0) ? 0 : -1;
        instance.title        = value.title        || '';
        instance.src          = value.src          || value.thumbnail || '';
        instance.url          = value.url          || '';
        instance.release_time = value.release_time || '';
        instance.expired_time = value.expired_time || '';
        instance.show         = value.show         || false;
        instance.focus        = value.focus        || false;
        instance.start_time   = value.start_time   || '';
        instance.end_time     = value.end_time     || '';
        return instance;
    }

    static getDefault() {
        return Banner.init({
            order        : 99,
            src          : 'https://www.wkphc.org/wp-content/uploads/2020/05/2020_growth_banner.jpg',
            release_time : Constants.EASTER.RANGE.STAGE_1.START_DAY,
            expired_time : Constants.EASTER.RANGE.STAGE_3.END_DAY,
            show         : true,
        });
    }
}
