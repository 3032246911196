<ion-card-content [style.font-size.px]="_size">
    <ion-row>
        <ion-col class="bg-col" [size]="5">
            <img class="bg-icon" [src]="_item['icon']" *ngIf="_item['icon']">
<!--            <img-loader class="bg-icon" [src]="_item['icon']" *ngIf="_item['icon']" [imgAttributes]="imageAttr"></img-loader>  -->
        </ion-col>
        <ion-col class="title-col">
            <div class="title-block">
                <ion-card-title [style.font-size.px]="_size" [innerHTML]="_item['menu_title']"></ion-card-title>
                <ion-icon [name]="'chevron-forward'" [slot]="'end'" [color]="'primay'" [size]="'large'"></ion-icon>
            </div>
        </ion-col>
    </ion-row>
</ion-card-content>

