import {EasterResource} from './easter-resource.model';

export class EasterVideo extends EasterResource {

    constructor(id?: number, order?: number, category?: string, key?: string, value?: string, title?: string, subtitle?: string, content?: string, excerpt?: string, thumbnail?: string, url?: string, release_time?: string, expired_time?: string, show?: boolean) {
        super(id, order, category, key, value, title, subtitle, content, excerpt, thumbnail, url, release_time, expired_time, show);
    }

    static init(value: any) {
        let instance = new EasterVideo();
        if (value === null) return instance;
        instance.id           = (value.id > 0) ? value.id : (value.id === 0) ? 0 : -1;
        instance.order        = (value.order > 0) ? value.order : (value.order === 0) ? 0 : -1;
        instance.category     = value.category     || '';
        instance.key          = value.key          || '';
        instance.value        = value.value        || '';
        instance.title        = value.title        || '';
        instance.subtitle     = value.subtitle     || '';
        instance.content      = value.content      || '';
        instance.excerpt      = value.excerpt      || '';
        instance.thumbnail    = value.thumbnail    || '';
        instance.url          = value.url          || '';
        instance.release_time = value.release_time || '';
        instance.expired_time = value.expired_time || '';
        instance.show         = value.show         || false;
        instance.type         = value.type         || '';
        instance.expanded     = value.expanded     || false;
        instance.buttons      = instance.configButtons(value.buttons);
        return instance;
    }

}
