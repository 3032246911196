/* ==================================================================================================== */
/* Framework Basics */
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

/* ==================================================================================================== */
/* Modules */
// import {ServiceWorkerModule} from '@angular/service-worker';
// import {ComponentsModule} from './components/components.module';
import {IonicStorageModule} from '@ionic/storage-angular';
import {HttpClientModule} from '@angular/common/http';
//import { FirebaseOptions } from '@angular/fire/app';

// Firebase v8
// import {AngularFireModule} from '@angular/fire/compat';
// import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
// import { FIREBASE_OPTIONS } from '@angular/fire/compat';
// import {AngularFireStorageModule} from '@angular/fire/compat/storage';
// import {AngularFireRemoteConfigModule, SETTINGS} from '@angular/fire/compat/remote-config';

// New v9 modular style
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectFirestoreEmulator, enableMultiTabIndexedDbPersistence, getFirestore, initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgPipesModule} from 'ngx-pipes';
import {NgxPaginationModule} from 'ngx-pagination';
import {PinchZoomModule} from './modules/ivypinch/pinch-zoom.module';
import {DevotionDateModule} from './pages/devotion/devotion-date/devotion-date.module';
import {ImageModalPageModule} from './pages/modal/image-modal/image-modal.module';
import {VideoModalPageModule} from './pages/modal/video-modal/video-modal.module';
import {VideoPopoverPageModule} from './pages/popovers/video-popover/video-popover.module';
// import { IonicImageLoaderModule } from 'ionic-image-loader-v5';
// import {ImageLoaderConfigService, IonicImageLoader} from 'ionic-image-loader';
import {SharedModule} from './shared/shared.module';
/* ==================================================================================================== */
/* Plugins */
// import {SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
// import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@capacitor/splash-screen';
import {StatusBar} from '@capacitor/status-bar';
import {Device} from '@awesome-cordova-plugins/device/ngx';
// import {Brightness} from '@awesome-cordova-plugins/brightness/ngx';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import { HttpClient } from '@angular/common/http';
import {Network} from '@awesome-cordova-plugins/network/ngx';
import {Market} from '@awesome-cordova-plugins/market/ngx';
import {SocialSharing} from '@awesome-cordova-plugins/social-sharing/ngx';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
// import {LocalNotifications} from '@awesome-cordova-plugins/local-notifications/ngx';
// import {BarcodeScanner} from '@awesome-cordova-plugins/barcode-scanner/ngx';
// import {WebView} from '@';
// import {ImagePicker} from '@awesome-cordova-plugins/image-picker/ngx';
// import {SQLite} from '@awesome-cordova-plugins/sqlite';
/* ==================================================================================================== */
/* Custom */
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment.prod';
import {
    fas,
    faPlus,
    faPlusCircle,
    faTrashAlt,
    faBackspace,
} from '@fortawesome/free-solid-svg-icons';

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>(resolve => {
  resolvePersistenceEnabled = resolve;
});

/* ==================================================================================================== */
@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            backButtonText: '返回',
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,

        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideFirestore(() => {
            const app = initializeApp(environment.firebaseConfig);
            const firestore = initializeFirestore(app, {experimentalForceLongPolling:true})
            // firestore = getFirestore(app);
            if (environment.useEmulators) {
                connectFirestoreEmulator(firestore, 'localhost', 8080);
            }
            // enableMultiTabIndexedDbPersistence(firestore).then(
            //     () => resolvePersistenceEnabled(true),
            //     () => resolvePersistenceEnabled(false)
            // );
            return firestore;
        }),
        provideStorage(() => {
            const storage = getStorage();
            if (environment.useEmulators) {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            return storage;
        }),
        provideRemoteConfig(() => getRemoteConfig()),

//        AngularFireModule.initializeApp(environment.firebaseConfig),
        // AngularFirestoreModule.enablePersistence(),
        // AngularFireStorageModule,
        // AngularFireRemoteConfigModule,

        FontAwesomeModule,
        NgPipesModule,
        NgxPaginationModule,
        PinchZoomModule,
        DevotionDateModule,
        ImageModalPageModule,
        VideoModalPageModule,
        VideoPopoverPageModule,
//         IonicImageLoaderModule,
        SharedModule,
    ],
    providers: [
        // StatusBar,
        // SplashScreen,
        Device,
//        Brightness,
        HttpClient,
        Network,
        Market,
        SocialSharing,
        InAppBrowser,
//        LocalNotifications,
//         BarcodeScanner,
//         WebView,
        // ImagePicker,
        // ImageLoaderConfigService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},

//        {provide: SETTINGS, useValue: { minimumFetchIntervalMillis: 10_000 }},
    //    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig }

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIcons(faPlus);
        library.addIcons(faPlusCircle);
        library.addIcons(faTrashAlt);
        library.addIcons(faBackspace);
    }
}
