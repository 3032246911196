export class Filter {
    key   : string;
    value : string;
    show  : boolean;

    constructor(key?: string, value?: string, show?: boolean) {
        this.key   = key;
        this.value = value;
        this.show  = show;
    }

    static init(value: any) {
        let instance = new Filter();
        instance.key   = value.key || '';
        instance.value = value.value || '';
        instance.show  = value.show || false;
        return instance;
    }
}
