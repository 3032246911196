import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'checkLink'
})
export class CheckLinkPipe implements PipeTransform {

    transform(link: any): string {
        if (link !== undefined && link.length > 0) {
            if (link.includes('https://youtu.be/')) return 'video';
            if (link.includes('https://soundcloud.com/')) return 'audio';
            if (link.includes('https://bit.ly/') || link.includes('https://devotion.wkphc.org/')) return 'document';
            if (link.includes('https://www.wkphc.org/')) return 'link';
        }
        return 'link';
    }

}
