import {Injectable} from '@angular/core';
import {Moment} from 'moment';
import * as moment from 'moment';
import * as Constants from '../../configs/constants';

@Injectable({
    providedIn: 'root',
})
export class DateService {

    devDate: string;
    currentDate: Moment;

    constructor(
    ) {
        this.devDate = '2024-02-22';
    }

    // ! dates are in ISO 8601 format,
    // ! HKT = 2020-01-01T00:00:00.000Z
    // ! ISO = 2020-01-01T00:00:00.000+08:00

    /* ==================================================================================================== */

    /* MOMENT */
    momentDate(date:string) {
        return moment(date).utcOffset('+08:00');
    }
    momentStartDate(date:string) {
        return this.momentDate(date).startOf('day');
    }
    momentEndDate(date:string) {
        return this.momentDate(date).endOf('day');
    }
    momentWeek() {
        return moment.localeData('zh-tw').weekdaysMin();
    }

    getCurrentMoment() {
        return moment();
    }

    /* TIMESTAMP */
    getTodayTimestamp() {
        return moment().startOf('day');
    }
    getTodayTimestampISO() {
        return moment().utcOffset('+08:00').startOf('day').toISOString(true);
    }

    getCurrentTimestamp() {
        // return this.momentDate('2021-04-04 00:00:01');
        // return this.momentDate('2021-04-06 12:00:00');
        // return this.momentDate('2021-04-20');
        // return this.momentDate('2021-05-10');
        return moment().utcOffset('+08:00');
    }

    getCurrentISOString() {
        return this.getCurrentTimestamp().toISOString(true);
    }

    /* ==================================================================================================== */

    /* STRING */
    formatDate(date:string, format: string = Constants.TIMESTAMP_FORMAT_EN) {
        return this.momentDate(date).format(format);
    }

    formatDateString(date:string, format: string = Constants.HUMAN_TIME_FORMAT_TC, prefix: string = '', suffix: string = '') {
        return prefix + this.momentDate(date).format(format) + suffix;
    }

    getDevDate() {
        return this.momentStartDate(this.devDate).format(Constants.TIMESTAMP_FORMAT_EN);
    }
    getTodayDate() {
        return this.getCurrentTimestamp().format(Constants.DATE_FORMAT_EN);
    }
    getTodayShort() {
        return this.getCurrentTimestamp().format(Constants.DATE_FORMAT_SHORT);
    }

    getCurrentTime() {
        return this.getCurrentTimestamp().format(Constants.TIMESTAMP_FORMAT_EN);
    }
    static getLastUpdateTime(date:string, format: string = Constants.HUMAN_TIME_FORMAT_TC, prefix: string = '', suffix: string = '') {
        return prefix + moment(date).utcOffset('+08:00').format(format) + suffix;
    }

    /* ==================================================================================================== */

    /* Number */

    getCurrentTimeNumber() {
        return Number(this.getCurrentTimestamp().format(Constants.DATE_FORMAT_ID_LONG));
    }

    /* ==================================================================================================== */

    /* COMPARE */
    validDate(date1: string, date2: string) {
        return this.getCurrentTimestamp().isBetween(date1, date2, null, '[]');
    }

    inRange(lowerBound: string = Constants.LENT_START_DAY, upperBound: string = Constants.LENT_END_DAY, srcDate: string = '') {
        let srcMoment = moment();
        if (srcDate != '')
            srcMoment = this.momentDate(srcDate);
        else
            srcMoment = this.getCurrentTimestamp();        
        return srcMoment.isBetween(lowerBound, upperBound, null, '[]');
    }

    isSame(targetDate: string, granularity: any, srcDate: string = '') {
        let srcMoment = moment();
        if (srcDate != '')
            srcMoment = this.momentDate(srcDate);
        else
            srcMoment = this.getCurrentTimestamp();        
        return srcMoment.isSame(targetDate, granularity);
    }
    isBefore(targetDate: string, granularity: any = 'day', srcDate: string = '') {
        let srcMoment = moment();
        if (srcDate != '')
            srcMoment = this.momentDate(srcDate);
        else
            srcMoment = this.getCurrentTimestamp();        
        return srcMoment.isBefore(targetDate, granularity);
    }
    isAfter(targetDate: string, granularity: any = 'day', srcDate: string = '') {
        let srcMoment = moment();
        if (srcDate != '')
            srcMoment = this.momentDate(srcDate);
        else
            srcMoment = this.getCurrentTimestamp();        
        return srcMoment.isAfter(targetDate, granularity);
    }

}
