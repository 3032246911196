import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

import * as Constants from '../../../configs/constants';
import {GlobalVars} from '../../../providers/globalvars';

@Component({
    selector: 'page-video-popover',
    templateUrl: './video-popover.html',
    styleUrls: ['./video-popover.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VideoPopoverPage implements OnInit {

    params : any;
    url    : SafeResourceUrl;

    constructor(
        private modalCtrl   : ModalController,
        private navParams   : NavParams,
        private sanitizer   : DomSanitizer,
        public globalVars   : GlobalVars,
    ) {
    }

    ngOnInit() {
        this.params = this.navParams.get('params');
        this.url    = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.params['code']}?rel=0&showinfo=0&playsinline=1`);
    }

    seenTutorial() {
        this.globalVars.dataService.setData(Constants.KEY_SEEN_TUTORIAL, true)
            .then(() => this.close());
    }

    close() {
        this.modalCtrl.dismiss();
    }

}
