<p class="refresher-container">
    <ion-refresher id="refresher" slot="fixed" (ionRefresh)="refresh($event)"
                   [pullMin]="180">
        <ion-refresher-content
            [pullingIcon]="'arrow-down'"
            [pullingText]="'請下拉更新'"
            [refreshingSpinner]="'circles'"
            [refreshingText]="'我的心默默無聲，專等候神...'">
        </ion-refresher-content>
    </ion-refresher>
</p>
