import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'isItem'
})
export class IsItemPipe implements PipeTransform {

    transform(items: any): any {
        return items.some(item => item.type.includes('item'));
    }

}
