import {Category} from './category.model';

export class HymnCategory extends Category {
    tag : string;
    color : string;

    constructor(id?: number, count?: number, name?: string, slug?: string, tag?: string, color?: string, link?: string) {
        super(id, count, name, slug, link);
        this.tag   = tag;
        this.color = color;
    }

    static init(cat: any) {
        let instance   = new HymnCategory();
        instance.id    = cat.id;
        instance.count = cat.count;
        instance.name  = instance.handleNameTag(cat.name, 'name');
        instance.slug  = cat.slug;
        instance.link  = cat.link;
        instance.tag   = instance.handleNameTag(cat.name, 'tag');
        instance.color = instance.handleColor(cat.id);
        return instance;
    }

    handleNameTag(name: string, target: string) {
        if (target === 'name') {
            return (name.includes(' ')) ? name.split(' ')[1] : name;
        } else if (target === 'tag') {
            return (name.includes(' ')) ? name.split(' ')[0] : '';
        }
    }

    handleColor(id: number) {
        let color = '';
        if ([3, 6, 9, 13, 16].includes(id)) {
            color = '#00A6DF';
        } else
        if ([4, 15].includes(id)) {
            color = '#97C223';
        } else
        if ([5, 10, 14, 21].includes(id)) {
            color = '#F58220';
        } else
        if ([7, 11, 17].includes(id)) {
            color = '#DD3F3E';
        } else
        if ([8, 12].includes(id)) {
            color = '#EC008C';
        } else {
            color = '#996097';
        }
        return color;
    }

}
