interface ActionButtons {
    name: string;
    link: string;
}

export class ResourceBase {

    id           : number  ;
    order        : number  ;
    category     : string  ;
    key          : string  ;
    value        : string  ;

    title        : string  ;
    subtitle     : string  ;
    content      : string  ;
    excerpt      : string  ;

    thumbnail    : string  ;
    url          : string  ;

    release_time : string  ;
    expired_time : string  ;
    show         : boolean ;

    buttons      : any;

    constructor(id?: number, order?: number, category?: string, key?: string, value?: string, title?: string, subtitle?: string, content?: string, excerpt?: string, thumbnail?: string, url?: string, release_time?: string, expired_time?: string, show?: boolean, buttons?: any) {
        this.id           = id           ;
        this.order        = order        ;
        this.category     = category     ;
        this.key          = key          ;
        this.value        = value        ;
        this.title        = title        ;
        this.subtitle     = subtitle     ;
        this.content      = content      ;
        this.excerpt      = excerpt      ;
        this.thumbnail    = thumbnail    ;
        this.url          = url          ;
        this.release_time = release_time ;
        this.expired_time = expired_time ;
        this.show         = show         ;
        this.buttons      = this.configButtons(buttons);
    }

    configButtons(buttons: string) {
        let result: ActionButtons[];
        if (buttons !== undefined) {
            try {
                buttons = buttons.replace(/'/gm, '"');
                result = JSON.parse(buttons);
                return result;
            } catch (e) {
                result = [];
                return result;
            }
        }
    }

}
