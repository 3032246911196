import * as moment from 'moment';
import * as Constants from '../configs/constants';

export class ServerLog {

    log_key      : string ;
    log_type     : string ;
    api_type     : string ;
    status       : string ;
    timestamp    : string ;
    url          : string ;
    device_id    : string ;
    device_model : string ;
    os_version   : string ;
    app_version  : string ;

    constructor(log_key?: string, log_type?: string, api_type?: string, status?: string, timestamp?: string, url?: string, device_id?: string, device_model?: string, os_version?: string, app_version?: string) {
        this.log_key      = log_key      ;
        this.log_type     = log_type     ;
        this.api_type     = api_type     ;
        this.status       = status       ;
        this.timestamp    = timestamp    ;
        this.url          = url          ;
        this.device_id    = device_id    ;
        this.device_model = device_model ;
        this.os_version   = os_version   ;
        this.app_version  = app_version  ;
    }

    static init(value: any) {
        return new ServerLog(
            value.log_key || '',
            value.log_type || '',
            value.api_type || '',
            value.status || '',
            value.timestamp || '',
            value.url || '',
            value.device_id || '',
            value.device_model || '',
            value.os_version || '',
            value.app_version || '',
        );
    }

    getLogDate() {
        return moment(this.timestamp).utcOffset('+08:00').format(Constants.DATE_FORMAT_EN);
    }

    toLog() {
        return {
            log_key      : this.log_key      ,
            log_type     : this.log_type     ,
            api_type     : this.api_type     ,
            status       : this.status       ,
            timestamp    : this.timestamp    ,
            url          : this.url          ,
            device_id    : this.device_id    ,
            device_model : this.device_model ,
            os_version   : this.os_version   ,
            app_version  : this.app_version  ,
        };
    }

}
