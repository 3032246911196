<ion-content>
    <pinch-zoom #pinchZoom [properties]="properties">
        <img [src]="img" [alt]="">
    </pinch-zoom>
</ion-content>
<ion-footer class="ion-no-margin ion-no-padding ion-no-border">
    <ion-toolbar [color]="'primary'">
        <ion-grid>
            <ion-row>
                <ion-col [size]="6" (click)="close()">
                    <ion-button class="vh-center" [fill]="'clear'" [color]="'light'">
                        <ion-icon name="close" slot="start" [size]="'large'"></ion-icon>
                        關閉
                    </ion-button>
                </ion-col>
                <ion-col [size]="6" (click)="zoom()" [hidden]="pinchZoom.isZoomedIn">
                    <ion-button class="vh-center" [fill]="'clear'" [color]="'light'">
                        <ion-icon [name]="'add'" [slot]="'start'" [size]="'large'"></ion-icon>
                        放大
                    </ion-button>
                </ion-col>
                <ion-col [size]="6" (click)="zoom()" [hidden]="!pinchZoom.isZoomedIn">
                    <ion-button class="vh-center" [fill]="'clear'" [color]="'light'">
                        <ion-icon [name]="'remove'" [slot]="'start'" [size]="'large'"></ion-icon>
                        縮小
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
