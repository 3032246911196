import { Injectable } from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
// import firebase from 'firebase/compat/app';
// import FieldValue = firebase.firestore.FieldValue;
// import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

import { Firestore, doc, docData, getDoc, setDoc, arrayUnion, arrayRemove, deleteField, DocumentSnapshot, DocumentData } from '@angular/fire/firestore';

import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {BehaviorSubject, forkJoin, Observable, of, zip} from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged, filter, map, retryWhen,
    switchMap,
    take,
    tap
} from 'rxjs/operators';

import _ from 'lodash-es';
import {classToPlain} from 'class-transformer';

import * as Constants from '../../configs/constants';
import {User} from '../../models/user.model';
import {Log} from '../../models/log.model';
import {Festival} from '../../models/festival.model';
import {Banner} from '../../models/banner.model';
// /* ================================================== */ //
/* LENT STUFF */
import {LentDate} from '../../models/lent-date.model';
import {CardSetting, LentCard} from '../../models/lent-card.model';
import {LentEntry} from '../../models/lent-entry.model';
// /* ================================================== */ //
/* Festival STUFF */
import {GrowthCategory} from '../../shared/models/growth-category.model';
import {GrowthResource} from '../../shared/models/growth-resource.model';
import { LentAmbition } from 'src/app/models/lent-ambition.model';

@Injectable({
    providedIn: 'root'
})
export class FestivalService extends BaseService {

    // Lent Card
    // Lent Record
    // Lent Res
    private collection_key : string;
    private _card_key      : string;

    private _lentCard      : LentCard = LentCard.init(null);
    private _local_card       = new BehaviorSubject<LentCard>(this._lentCard);
    private _fs_archive       = new BehaviorSubject<LentCard>(this._lentCard);
    public  localCard$        = this._local_card.asObservable();
    public firestoreArchive$  = this._fs_archive.asObservable();

    constructor(
        // protected http        : HTTP,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
        this.collection_key = '';
        this._card_key      = '';
    }

    // /* ================================================== */ //
    /*
    !!!!!!!!!! GENERAL
    */

    getChurchFestivals(): Observable<Festival[]> {
        return docData(doc(this.fireStore, Constants.COLLECTION_CHURCH_FESTIVALS + "/" + this.dataService.getAPIEnv())).pipe(
            map(values => values['array'].map(value => Festival.init(value))),
            distinctUntilChanged(),
        );

        // const q = query(collection(this.fireStore, Constants.COLLECTION_CHURCH_FESTIVALS), orderBy("start_time", 'asc'));
        // return collectionData(q).pipe(
        // );
        
        // return this.fireStore
        //     .collection<Festival>(Constants.COLLECTION_CHURCH_FESTIVALS, ref => ref.orderBy('start_time', 'asc'))
        //     .doc(this.dataService.getAPIEnv())
        //     .snapshotChanges().pipe(
        //         map(values => values.payload.data()['array'].map(value => Festival.init(value))),
        //         distinctUntilChanged(),
        //     );
    }

    getFestivalResources(): Observable<any> {
        return docData(doc(this.fireStore, Constants.COLLECTION_FESTIVAL_SETTINGS.MAIN_RES + "/" + this.dataService.getAPIEnv())).pipe(
            map(values => this.fetchFestivalResources(values['growth'])),
            distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
        );
        // return this.fireStore
        //     .collection<any>(Constants.COLLECTION_FESTIVAL_SETTINGS.MAIN_RES)
        //     .doc(this.dataService.getAPIEnv())
        //     .snapshotChanges().pipe(
        //         map(values => this.fetchFestivalResources(values.payload.data()['growth'])),
        //         distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
        //         // tap(output => console.log('api output: ', output)),
        //     );
    }

    /**
     * Callback. Update app according to items fetched by getFestivalResources()
     * @param items 
     * @returns 
     */
    fetchFestivalResources(items: any) {
        let result = {};
        if (items !== undefined) {
            let tabs          = (items.tabs??Constants.GROWTH.tabs).map(item => GrowthResource.init(item)).sort(this.dataService.compareValues(Constants.SORT_ORDER));
            let categories    = (items.categories??Constants.GROWTH.categories).map(item => GrowthCategory.init(item)).sort(this.dataService.compareValues(Constants.SORT_ORDER));
            let banners       = (items.banners??[]).map(item => Banner.init(item));
            let latestRes     = (items.latest??[]).map(item => GrowthResource.init(item));
            let suggestionRes = (items.suggestions??[]).map(item => GrowthResource.init(item));//.sort(this.dataService.compareValues(Constants.SORT_ORDER));;
            let videos        = (items.videos??[]).map(item => GrowthResource.init(item));

            result = {
                tabs          : (this.dataService.hasValues(tabs)) ? this.dataService.filterValidElements(tabs) : [],
                categories    : categories,
                banners       : (this.dataService.hasValues(banners)) ? this.dataService.filterValidElements(banners)             : [Banner.getDefault()],
                latestRes     : (this.dataService.hasValues(latestRes)) ? this.dataService.filterValidElements(latestRes)         : [],
                videoRes      : (this.dataService.hasValues(videos)) ? this.dataService.filterValidElements(videos) : [],
                suggestionRes : (this.dataService.hasValues(suggestionRes)) ? this.dataService.filterValidElements(suggestionRes) : [],
            };

            // console.log(result["suggestionRes"]);
            // console.log(result["categories"]);
        }
        return result;
    }

    getFestivalBound(type: string) {
        let target;
        switch(type) {
            case 'dev': target = this.dateService.getDevDate(); break;
            case 'lent-lower': target = this.dateService.momentStartDate(Constants.LENT_START_DAY).format(Constants.TIMESTAMP_FORMAT_EN); break;
            case 'lent-upper': target = this.dateService.momentEndDate(Constants.LENT_END_DAY).format(Constants.TIMESTAMP_FORMAT_EN); break;
            case 'easter-lower': target = this.dateService.momentStartDate(Constants.EASTER.RANGE.STAGE_1.START_DAY).format(Constants.TIMESTAMP_FORMAT_EN); break;
            case 'easter-upper': target = this.dateService.momentEndDate(Constants.EASTER.RANGE.STAGE_3.END_DAY).format(Constants.TIMESTAMP_FORMAT_EN); break;
        }
        return target;
    }

    // /* ================================================== */ //
    /* LENT STUFF */

    public getLentCurrentDay(date: string = this.dateService.getTodayDate()): Observable<LentDate> {
        // if (this.dateService.momentDate(date) < this.dateService.momentDate(Constants.LENT_START_DAY)) {
        //     date = this.dateService.momentDate(Constants.LENT_START_DAY).format(Constants.DATE_FORMAT_EN);
        // }
        if (this.dataService.lentDates !== undefined) {
            return this.dataService.lentDates
                .pipe(
                    switchMap(dates => dates.filter(day => day.getDateEN() === date))
                );
        } else {
            return of(LentDate.init({
                index: -1,
                momentDate: this.dateService.momentDate(Constants.LENT_START_DAY),
                weekday: this.dateService.momentDate(Constants.LENT_START_DAY).days(),
                dirty: false
            }));
        }
    }

    /* LENT CARD */

    getLocalCardValue() {
        // return plainToClass(LentCard, (this._local_card.getValue() == undefined) ? LentCard.init(null) : this._local_card.getValue());
        // console.log("getLocalCardValue");
        // console.log(this._local_card.getValue());
        return (this._local_card.getValue() == undefined) ? LentCard.init(null) : this._local_card.getValue();
    }

    /**
     * For API use?
     * @param key 
     * @returns 
     */
    getLentCardSnapshot(key: string) : Observable<LentCard> {
        if (key === undefined || key === null)
            return of(LentCard.init(null));
        return docData(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD + "/" + key)).pipe(
                map(value => LentCard.init(value)),
                distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
                take(1),
        );
        // return this.fireStore
        //     .collection<LentCard>(Constants.COLLECTION_LENT_CURRENT.CARD)
        //     .doc(key)
        //     .snapshotChanges()
        //     .pipe(
        //         tap(value => console.log('api output: ', value)),
        //         filter(snap => !snap.payload.metadata.fromCache),
        //         map(value => LentCard.init(value.payload.data())),
        //         distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
        //         take(1),
        //         tap(output => console.log('api output: ', output)),
        //     );
    }

    /**
     * 
     * @param key string: the Card Key (email address)
     */
//    getFirestoreCardSnapshot(key: string) : Observable<LentCard> {
    getFirestoreCardSnapshot(key: string) : Promise<DocumentSnapshot<DocumentData>> {
        return getDoc(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD + "/" + key));

//         return docData(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD + "/" + key)).pipe(
//             map(value => LentCard.init(value)),
//             distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
// //            take(1),
        // );
        // return this.fireStore
        //     .collection<LentCard>(Constants.COLLECTION_LENT_CURRENT.CARD)
        //     .doc(key)
        //     .get({ source: 'server' })
        //     .pipe(
        //         map(value => LentCard.init(value.data())),
        //         distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
        //     );
    }

    async saveSnapshot(snapshot: LentCard) {
        console.log("Save Snapshot");
        console.log(snapshot);
        return this._local_card.next(snapshot);
    }

    updateLentCard(args: any) {
        let snapshot = this.getLocalCardValue();
        // save version info
        if (args instanceof Log) {
            snapshot.log = args;
        }
        // register owner
        if (args instanceof User) {
            snapshot.owner = args;
        }
        // configure settings
        if (args instanceof CardSetting) {
            snapshot.setting = args;
        }
        // update timestamp
        snapshot.last_update = this.dateService.getCurrentTime();
        this._local_card.next(snapshot)
        console.log('updated card: ', this.getLocalCardValue());
    }

    getTotal(prefix: string) : string {
        let total = `${prefix} $ `;
        if (this.getLocalCardValue().setting.mode === Constants.TYPE.record.I) {
            total += `${Number(this.getLocalCardValue().total).toFixed(1)}`;
        } else if (this.getLocalCardValue().setting.mode === Constants.TYPE.record.D) {
            total += `${Number(this.getLocalCardValue().setting.maximum - this.getLocalCardValue().total).toFixed(1)}`;
        }
        return total;
    }

    getFormattedTotal() : string {
        let total = '';
        this.localCard$
            .pipe(take(1))
            .subscribe(card => {
                if (card !== undefined) {
                    if (this.getLocalCardValue().setting.mode === Constants.TYPE.record.I) {
                        total = `饑饉卡總額：<br/>$ ${Number(this.getLocalCardValue().total).toFixed(1)}`;
                    } else if (this.getLocalCardValue().setting.mode === Constants.TYPE.record.D) {
                        total = `饑饉卡<span class="caution">餘額</span>：<br/>$ ${Number(this.getLocalCardValue().setting.maximum - this.getLocalCardValue().total).toFixed(1)}`;
                    }
                }
            });
        return total;
    }

    checkLentCardOwnership() : boolean {
        return (this.getLocalCardValue() !== undefined && this.getLocalCardValue().owner !== undefined);
    }

    async initLentCard(args : any) {
        return setDoc(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD + "/" + args.owner.email), args, {merge:true});
        // return this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.CARD)
        //     .doc(args.owner.email)
        //     .set(args, {merge: true})
        //     .then(() => {});
    }

    fetchLentCard(key: string) : Observable<LentCard> {
        return docData(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD)).pipe(
            map(value => LentCard.init(value)),
            tap(output => console.log('api output: ', output)),
            take(1),
            distinctUntilChanged(),
        );
        // return this.fireStore
        //     .collection<LentCard>(Constants.COLLECTION_LENT_CURRENT.CARD)
        //     .doc(key)
        //     .snapshotChanges().pipe(
        //         map(value => LentCard.init(value.payload.data())),
        //         tap(output => console.log('api output: ', output)),
        //         take(1),
        //         distinctUntilChanged(),
        //     );
    }

    async syncLentCard(args: any) {
        let target = classToPlain(args.card);
        return setDoc(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD + "/" + args.key), target, {merge:true});

        // return await this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.CARD)
        //     .doc(args.key)
        //     .set(target, {merge: true})
        //     // .update(args);
    }

    async setLentAmbition(ambitions: any, key: string) {
        let target = classToPlain({"ambitions" : ambitions});
        return setDoc(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.AMBITIONS + "/" + key), target, {merge:false});
        // return await this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.AMBITIONS)
        //     .doc(key)
        //     .set(target, {merge: false})
    }

    getLentAmbition(key: string): Observable<any> {
        return docData(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.AMBITIONS + "/" + key)).pipe(
//            take(1),
        );
        // return this.fireStore
        //     .collection<LentAmbition>(Constants.COLLECTION_LENT_CURRENT.AMBITIONS)
        //     .doc(key)
        //     .snapshotChanges()
        //     .pipe(
        //         take(1),
        //     );
    }

    getDefaultLentAmbition() {
        return Constants.COLLECTION_LENT_CURRENT.DEFAULT_AMBITION;
    }

    validateLentCard(args: any) : Observable<any> {
        return docData(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD + "/" + args.key)).pipe(
//            map(values => LentCard.init(values)),
            take(1),
        );

        // return this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.CARD)
        //     .doc(args.key)
        //     .valueChanges()
        //     .pipe(
        //         map(values => LentCard.init(values)),
        //         take(1),
        //     );
    }

    updateOfferingTotal(args: any) {
        if (args.key.length > 0 ) {
            args.total = Number(Number(args.total).toFixed(1));
            setDoc(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD + "/" + args.key), args, {merge:true});
            // this.fireStore
            //     .collection(Constants.COLLECTION_LENT_CURRENT.CARD)
            //     .doc(args.key)
            //     .set(args, {merge: true})
            //     .then(() => {});
        }
    }

    // // /* ================================================== */ //
    // /* Lent Record */

    getOfferingRecord(args: any) : Observable<any> {
        return docData(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD + "/" + args.key)).pipe(
            map(value => value),
            distinctUntilChanged(),
        );

        // return this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.CARD)
        //     .doc(args.key)
        //     .snapshotChanges()
        //     .pipe(
        //         map(value => value.payload.data()),
        //         distinctUntilChanged()
        //     );
    }

    public checkRecord(index: number): boolean {
        let exist = false;
        if (this.dataService.lentRecord$ !== undefined) {
            this.dataService.lentRecord$
                .pipe(take(1))
                .subscribe(items => {
                    if (items[index] !== undefined && items[index].length > 0) {
                        exist = true;
                    }
                });
        }
        return exist;
    }

    public getEntriesByIndex(index: number): LentEntry[] {
        let record = [];
        if (this.dataService.lentRecord$ !== undefined) {
            this.dataService.lentRecord$
                .pipe(take(1))
                .subscribe(items => {
                    if (items[index] !== undefined && items[index].length > 0) {
                        record =  items[index];
                    }
                });
        }
        return record;
    }

    // // /* ================================================== */ //
    // /* Lent Entry */

    async pushOfferingEntry(args: any) {
        // let target = (args.target as LentEntry).toObject();
        try {
            let result = await setDoc(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD + "/" + args.key),
                // args.target
                {
                    // entries: { [args.date]: arrayUnion(target) },
                    // entries: { [args.date]: args.target },
                    entries: args.target,
                    last_update: args.last_update,
                },
                {merge: true}
            );
            console.log(result);
        } catch (e) {
            console.log(e);
        }
        
        // await this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.CARD)
        //     .doc(args.key)
        //     .set({
        //         entries: {
        //             [args.date]: FieldValue.arrayUnion(target)
        //         },
        //         last_update: args.last_update,
        //     }, {merge: true})
            // .then(() => console.log('added obj: ', target));
    }

    async removeOfferingEntry(args: any) {
        let target = Object.assign({}, args.target);
        await setDoc(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD + "/" + args.key),
            {
                entries: { [args.date]: arrayRemove(target) },
                last_update: args.last_update,
            },
            {merge: true}
        )

        // await this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.CARD)
        //     .doc(args.key)
        //     .set({
        //         entries: {
        //             [args.date] : FieldValue.arrayRemove(target)
        //         },
        //         last_update: args.last_update,
        //     }, {merge: true})
            .then(() => console.log('removed obj: ', target));
    }

    /**
     * Remove the complete entry array for a specific date in firebase.
     * May need debug.
     * @param args 
     */
    removeOfferingDate(args: any) {
        setDoc(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.CARD + "/" + args.key), 
        {
            entries: { [args.date]: deleteField() }
        }, {merge: true});

        // this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.CARD)
        //     .doc(args.key)
        //     .set({
        //         entries: {
        //             [args.date]: FieldValue.delete()
        //         }
        //     }, {merge: true})
        //     .then(() => {});
    }

    // // /* ================================================== */ //
    // /* Lent Archive */

    validateID(id: string) {
        return docData(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.IDS + "/" + id));
        // return this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.IDS)
        //     .doc(id)
        //     .get();
    }

    async reserveID(args: any) {
        return await setDoc(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.IDS + "/" + args.key), args, {merge:true});
        // return await this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.IDS)
        //     .doc(args.key)
        //     .set(args, {merge: true});
    }

    /**
     * Need check: result.exists may not work
     * @returns Observable<any>
     */
    getAvailableID() : Observable<any> {
        let newid = this.dataService.generateID().substring(0, 6);
        return this.validateID(newid).pipe(
            map((result) => {
                if (result !== undefined) {
                    throw new Error(); // caught by retryWhen
                } else {
                    return newid;
                }
            }), retryWhen(errors=>errors.pipe(debounceTime(300), take(5))) // retry after 100ms
        );
        // return this.validateID(this.dataService.generateID().substr(0,6))
        //     .pipe(
        //         map((result: DocumentSnapshot) => {
        //             if (result.exists) {
        //                 throw new Error(); // caught by retryWhen
        //             } else {
        //                 return result.id;
        //             }
        //         }), retryWhen(errors=>errors.pipe(debounceTime(300), take(5))) // retry after 100ms
        //     );
    }

    getFirestoreArchiveValue() {
        return (this._fs_archive.getValue() == undefined) ? LentCard.init(null) : this._fs_archive.getValue();
    }

    updateFirestoreArchive(archive: LentCard) {
        this._fs_archive.next(archive);
    }

    async archiveLentCard(args: any) {
        let target = classToPlain(args.card);
        if (target.setting.mode === Constants.TYPE.record.D) {
            target.total = target.setting.maximum - target.total;
        }
        target.total = Number(Number(target.total).toFixed(1));
        target.last_update = this.dateService.getCurrentTime();

        return await setDoc(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.ARCHIVES + "/" + args.key), target, {merge:true});
        // return await this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.ARCHIVES)
        //     .doc(args.key)
        //     .set(target, {merge: true});
    }

    getArchiveSnapshot(key: string) : Observable<LentCard> {
        return docData(doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.ARCHIVES + "/" + key)).pipe(
            map(value => LentCard.init(value)),
            distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
        );
        // return this.fireStore
        //     .collection<LentCard>(Constants.COLLECTION_LENT_CURRENT.ARCHIVES)
        //     .doc(key)
        //     .get()
        //     .pipe(
        //         map(value => LentCard.init(value.data())),
        //         distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
        //     );
    }
}
