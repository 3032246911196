import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-refresher',
    templateUrl: './refresher.component.html',
    styleUrls: ['./refresher.component.scss'],
})
export class RefresherComponent implements OnInit {

    @Output('pull') refresherPulled = new EventEmitter();


    constructor() {
    }

    ngOnInit() {
    }

    refresh(ev) {
        this.refresherPulled.emit(ev);
    }

}
