import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ComponentsModule} from '../providers/componentsModule';
import {NgPipesModule} from 'ngx-pipes';
// import {IonicImageLoaderModule} from 'ionic-image-loader-v5';
/* ==================================================================================================== */
/* Directives */
import {RoundedCornerDirective} from './directives/rounded-corner.directive';
import {DynamicBackgroundDirective} from './directives/dynamic-background.directive';
import {VisibilityControlDirective} from './directives/visibility-control.directive';
import {ZoomableImageDirective} from './directives/zoomable-image.directive';
/* ==================================================================================================== */
/* Components */
import {SlidesComponent} from './components/slides/slides.component';
import {BannerComponent} from './components/banner/banner.component';
import {DetailDirective} from './directives/detail.directive';
import {TabsComponent} from './components/tab/tabs/tabs.component';
import {TabItemComponent} from './components/tab/tab-item/tab-item.component';
import {TabBodyComponent} from './components/tab/tab-body/tab-body.component';
import {TabLabelComponent} from './components/tab/tab-label/tab-label.component';
import {ResourceGridComponent} from './components/grid/resource-grid/resource-grid.component';
import {VideoItemComponent} from './components/video-item/video-item.component';
import {SummaryCardComponent} from './components/card/summary-card/summary-card.component';
import {PostListComponent} from './components/post/post-list/post-list.component';
/* ==================================================================================================== */
/* Pipes */
import {TypeofPipe} from './pipes/typeof.pipe';
import {IsBannerPipe} from './pipes/is-banner.pipe';
import {IsVideoPipe} from './pipes/is-video.pipe';
import {IsListPipe} from './pipes/is-list.pipe';
import {IsItemPipe} from './pipes/is-item.pipe';
import {IsGridPipe} from './pipes/is-grid.pipe';
import {CheckLinkPipe} from './pipes/check-link.pipe';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ComponentsModule,
        NgPipesModule,
//         IonicImageLoaderModule,
    ],
    declarations: [
        // Directives
        RoundedCornerDirective,
        DynamicBackgroundDirective,
        DetailDirective,
        VisibilityControlDirective,
        ZoomableImageDirective,
        // Components
        SlidesComponent,
        BannerComponent,
        TabsComponent,
        TabItemComponent,
        TabBodyComponent,
        TabLabelComponent,
        ResourceGridComponent,
        VideoItemComponent,
        SummaryCardComponent,
        PostListComponent,
        // Pipes
        TypeofPipe,
        IsBannerPipe,
        IsVideoPipe,
        IsListPipe,
        IsItemPipe,
        IsGridPipe,
        CheckLinkPipe,
    ],
    providers: [],
    exports: [
        CommonModule,
        // Directives
        RoundedCornerDirective,
        DynamicBackgroundDirective,
        DetailDirective,
        VisibilityControlDirective,
        // Components
        SlidesComponent,
        BannerComponent,
        TabsComponent,
        TabItemComponent,
        TabBodyComponent,
        TabLabelComponent,
        ResourceGridComponent,
        SummaryCardComponent,
        PostListComponent,
        ZoomableImageDirective,
    ]
})
export class SharedModule {
}
