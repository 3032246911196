export class LentAmbition {
    member      : string;
    ambition    : string;
    last_update : string;

    constructor(member?: string, ambition?: string, last_update?: string) {
        this.member      = member      ?? '';
        this.ambition    = ambition    ?? '';
        this.last_update = last_update ?? '';
    }

    static init(value: any) {
        let instance = new LentAmbition();
        if (value == null) return instance;
        instance.member      = value.member      ?? '';
        instance.ambition    = value.ambition    ?? '';
        instance.last_update = value.last_update ?? '';
        return instance;
    }

    update(data: LentAmbition) {
        this.ambition    = data.ambition    ?? '';
        this.last_update = data.last_update ?? '';
    }

    toObject() {
        return {
            ambition    : this.ambition    ,
            last_update : this.last_update ,
        };
    }

}
