import {AfterViewInit, Directive, ElementRef, OnInit, Renderer2} from '@angular/core';
import {DateService} from '../../core/services/dateService';
import * as Constants from '../../configs/constants';

@Directive({
    selector: '[appDynamicBackground]'
})
export class DynamicBackgroundDirective implements AfterViewInit {

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private dateService: DateService,
    ) {
    }

    ngAfterViewInit(): void {
        let bgColor;
        if (this.dateService.inRange(Constants.EASTER.RANGE.STAGE_1.START_DAY,Constants.EASTER.RANGE.STAGE_1.END_DAY)) {
            bgColor = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-easter-stage-1');
        }
        else if (this.dateService.inRange(Constants.EASTER.RANGE.STAGE_2.START_DAY,Constants.EASTER.RANGE.STAGE_2.END_DAY)) {
            bgColor = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-easter-stage-2');
        }
        else if (this.dateService.inRange(Constants.EASTER.RANGE.STAGE_3.START_DAY,Constants.EASTER.RANGE.STAGE_3.END_DAY)) {
            bgColor = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-easter-stage-3');
        }
        else {
            bgColor = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-easter-stage-1')
        }
        this.renderer.setStyle(
            this.elementRef.nativeElement,
            'background', bgColor
        );
    }

}
