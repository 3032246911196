import { Injectable } from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore, query, collection, collectionData, orderBy } from '@angular/fire/firestore';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {Meeting} from '../../models/meeting.model';
import {BehaviorSubject, Observable, zip} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, take, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeetingService extends BaseService {

    private _meetings = new BehaviorSubject<Meeting[]>([]);
    private dataStore : { items : Meeting[] } = { items : [] };
    public readonly meetings = this._meetings.asObservable();
    collection_key : string;

    constructor(
        // protected http        : HTTP,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
        this.collection_key = Constants.COLLECTION_MEETINGS;
    }

    getMeetings(): Observable<Meeting[]> {
        const q = query(collection(this.fireStore, this.collection_key), orderBy('id', 'desc'));
        return collectionData(q).pipe(
            debounceTime(Constants.TIMEOUT_API_BLINK),
            map(items => items
                .map(item => Meeting.init(item))
                .filter(item => item.show && this.dateService.validDate(item.release_time, item.expired_time)),
            ),
            tap(items => this.dataService.setData(this.collection_key, items)),
            distinctUntilChanged(),
        );
        // return this.fireStore
        //     .collection<Meeting>(this.collection_key, ref => ref.orderBy('id', 'desc'))
        //     .snapshotChanges()
        //     .pipe(
        //         debounceTime(Constants.TIMEOUT_API_BLINK),
        //         map(items => items
        //             .map(item => Meeting.init(item.payload.doc.data()))
        //             .filter(item => item.show && this.dateService.validDate(item.release_time, item.expired_time)),
        //         ),
        //         tap(items => this.dataService.setData(this.collection_key, items)),
        //         distinctUntilChanged(),
        //     );
    }

    getMeeting$() {
        return this.meetings;
    }

}
