export class Category {
    id    : number;
    count : number;
    name  : string;
    slug  : string;
    link  : string;

    constructor(id?: number, count?: number, name?: string, slug?: string, link?: string) {
        this.id    = id;
        this.count = count;
        this.name  = name;
        this.slug  = slug;
        this.link  = link;
    }
}
