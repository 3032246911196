import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import * as Constants from '../../../../configs/constants';
import {DateService} from '../../../../core/services/dateService';

@Component({
    selector: 'app-post-list',
    templateUrl: './post-list.component.html',
    styleUrls: ['./post-list.component.scss'],
})
export class PostListComponent {

    /* ==================================================================================================== */
    /* Model */

    @Input('items') _items: any;
    @Input() _size : number;
    @Input()
    set size(value: number) {
        this._size = (value !== undefined && value !== null) ? value : 16;
    }
    @Output('detail') detailClicked = new EventEmitter();

    constructor() {
    }

    /* ==================================================================================================== */
    /* View */

    trackById(index, item) {
        return item.id;
    }

    getLastUpdateTime(date: string) {
        return DateService.getLastUpdateTime(date, Constants.HUMAN_TIME_FORMAT_TC, '更新時間：');
    }

    fetchContent(index: number) {
        this.detailClicked.emit(index);
    }


}
