import * as Constants from '../configs/constants';
import * as moment from 'moment';

export class Festival {
    title      : string;
    remark     : string;
    bg_color   : string;
    color      : string;
    url        : string;
    start_time : string;
    end_time   : string;
    show       : boolean;

    constructor(title?: string, remark?: string, bg_color?: string, color?: string, url?: string, start_time?: string, end_time?: string, show?: boolean) {
        this.title      = title;
        this.remark     = remark;
        this.bg_color   = bg_color;
        this.color      = color;
        this.url        = url;
        this.start_time = start_time;
        this.end_time   = end_time;
        this.show       = show;
    }

    static init(value: any) {
        let instance = new Festival();
        instance.title      = value.title;
        instance.remark     = value.remark || '';
        instance.bg_color   = value.bg_color || '';
        instance.color      = value.color || '';
        instance.url        = value.url || '';
        instance.start_time = instance.formatDate(value.start_time);
        instance.end_time   = instance.formatDate(value.end_time);
        instance.show       = value.show || false;
        return instance;
    }

    formatDate(date:string, format: string = Constants.DATE_FORMAT_SHORT) {
        return moment(date).format(format);
    }
}
