import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {RouterModule, Routes} from '@angular/router';

import {VideoPopoverPage} from './video-popover';

const routes: Routes = [
    {
        path: '',
        component: VideoPopoverPage,
    }
];

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        VideoPopoverPage,
    ],
})
export class VideoPopoverPageModule {
}
