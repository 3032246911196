import {
    Directive,
    ElementRef,
    Input,
    OnInit,
    Renderer2
} from '@angular/core';
import {DateService} from '../../core/services/dateService';

@Directive({
    selector: '[visibilityControl]'
})
export class VisibilityControlDirective implements OnInit {

    @Input('lowerBound') lowerBound : string;
    @Input('upperBound') upperBound : string;
    @Input('selected') selected : string;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private dateService: DateService,
    ) {
    }

    ngOnInit() : void {
        let visible : boolean;

        this.lowerBound = this.lowerBound || this.dateService.formatDate('2001-01-01');
        this.upperBound = this.upperBound || this.dateService.formatDate('2099-12-31');
        this.selected = this.selected || this.dateService.getTodayDate();

        // console.log('within lower bound %s : ', this.lowerBound, this.dateService.isAfter(this.lowerBound));
        // console.log('within upper bound %s : ', this.upperBound, this.dateService.isBefore(this.upperBound));
        if (this.lowerBound !== undefined && this.dateService.isAfter(this.lowerBound, 'day', this.selected)) {
            visible = true;
        } else {visible = false;}
        // console.log('visible: ', visible, this.lowerBound !== undefined && this.dateService.isAfter(this.lowerBound));
        if (this.upperBound !== undefined && this.dateService.isBefore(this.upperBound, 'day', this.selected)) {
            visible = true;
        } else {visible = false;}
        // console.log('visible: ', visible, this.upperBound !== undefined && this.dateService.isBefore(this.upperBound));
        if (this.lowerBound !== undefined && this.upperBound !== undefined && this.dateService.inRange(this.lowerBound, this.upperBound, this.selected)) {
            visible = true;
        } else {visible = false;}
        // console.log('visible: ', visible, this.lowerBound !== undefined && this.upperBound !== undefined && this.dateService.inRange(this.lowerBound, this.upperBound));
        if (visible) {
        } else {
            this.elementRef.nativeElement.remove();
        }
    }

}
