<ion-grid>
    <ng-container *ngFor="let res of _resources">
        <ion-row *ngFor="let row of res | groupBy:'key' | pairs; index as rowIndex; trackBy: trackById;">
            <ion-item-divider>
                <ion-label class="header-label" [innerHTML]="findHeader(row[0])"></ion-label>
                <hr>
            </ion-item-divider>

            <ng-container *ngIf="row[1] | isList">
                <ion-list>
                    <video-item [items]="row[1]"
                                (link)="clickLink($event)">
                    </video-item>
                </ion-list>
            </ng-container>
            <ng-container *ngIf="row[1] | isItem">
                <ion-col [size]="12" *ngFor="let item of row[1] | orderBy: '-order'; trackBy: trackById;" (click)="clickLink(item.url)">
                    <ion-item roundedCorner class="basic others">
                        <img roundedCorner class="thumbnail" [src]="item['thumbnail']" alt="">
                        <div class="block">
                            <div class="item-title" [innerHTML]="item['title']"></div>
                            <div class="subtitle" [innerHTML]="item['subtitle']"></div>
                            <div class="excerpt" [innerHTML]="item['excerpt']"></div>
                            <BR>
                            <ion-row class="action-row" *ngIf="item['buttons']">
                                <ion-col *ngFor="let button of item['buttons']" (click)="clickLink(button['link'])"
                                            [ngSwitch]="checkLinkType(button['link'])">
                                    <img class="action-icon" [src]="'assets/icons/custom/youtube.svg'" [alt]="" *ngSwitchCase="'video'">
                                    <img class="action-icon" [src]="'assets/icons/custom/soundcloud.svg'" [alt]="" *ngSwitchCase="'audio'">
                                    <img class="action-icon" [src]="'assets/icons/custom/document.svg'" [alt]="" *ngSwitchCase="'document'">
                                    <img class="action-icon" [src]="'assets/icons/custom/link.svg'" [alt]="" *ngSwitchCase="'link'">
                                    <div [innerHTML]="button['name']" *ngSwitchDefault></div>
                                </ion-col>
                            </ion-row>
                        </div>
                    </ion-item>
                </ion-col>
            </ng-container>
            <ng-container *ngIf="row[1] | isGrid">
                <ion-slides class="slides-grid" [options]="setupSlideOption(row[0])">
                    <ion-slide class="grid" *ngFor="let item of row[1] | filterBy: ['type']: 'grid' | orderBy: '-order' ; trackBy: trackById;"
                               roundedCorner>
                        <ion-item class="grid" *ngIf="item['thumbnail']">
                            <img roundedCorner class="thumbnail" [src]="item['thumbnail']" alt="" (click)="clickLink(item.url)">
                        </ion-item>
                        <div class="item-title" [innerHTML]="item['title']" (click)="clickLink(item.url)"></div>
                        <ion-row class="action-row" *ngIf="item['buttons']">
                            <ion-col *ngFor="let button of item['buttons']" (click)="clickLink(button['link'])"
                                     [ngSwitch]="checkLinkType(button['link'])">
                                <img class="action-icon" [src]="'assets/icons/custom/youtube.svg'" [alt]="" *ngSwitchCase="'video'">
                                <img class="action-icon" [src]="'assets/icons/custom/soundcloud.svg'" [alt]="" *ngSwitchCase="'audio'">
                                <img class="action-icon" [src]="'assets/icons/custom/document.svg'" [alt]="" *ngSwitchCase="'document'">
                                <img class="action-icon" [src]="'assets/icons/custom/link.svg'" [alt]="" *ngSwitchCase="'link'">
                                <div [innerHTML]="button['name']" *ngSwitchDefault></div>
                            </ion-col>
                        </ion-row>
                    </ion-slide>
                </ion-slides>
            </ng-container>
        </ion-row>
    </ng-container>

</ion-grid>
