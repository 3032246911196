import {Directive, ElementRef, EventEmitter, Output, Renderer2} from '@angular/core';

@Directive({
    selector: '[passDetail]'
})
export class DetailDirective {

    @Output() openDetail = new EventEmitter<string>();

    constructor(
    ) {
    }

    itemClicked(item) {
        this.openDetail.emit(item);
        console.log('item:', item);
    }

}
