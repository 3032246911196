import {
    AfterContentInit,
    AfterViewInit,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output
} from '@angular/core';

import * as Constants from '../../../../configs/constants';
import {DateService} from '../../../../core/services/dateService';
import {FestivalService} from '../../../../providers/apiServices/festivalService';

@Component({
    selector: 'app-resource-grid',
    templateUrl: './resource-grid.component.html',
    styleUrls: ['./resource-grid.component.scss'],
})
export class ResourceGridComponent implements AfterViewInit,AfterContentInit {

    // @ViewChildren(IonSlides) slides: QueryList<IonSlides>;
    @HostBinding('class.first-slide-active') isFirstSlide = true;
    @HostBinding('class.last-slide-active') isLastSlide = false;

    @Input('filterKey')   _filterKey : string;
    @Input('categories') _categories : any;
    @Input('resources')  _resources  : any;

    @Output('link') openLink = new EventEmitter();

    gridSlideOptions : any;

    constructor(
        public dataService : DateService,
        public festivalService : FestivalService,
    ) {
        this.gridSlideOptions = {
            initialSlide: 0,
            spaceBetween: 15,
            slidesPerView: 2.5,
            // loop: true,
            zoom: false,
            speed: 400,
            // centeredSlides: true,
            autoplay: false,
            lazy: {
                loadPrevNext: true,
            },
            preloadImages: true,
            updateOnImagesReady: true,
        };
    }

    /* ==================================================================================================== */

    /* Manipulate Views */

    ngAfterViewInit(): void {
        // console.log('view num: ', this._viewNum);
    }
    ngAfterContentInit(): void {
    }

    trackById(index, item) {
        return item.id;
    }

    findCategory(key: string) {
        if (this._categories !== undefined && this._categories.length > 0) {
            return this._categories.find(item => item.key === key);
        }
        return Constants.GROWTH.categories[0];
    }

    findHeader(key: string) {
        let header = '最新項目';
        let target = this.findCategory(key);
        if (target) {
            header = target.value;
        }
        return header;
    }

    checkLinkType(link: string) {
        if (link !== undefined && link.length > 0) {
            if (link.includes('https://youtu.be/')) return 'video';
            if (link.includes('https://soundcloud.com/')) return 'audio';
            if (link.includes('https://bit.ly/')) return 'document';
            if (link.includes('https://www.wkphc.org/')) return 'link';
        }
        return null;
    }

    // getSwiperOption(option: string, key?: string) {
    //     let currentCategory = this._categories.find(item => item.key === key);
    //     // view size
    //     let newOptions = {
    //         slidesPerView: (currentCategory !== undefined) ? currentCategory.size : 2.5,
    //     }
    //     let options = {...this.gridSlideOptions, ...newOptions}
    //     // if (option === 'slidesPerView') {
    //     //     console.log('options: ', options);
    //     // }
    //     return this.gridSlideOptions[option];
    // }

    setupSlideOption(key: string) {
        let currentCategory = this._categories.find(item => item.key === key);
        let options = {
            slidesPerView: (currentCategory !== undefined) ? currentCategory.size : 2.5,
        };
        return {...this.gridSlideOptions, ...options};
    }

    /* ==================================================================================================== */

    /* Operations */

    clickLink(link: string) {
        this.openLink.emit(link);
    }

}
