import {Component, Input, TemplateRef} from '@angular/core';

@Component({
    selector: 'template-content',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss'],
})
export class TemplateComponent {

    @Input() template: TemplateRef<any>;

    constructor() {
    }

}
