import * as moment from 'moment';

export class Post {
    id             : number;
    categories     : number[];
    slug           : string;
    title          : string;
    content        : string;
    excerpt        : string;
    featured_media : number;
    link           : string;
    date           : string;
    modified_date  : string;
    status         : string;

    constructor(id?: number, categories?: number[], slug?: string, title?: string, content?: string, excerpt?: string, featured_media?: number, link?: string, date?: string, modified_date?: string, status?: string) {
        this.id             = id;
        this.categories     = categories;
        this.slug           = decodeURI(slug);
        this.title          = title;
        this.content        = content;
        this.excerpt        = excerpt;
        this.featured_media = featured_media;
        this.link           = link;
        this.date           = date;
        this.modified_date  = modified_date;
        this.status         = status;
    }

    formatDate(date:string, format: string) {
        return moment(date).format(format);
    }
}
