export class Level {
    id       : number  ;
    category : string  ;
    title    : string  ;
    color    : string  ;
    icon     : string  ;
    show     : boolean ;

    constructor(id: number, category: string, title: string, color: string, icon: string, show: boolean) {
        this.id       = id       ;
        this.category = category ;
        this.title    = title    ;
        this.color    = color    ;
        this.icon     = icon     ;
        this.show     = show     ;
    }

    static init(value: any) {
        return new Level(
            (value.id > 0) ? value.id : (value.id === 0) ? 0 : -1,
            value.category || '',
            value.title || '',
            value.color || '',
            value.icon || '',
            value.show || false,
        );
    }
}
