import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '../../../providers/pipesModule';
import {RouterModule, Routes} from '@angular/router';

import {VideoModalPage} from './video-modal';

const routes: Routes = [
    {
        path: '',
        component: VideoModalPage,
    }
];

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        PipesModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        VideoModalPage,
    ],
})
export class VideoModalPageModule {
}
