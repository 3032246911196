import {
    AfterContentInit,
    AfterViewInit,
    Component, EventEmitter,
    HostBinding,
    Input, Output,
    ViewChild
} from '@angular/core';
import {IonSlides} from '@ionic/angular';

@Component({
    selector: 'app-slides',
    templateUrl: './slides.component.html',
    styleUrls: ['./slides.component.scss'],
})
export class SlidesComponent implements AfterViewInit,AfterContentInit {

    @ViewChild('slides', {static: true}) slider: IonSlides;
    @HostBinding('class.first-slide-active') isFirstSlide = true;
    @HostBinding('class.last-slide-active') isLastSlide = false;
    sliderOptions  : any;
    defaultOptions : any;
    multiple       : boolean;

    @Input('items') _items: any;
    @Input()
    set options(options) {
        this.sliderOptions = {...this.defaultOptions, ...options};
    }
    @Output() openLink = new EventEmitter<string>();

    constructor() {
        this.defaultOptions = {
            initialSlide: 0,
            spaceBetween: 10,
            slidesPerView: 1,
            loop: true,
            zoom: false,
            centeredSlides: true,
            autoplay: {
                delay: 10000,
                disableOnInteraction: false,
            },
            lazy: {
                loadPrevNext: true,
            },
            preloadImages: true,
            updateOnImagesReady: true,
        };
        this.multiple = false;
    }

    /* ==================================================================================================== */

    /* Manipulate Views */

    trackById(index, item) {
        return item.id; // unique id corresponding to the item
    }

    ngAfterViewInit(): void {
        // ViewChild is set
        this.slider.isBeginning().then(isBeginning => {
            this.isFirstSlide = isBeginning;
        });
        this.slider.isEnd().then(isEnd => {
            this.isLastSlide = isEnd;
        });

        // Subscribe to changes
        this.slider.ionSlideWillChange.subscribe(changes => {
            this.slider.isBeginning().then(isBeginning => {
                this.isFirstSlide = isBeginning;
            });
            this.slider.isEnd().then(isEnd => {
                this.isLastSlide = isEnd;
            });
            this.slider.getActiveIndex().then(index => {
                // console.log('index: ', index);
                // console.log('isLastSlide: ', this.isLastSlide);
            });
        });
    }

    slideDidLoaded(slides: any, slider: IonSlides) {
        // this.slider.update().then(() => {
        //     console.log('slider update.');
        //     this.multiple = (this._items !== undefined && this._items.length > 1);
        // });
    }

    slideDidChanged(slides: any, slider: IonSlides) {
        this.slider.getActiveIndex().then(async (activeIndex: number) => {
            const slideCount = await this.slider.length();
            this.setupSlideOption();
            if (activeIndex === slideCount - 1) {
                this.slider.slideTo(1, 0).then(() => {});
            }
            if (activeIndex === 0) {
                this.slider.slideTo(slideCount - 2, 0).then(() => {});
            }
        });
        slider.getSwiper().then(swiper => {
            // console.log('real index: ', swiper.realIndex);
            if (swiper.realIndex === 0) {
            }
        });
    }

    ngAfterContentInit(): void {
        this.multiple = (this._items !== undefined && this._items.length > 1);
    }

    setupSlideOption() {
        this.multiple = (this._items !== undefined && this._items.length > 1);
        let options = {};
        if (!this.multiple) {
            options = {
                loop: false,
            }
        }
        return {...this.defaultOptions, ...options};
    }

    checkInstance(item) {
        return item.title;
    }

    /* ==================================================================================================== */

    /* Operations */

    clickLink(link: string) {
        this.openLink.emit(link);
    }

}
