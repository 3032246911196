<ion-card *ngFor="let item of _items" class="meeting-item">
<!--    <img-loader *ngIf="item['thumbnail']" [src]="item['thumbnail']" (click)="clickLink(item['url'])"></img-loader>-->
    <img [src]="item['thumbnail']" *ngIf="item['thumbnail']" (click)="clickLink(item['url'])">
    <ion-card-content [style.font-size.px]="_size">
        <ion-row>
            <ion-col class="title-col" (click)="clickLink(item['url'])">
                <div (click)="clickLink(item['url'])">
                    <ion-card-title [style.font-size.px]="_size+2" [innerHTML]="item['title']"></ion-card-title>
                </div>
            </ion-col>
        </ion-row>

        <ion-row class="action-row">
            <ion-col *ngFor="let button of item['buttons']" (click)="clickLink(button['link'])">
                <div [innerHTML]="button['name']"></div>
            </ion-col>
        </ion-row>
    </ion-card-content>
</ion-card>


