import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Network} from '@awesome-cordova-plugins/network/ngx';
import {fromEvent, merge, Observable, of, Subscription} from 'rxjs';
import {mapTo} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NetworkService {

    private networkStatus          : Observable<boolean> = undefined;
    private connectSubscription    : Subscription;
    private disconnectSubscription : Subscription;

    constructor(
        public network: Network,
        public platform: Platform
    ) {
        this.networkStatus = Observable.create(observer => {
            observer.next(true);
        }).pipe(mapTo(true));

        if (this.platform.is('cordova')) {
            // on Device
            this.networkStatus = merge(
                this.network.onConnect().pipe(mapTo(true)),
                this.network.onDisconnect().pipe(mapTo(false))
            );
        } else {
            // on Browser
            this.networkStatus = merge(
                of(navigator.onLine),
                fromEvent(window, 'online').pipe(mapTo(true)),
                fromEvent(window, 'offline').pipe(mapTo(false))
            );
        }
    }

    /* ==================================================================================================== */
    /* Status */

    getNetworkType(): string {
        return this.network.type;
    }

    getNetworkStatus(): Observable<boolean> {
        return this.networkStatus;
    }

    /* ==================================================================================================== */
    /* Operations */

    subscribeConnection() {
        // watch network for a connection
        this.connectSubscription = this.network.onConnect().subscribe(() => {
            alert('network connected!');
            setTimeout(() => {
                alert('type:' + this.network.type);
            }, 3000);
        });
    }

    unsubscribeConnection() {
        // stop connect watch
        this.connectSubscription.unsubscribe();
    }

    subscribeDisconnection() {
        // watch network for a connection
        this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
            alert('network was disconnected :-(');
        });
    }

    unsubscribeDisconnection() {
        // stop connect watch
        this.disconnectSubscription.unsubscribe();
    }

}
