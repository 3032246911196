import {Pipe, PipeTransform} from '@angular/core';
import {Banner} from '../../models/banner.model';

@Pipe({
    name: 'isBanner'
})
export class IsBannerPipe implements PipeTransform {

    transform(value: any): any {
        // console.log("Pipe works ", value instanceof Banner);
        return value instanceof Banner;
    }

}
