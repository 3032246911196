<img *ngIf="_item['thumbnail']" [src]="_item['thumbnail']" (click)="clickLink(_item['link'])">
<ion-card-content [style.font-size.px]="_size"
                  [ngStyle]="formatCard()">
    <ion-row>
        <ion-col class="title-col" (click)="clickLink(_item['link'])">
            <div>
                <ion-card-title [style.font-size.px]="_size+2" [innerHTML]="_item['title']"></ion-card-title>
            </div>
        </ion-col>
    </ion-row>

    <ion-row class="action-row">
        <ion-col *ngFor="let item of _item['action_buttons']" (click)="clickLink(item.link)">{{item.name}}</ion-col>
    </ion-row>
</ion-card-content>
