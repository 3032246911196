import { Injectable } from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore } from '@angular/fire/firestore';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {Poster} from '../../models/poster.model';
import {Observable, from} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import { CapacitorHttp } from '@capacitor/core';

const httpOptions = {
    headers: new HttpHeaders({
    })
};

@Injectable({
  providedIn: 'root'
})
export class PosterService extends BaseService {

    constructor(
        // protected http        : HTTP,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
    }

    getPosterObservable(amount: number = 30): Observable<Poster[]> {
        let url = `${Constants.API_MAIN}/posts?${this.wpEncode(165, amount, 'modified')}`;
        const options = { url: url};
        return from(CapacitorHttp.get(options)).pipe(
        // return this.httpClient.get<Poster[]>(url, httpOptions).pipe(
            map(response => {
                const items:Poster[] = response.data;
                return items.map(item => Poster.init(item))
            }),
            // tap(items => this.logWPResult<Poster>(url, items)),
            catchError(this.handleError)
        );
    }

}
