import {Injectable} from '@angular/core';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore, doc, setDoc, arrayUnion } from '@angular/fire/firestore';

import {Device} from '@awesome-cordova-plugins/device/ngx';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
// import {Observable, throwError} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import * as Constants from '../configs/constants';
// import firebase from 'firebase/compat/app';
import {environment} from '../../environments/environment.prod';
import {DateService} from '../core/services/dateService';
import {DataService} from '../core/services/dataService';
// import { GlobalVars } from './globalvars';

/* ==================================================================================================== */
/* Models */
import {LentArchive} from '../models/lent-archive.model';
import {ServerLog} from '../models/server-log.model';
import {LentCard} from '../models/lent-card.model';
import {classToPlain} from 'class-transformer';

@Injectable({
    providedIn: 'root',
})
export class LogService {

    deviceID   : string ;
    appVersion : string ;

    constructor(
        private device: Device,
//        private http: HTTP,
        private httpClient: HttpClient,
//        private globalVars: GlobalVars,
//        private fireStore: AngularFirestore,
        private fireStore: Firestore,
        public dateService: DateService,
        public dataService: DataService,
    ) {
        this.deviceID   = this.device.uuid || 'WKPHC';
        this.appVersion = '2.0.63';

    }

    getLogInfo(type: string) {
        switch (type) {
            case Constants.LOG.system.DEVICE_MODEL : return this.device.model || null;
            case Constants.LOG.system.OS     : return (this.device.platform) ? `${this.device.platform} ${this.device.version}` : null;
            case Constants.LOG.system.APP    : return this.appVersion;
        }
    }

    /* ==================================================================================================== */

    /* Profile */

    pushProfileLog(args: any, status: string) {
        let target = {
            id             : args.id,
            username       : args.username,
            device         : args.device,
            fullname       : args.fullname,
            email          : args.email,
            phone          : args.phone,
            offering_code  : args.offering_code,
            family         : args.family,
            unit           : args.unit,
            identity       : args.identity,
            balance        : args.balance,
            last_login     : args.last_login,
            last_update    : args.last_update,
            device_version : args.device_version,
            os_version     : args.os_version,
            app_version    : args.app_version,
            status         : status,
        };
        let docRef = doc(this.fireStore, Constants.COLLECTION_PROFILE_LOGS + "/" + target.username);
        setDoc(docRef, {[target.username]: arrayUnion(target)}, {merge:true});
        // this.fireStore
        //     .collection(Constants.COLLECTION_PROFILE_LOGS)
        //     .doc(target.username)
        //     .set({
        //         [target.username]: firebase.firestore.FieldValue.arrayUnion(target)
        //     }, {merge: true})
        //     .then(() => {});
    }

    /* ==================================================================================================== */

    /* Redeem Ticket */

    pushRedeemLog(args: any) {
        let target = {
            username   : args.username,
            cardnumber : args.cardnumber,
            timestamp  : args.timestamp,
            status     : args.status,
            message    : args.header,
        };
        let docRef = doc(this.fireStore, Constants.COLLECTION_REDEEM_LOGS + "/" + target.username);
        setDoc(docRef, {[target.username]: arrayUnion(target)}, {merge:true});

        // this.fireStore
        //     .collection(Constants.COLLECTION_REDEEM_LOGS)
        //     .doc(target.username)
        //     .set({
        //         [target.username]: firebase.firestore.FieldValue.arrayUnion(target)
        //     }, {merge: true})
        //     .then(() => {});
    }

    /* ==================================================================================================== */

    /* LENT  */

    logArchive(args: any, type: string) {
        let target = classToPlain(args.card);
        if (target.setting.mode === Constants.TYPE.record.D) {
            target.total = target.setting.maximum - target.total;
        }
        target.total = Number(Number(target.total).toFixed(1));
        target.last_update = this.dateService.getCurrentTime();
        target.log_status  = type;

        let docRef = doc(this.fireStore, Constants.COLLECTION_LENT_CURRENT.LOGS + "/" + args.key);
        setDoc(docRef, {[target.username]: arrayUnion(target)}, {merge:true});
        
        // this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.LOGS)
        //     .doc(args.key)
        //     .set(target, {merge: true});
    }

    /* ==================================================================================================== */

    /* API  */

    collectAPIStatus(args: any) {
        let logEntry = ServerLog.init({
            log_key: `${args.apiType}-${args.logType}`,
            log_type: args.logType,
            api_type: args.apiType,
            status: args.status,
            timestamp: args.timestamp,
            url: args.url,
            device_id: this.deviceID,
            device_model: this.getLogInfo(Constants.LOG.system.DEVICE_MODEL),
            os_version: this.getLogInfo(Constants.LOG.system.OS),
            app_version: this.getLogInfo(Constants.LOG.system.APP),
        });

        // save to storage
        this.dataService.serverLog$
            .pipe(take(1))
            .subscribe(values => values.push(logEntry));
    }

    pushAPILogs() {
        this.dataService.serverLog$
            .pipe(
                take(1),
            )
            .subscribe(items => {
                if (items.length === 0) return;
                items.forEach(item => {
                    let target = ServerLog.init(item);
                    let docRef = doc(this.fireStore, Constants.COLLECTION_SERVER_LOGS + "/" + target.log_key);
                    // Send to firestore, v9 modular syntax
                    setDoc(docRef, {[target.getLogDate()]: arrayUnion(target.toLog())}, {merge:true})
                    .then(()=>items.splice(items.indexOf(target), 1));

                    // this.fireStore
                    //     .collection(Constants.COLLECTION_SERVER_LOGS)
                    //     .doc(target.log_key)
                    //     .set({
                    //         [target.getLogDate()]: firebase.firestore.FieldValue.arrayUnion(target.toLog())
                    //     }, {merge: true})
                    //     .then(() => items.splice(items.indexOf(target), 1));
                });
            });
    }

}
