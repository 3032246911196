import {
    AfterContentInit,
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {

    /* ==================================================================================================== */
    /* Banner */

    @Input('item') _item : any;
    @Output() openLink = new EventEmitter<string>();

    constructor() {
    }

    /* ==================================================================================================== */

    /* Manipulate Views */

    formatBackground(bg: string, gradient: boolean = false): any {
        if (bg.includes('#')) {
            // background color
            return {'background': bg};
        }
        if (gradient) {
            // background image with gradient
            return {'background': `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${bg}) no-repeat center center / cover`};
        } else {
            // background image with full opacity
            return {'background': `url(${bg}) no-repeat center center / cover`};
        }
    }

    /* ==================================================================================================== */

    /* Operations */

    clickLink(link: string) {
        this.openLink.emit(link);
    }

}
