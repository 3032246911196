import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ShellModule} from '../shell/shell.module';
import {PipesModule} from './pipesModule';
import {NgPipesModule} from 'ngx-pipes';
// import {IonicImageLoaderModule} from 'ionic-image-loader-v5';

import {AspectRatioComponent} from '../components/aspect-ratio/aspect-ratio.component';
import {PageItemComponent} from '../components/page-item/page-item.component';
import {AlertItemComponent} from '../components/alert-item/alert-item.component';
import {MeetingItemComponent} from '../components/meeting-item/meeting-item.component';
import {NotificationItemComponent} from '../components/notification-item/notification-item.component';
import {BlockGridComponent} from '../components/block-grid/block-grid.component';
import {ImageGridComponent} from '../components/image-grid/image-grid.component';
import {CheckboxWrapperComponent} from '../components/checkbox-wrapper/checkbox-wrapper.component';
import {RefresherComponent} from '../components/refresher/refresher.component';
import {TemplateComponent} from '../components/template/template.component';
import {CardSliderComponent} from '../components/card-slider/card-slider.component';
import {LentItemComponent} from '../components/lent-item/lent-item.component';


@NgModule({
    declarations: [
        AspectRatioComponent,
        PageItemComponent,
        AlertItemComponent,
        MeetingItemComponent,
        NotificationItemComponent,
        BlockGridComponent,
        ImageGridComponent,
        CheckboxWrapperComponent,
        RefresherComponent,
        TemplateComponent,
        CardSliderComponent,
        LentItemComponent,
        //other components
    ],
    imports: [
        CommonModule,
        IonicModule,
        ShellModule,
        PipesModule,
        NgPipesModule,
//         IonicImageLoaderModule,
    ],
    exports: [
        ShellModule,
        AspectRatioComponent,
        PageItemComponent,
        AlertItemComponent,
        MeetingItemComponent,
        NotificationItemComponent,
        BlockGridComponent,
        ImageGridComponent,
        CheckboxWrapperComponent,
        RefresherComponent,
        TemplateComponent,
        CardSliderComponent,
        LentItemComponent,
        //other components
    ],
})
export class ComponentsModule {}
