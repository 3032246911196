import {NgModule} from '@angular/core';
import {SafePipe} from './safePipe';

@NgModule({
    declarations: [
        SafePipe,
        //other pipes
    ],
    imports: [
    ],
    exports: [
        SafePipe,
        //other pipes
    ],
})
export class PipesModule { }
