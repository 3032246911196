import {Pipe, PipeTransform} from '@angular/core';
import {EasterVideo} from '../models/easter-video.model';

@Pipe({
    name: 'isVideo'
})
export class IsVideoPipe implements PipeTransform {

    transform(value: any): any {
        // console.log("Pipe works ", value instanceof EasterVideo);
        return value instanceof EasterVideo;
    }

}
