import {User} from './user.model';
import {Log} from './log.model';
import {LentAmbition} from './lent-ambition.model';
import {LentEntry} from './lent-entry.model';
import {classToPlain, Type} from 'class-transformer';

export class CardSetting {
    mode: string;
    maximum: number;

    constructor(mode?: string, maximum?: number) {
        this.mode    = mode;
        this.maximum = maximum;
    }

    static init(value: any) {
        let instance = new CardSetting();
        if (value === null) return instance;
        instance.mode    = value.mode ?? 'increasing';
        instance.maximum = Number(value.maximum) ?? 0.0;
        return instance;
    }

    toObject() {
        return {
            mode: this.mode,
            maximum: Number(this.maximum),
        };
    }
}

export class LentCard {
    owner       : User          ;
    setting     : CardSetting   ;
    log         : Log           ;
    ambition    : LentAmbition  ;
    group_name  : string        ;
    total       : number        ;
    submitted   : boolean       ;
    archive_id  : string        ;
    last_update : string        ;
    entries     : LentEntry[][] ;

    constructor(owner?: User, setting?: CardSetting, log?: Log, ambition?: LentAmbition, group_name?: string, total?: number, submitted?: boolean, archive_id?: string, last_update?: string) {
        this.owner       = owner       || User.init({id: -1});
        this.setting     = setting     || CardSetting.init(null);
        this.log         = log         || Log.init(null);
        this.ambition    = ambition    || LentAmbition.init(null);
        this.group_name  = group_name  || '';
        this.total       = total       || 0;
        this.submitted   = submitted   || false;
        this.archive_id  = archive_id  || '';
        this.last_update = last_update || '';
        this.entries     = [];
    }

    static init(value: any) {
        let instance = new LentCard();
        if (value == null) return instance;
        instance.owner        = value.owner       || User.init({id: -1});
        instance.setting      = value.setting     || CardSetting.init(null);
        instance.log          = value.log         || Log.init(null);
        instance.ambition     = value.ambition    || LentAmbition.init(null);
        instance.group_name   = value.group_name  || '';
        instance.total        = value.total       || 0;
        instance.submitted    = value.submitted   || false;
        instance.archive_id   = value.archive_id  || '';
        instance.last_update  = value.last_update || '';
        instance.entries      = value.entries     || [];
        return instance;
    }

    toObject() {
        // push to firestore
        return classToPlain({
            owner       : this.owner.toSimpleObject(),
            setting     : this.setting.toObject(),
            log         : this.log.toObject(),
            group_name  : this.group_name    ,
            total       : Number(this.total) ,
            submitted   : this.submitted     ,
            archive_id  : this.archive_id    ,
            last_update : this.last_update   ,
        });
    }

    toArchive() {
        return {
            owner       : this.owner.toSimpleObject(),
            setting     : this.setting.toObject(),
            log         : this.log.toObject(),
            ambition    : this.ambition.toObject(),
            group_name  : this.group_name    ,
            total       : Number(this.total) ,
            submitted   : this.submitted     ,
            archive_id  : this.archive_id    ,
            last_update : this.last_update   ,
        };
    }

    toRecord() {
        return {
            owner       : this.owner.toSimpleObject(),
            setting     : this.setting.toObject(),
            log         : this.log.toObject(),
            ambition    : this.ambition.toObject(),
            group_name  : this.group_name    ,
            total       : Number(this.total) ,
            submitted   : this.submitted     ,
            archive_id  : this.archive_id    ,
            last_update : this.last_update   ,
        };
    }

    toLog() {
        return {
            // parent_id      : this.parent_id              ,
            // archive_id     : this.archive_id             ,
            // device_id      : this.device_id              ,
            // user_id        : this.owner['username']      ,
            // username       : this.owner['fullname']      ,
            // email          : this.owner['email']         ,
            // phone          : this.owner['phone']         ,
            // family         : this.owner['family']        ,
            // unit           : this.owner['unit']          ,
            // offering_code  : this.owner['offering_code'] ,
            // total          : Number(this.total)          ,
            // last_update    : this.last_update            ,
            // paid           : this.paid                   ,
            // device_model   : this.device_model           ,
            // os_version     : this.os_version             ,
            // app_version    : this.app_version            ,
            // record_setting : this.record_setting         ,
            // log_mode       : null                        ,
        };
    }

}
