import * as Constants from '../configs/constants';
import * as moment from 'moment';

export class Announcement {
    id:      number;
    date:    string;
    link:    string;
    title:   string;
    content: string;
    excerpt: string;

    constructor(id?: number, date?: string, link?: string, title?: string, content?: string, excerpt?: string) {
        this.id      = id      || -1;
        this.date    = date    || '';
        this.link    = link    || '';
        this.title   = title   || '';
        this.content = content || '';
        this.excerpt = excerpt || '';
    }

    static init(value: any) {
        let instance = new Announcement();
        instance.id      = value.id;
        instance.date    = instance.formatDate(value.date);
        instance.link    = value.link;
        instance.title   = value.title.rendered;
        instance.content = value.content.rendered;
        instance.excerpt = value.excerpt.rendered;
        return instance;
    }

    formatDate(date:string, format: string = Constants.TIMESTAMP_FORMAT_EN) {
        return moment(date).format(format);
    }
}
