import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'isGrid'
})
export class IsGridPipe implements PipeTransform {

    transform(items: any): any {
        return items.some(item => item.type.includes('grid'));
    }

}
