import { Injectable } from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { CapacitorHttp } from '@capacitor/core';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore } from '@angular/fire/firestore';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {Announcement} from '../../models/announcement.model';
import {Observable, from} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Poster} from '../../models/poster.model';

const httpOptions = {
    headers: new HttpHeaders({
    })
};

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService extends BaseService {

    constructor(
        // protected http        : HTTP,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
    }

    getAnnouncementObservable(): Observable<Announcement[]> {
        let url = `${Constants.API_MAIN}/posts?${this.wpEncode(7,4)}`;
        const options = { url: url};
        return from(CapacitorHttp.get(options)).pipe(
        // return this.httpClient.get<Announcement[]>(url, httpOptions).pipe(
            map(response => {
                const items:Announcement[] = response.data;
                return items.map(item => Announcement.init(item));
            }),
            // tap(items => this.logWPResult<Announcement>(url, items)),
            catchError(this.handleError)
        );
    }

}
