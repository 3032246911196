import * as Constants from '../configs/constants';
import * as moment from 'moment';

export class Verse {
    id           : number;
    content      : string;
    verse        : string;
    date         : string;
    release_time : string;
    expired_time : string;

    constructor(id?: number, content?: string, verse?: string, date?: string, release_time?: string, expired_time?: string) {
        this.id           = id           || -1;
        this.content      = content      || '';
        this.verse        = verse        || '';
        this.date         = date         || '';
        this.release_time = release_time || '';
        this.expired_time = expired_time || '';
    }

    static init(value: any) {
        let instance = new Verse();
        if (value == null) return instance;
        instance.id           = value.id;
        instance.content      = value.content;
        instance.verse        = value.verse;
        instance.date         = value.date;
        instance.release_time = instance.formatDate(value.release_time);
        instance.expired_time = instance.formatDate(value.expired_time);
        return instance;
    }

    formatDate(date:string, format: string = Constants.TIMESTAMP_FORMAT_EN) {
        return moment(date).format(format);
    }

}
