export class Grid {
    id    : number;
    order : number;
    show  : boolean;
    title : string;
    icon  : string;
    color : string;
    link  : string;

    constructor(id?: number, order?: number, show?: boolean, title?: string, icon?: string, color?: string, link?: string) {
        this.id    = id;
        this.order = order;
        this.show  = show;
        this.title = title;
        this.icon  = icon;
        this.color = color;
        this.link  = link;
    }

    static init(value: any) {
        let instance = new Grid();
        instance.id    = value.id;
        instance.order = value.order;
        instance.show  = value.show || false;
        instance.title = value.title || '';
        instance.icon  = value.icon || '';
        instance.color = value.color || '';
        instance.link  = value.link || '';
        return instance;
    }
}
