import * as Constants from '../configs/constants';
import * as moment from 'moment';
import {Post} from './post.model';

export class Devotion extends Post {

    constructor(id?: number, categories?: number[], slug?: string, title?: string, content?: string, excerpt?: string, featured_media?: number, link?: string, date?: string, status?: string) {
        super(id, categories, slug, title, content, excerpt, featured_media, link, date, status);
    }

    static init(value: any) {
        let instance = new Devotion();
        if (value == null) return instance;
        instance.id            = value.id;
        instance.date          = instance.formatDate(value.date);
        instance.modified_date = instance.formatDate(value.modified);
        instance.link          = value.link;
        instance.title         = value.title.rendered;
        instance.content       = value.content.rendered;
        instance.excerpt       = value.excerpt.rendered;
        return instance;
    }

    formatDate(date:string, format: string = Constants.TIMESTAMP_FORMAT_EN) {
        return moment(date).format(format);
    }
}
