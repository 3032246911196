import {Injectable} from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {CapacitorHttp} from '@capacitor/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore } from '@angular/fire/firestore';

import {throwError} from 'rxjs';
import * as Constants from '../../configs/constants';
import {DateService} from './dateService';
import {DataService} from './dataService';
import {LogService} from '../../providers/logService';

@Injectable()
export class BaseService {

    protected httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
        })
    };

    constructor(
//        protected http        : CapacitorHttp,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
    }

    protected handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        console.log('error: ', JSON.stringify(error));
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

    protected extractData(res) {
        return res || {};
    }

    protected extractJSON(res, key = 'id') {
        return res[key] || {};
    }

    // /* ================================================== */ //
    /* WP */

    protected encodeParams(obj) {
        let params: URLSearchParams = new URLSearchParams();
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                params.append(key, obj[key]);
            }
            if (obj[key] === -1 || obj[key] === '-1' || obj[key].length === 0) {
                params.delete(key);
            }
        }
        return params;
    }
    protected wpEncode(categories: number, per_page: number = 100, orderby: string = 'date', order: string = 'desc') {
        let params = {
            categories: categories,
            per_page: per_page,
            orderby: orderby,
            order: order,
        };
        return this.encodeParams(params).toString();
    }

    // /* ================================================== */ //
    /* Log */

    protected checkAPIType(url: string) {
        let params = this.dataService.getAllUrlParams(url);
        switch (Number(params['categories'])) {
            case 2: return Constants.LOG.categories.DEVOTION;
            case 118:
            case 29: return Constants.LOG.categories.PRAYER;
            case 165: return Constants.LOG.categories.POSTER;
            case 7: return Constants.LOG.categories.ANNOUNCEMENT;
            case 24: return Constants.LOG.categories.VH;
            case 5: return Constants.LOG.categories.PASTOR;
            default: return Constants.LOG.categories.UNKNOWN;
        }
    }

    protected checkCollection(collection: string) {
        if (collection.toLowerCase().includes('devotion')) {
            return Constants.LOG.categories.DEVOTION;
        }
        else if (collection.toLowerCase().includes('-logs')) {
            return collection.replace('-logs', '');
        }
        else {
            return (collection.endsWith('s')) ? collection.slice(0,-1) : collection;
        }
    }

    protected prepareWPLog<T>(url: string, result: T[]) {
        let status, params;
        status = (result !== undefined && result.length > 0);
        params = {
            apiType: this.checkAPIType(url),
            logType: (status) ? Constants.LOG.type.L : Constants.LOG.type.E,
            status: (status) ? Constants.STATUS.S : Constants.STATUS.F,
            timestamp: this.dateService.getCurrentTime(),
            url: url,
        }

        return params;
    }

    protected prepareFSLog<T>(collection: string, result: T[]) {
        let status, params;
        status = (result !== undefined && result.length > 0);
        params = {
            apiType: this.checkCollection(collection),
            logType: (status) ? Constants.LOG.type.L : Constants.LOG.type.E,
            status: (status) ? Constants.STATUS.S : Constants.STATUS.F,
            timestamp: this.dateService.getCurrentTime(),
            url: collection,
        }
        return params;
    }

    protected logWPResult<T>(url: string, result: T[]) {
        let params = this.prepareWPLog(url, result);
        this.logService.collectAPIStatus(params);
    }

    protected logFSResult<T>(collection: string, result: T[]) {
        let params = this.prepareFSLog(collection, result);
        this.logService.collectAPIStatus(params);
    }

}
