import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {RouterModule, Routes} from '@angular/router';

import {DevotionDatePage} from './devotion-date';

const routes: Routes = [
    {
        path: '',
        component: DevotionDatePage,
    }
];

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        DevotionDatePage,
    ],
})
export class DevotionDateModule {
}
