import {Injectable} from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { CapacitorHttp } from '@capacitor/core';
import { from } from 'rxjs';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore, collection, collectionData } from '@angular/fire/firestore';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {Devotion} from '../../models/devotion.model';
import {DevotionCollection} from '../../models/devotion-collection.model';
import {Observable, of, zip} from 'rxjs';
import {catchError, distinctUntilChanged, map, retry, take, tap} from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({
    })
};

@Injectable({
    providedIn: 'root'
})
export class DevotionService extends BaseService {

    constructor(
//        protected http        : HTTP,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
    }

    fetchDevotion(url: string):Observable<Devotion> {
        const options = { url: url};
        return from(CapacitorHttp.get(options)).pipe(
    //    return this.httpClient.get<Devotion>(url, httpOptions).pipe(
            map(response => {
                return Devotion.init(response.data);
            }),
                // tap(items => this.logWPResult<Devotion>(url, [items])),
            catchError(this.handleError),
            take(1),
            retry(2),
            distinctUntilChanged(),
        );
    }
    fetchDevotions(url: string):Observable<Devotion[]> {
        const options = { url: url};

        const devotions$ : Observable<any> = from(CapacitorHttp.get(options));
        return devotions$.pipe(
        // return this.httpClient.get<Devotion[]>(url, httpOptions).pipe(
            // tap(output => console.log('api output: ', output)),
            map(response => {
                const items:Devotion[] = response.data;
                return items.map(item => Devotion.init(item));
            }),
            // tap(items => this.logWPResult<Devotion>(url, items)),
            catchError(this.handleError),
            take(1),
            retry(2),
            distinctUntilChanged(),
        );
    }

    getDevotionContentByDate(date: string): Observable<Devotion> {
        date = date.replace('+', '%2B');
        let url = `${Constants.API_DEVOTION}/posts?per_page=1&categories=2&orderby=date&order=asc&after=${date}`;
        return this.fetchDevotions(url)
            .pipe(map(items => items[0]));
    }
    getDevotionContentById(id: number): Observable<Devotion> {
        let url = `${Constants.API_DEVOTION}/posts/${id}`;
        return this.fetchDevotion(url);
    }
    getDevotionContentPerDay(): Observable<any> {
        let url = `${Constants.API_DEVOTION}/posts?${this.wpEncode(2, 1)}`;
        return this.fetchDevotion(url);
    }
    getDevotionContentPerWeek(): Observable<Devotion[]> {
        let url = `${Constants.API_DEVOTION}/posts?${this.wpEncode(2, 7)}`;
        return this.fetchDevotions(url);
    }

    // TODO: get 2021 Hymn Devotion content (dev # = 3, prod # = unlimited).
    get2021HymnDevotion() : Observable<Devotion[]> {
        let url = `${Constants.API_DEVOTION}/posts?${this.wpEncode(2)}`;
        return this.fetchDevotions(url)
            .pipe(
                map(output =>
                    output
                        .filter(item =>
                            // filter by tag
                            (item.title.includes('詩歌靈修') || item.content.includes('詩歌靈修')) &&

                            // filter by date
                            (this.dateService.momentDate(item.date).day() === 6 || this.dateService.momentDate(item.modified_date).day() === 6) &&
                            this.dateService.isSame(item.date, 'year')
                        )
                ),
            );
    }

    getDevotionCollection(): Observable<DevotionCollection[]> {
        return collectionData(collection(this.fireStore, Constants.COLLECTION_DEVOTION_COLLECTION)).pipe(
            map(items => items.map(item => DevotionCollection.init(item))),
            // tap(items => this.logFSResult<DevotionCollection>(Constants.COLLECTION_DEVOTION_COLLECTION, items)),
            take(1),
            distinctUntilChanged(),
        );
        // return this.fireStore
        //     .collection<DevotionCollection>(Constants.COLLECTION_DEVOTION_COLLECTION, ref => ref.orderBy('order', 'asc'))
        //     .snapshotChanges().pipe(
        //         map(items => items.map(item => DevotionCollection.init(item.payload.doc.data()))),
        //         // tap(items => this.logFSResult<DevotionCollection>(Constants.COLLECTION_DEVOTION_COLLECTION, items)),
        //         take(1),
        //         distinctUntilChanged(),
        //     );
    }

    getDevotionCollectionContent(): Observable<any> {
        let url = `${Constants.API_MAIN}/posts?${this.wpEncode(213, 12, 'id', 'asc')}`;
        const options = { url: url};
        return from(CapacitorHttp.get(options)).pipe(
        // return this.httpClient.get<any>(url, httpOptions).pipe(
            map(response => {
                const items = response.data;
                return items.map(item => {
                    item.content.rendered = item.content.rendered
                        .replace(/https:\/\/www.wkphc.org\/app\/images\/devotions\//gm, 'https://zerex.net/app/wp-content/uploads/2021/01/')
                        .replace(/https:\/\/www.wkphc.org\/app\/images\/icon\//gm, 'https://zerex.net/app/wp-content/uploads/2021/01/');
                    return item.content.rendered;
                })
            }),
            catchError(this.handleError),
            take(1),
            distinctUntilChanged(),
        );
    }

    getDevotionCollectionObservable(): Observable<DevotionCollection[]> {
        return zip(
            this.getDevotionCollection(),
            this.getDevotionCollectionContent(),
        )
            .pipe(
                map(([items, posts]) => {
                    items.forEach((item,index) => {
                        item.content = posts[index];
                    });
                    return items;
                })
            );
    }

}
