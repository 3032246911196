<ion-card-content [style.font-size.px]="_size"
                  [ngStyle]="formatCard()">
    <div class="vh-center" *ngIf="_item['icon']">
        <img [src]="_item['icon']" />
    </div>
    <div>
        <ion-card-title [style.font-size.px]="_size" [innerHTML]="_item['title']"></ion-card-title>
        <div class="excerpt" [innerHTML]="_item['excerpt']"></div>
    </div>
    <div class="time-label">
        <ion-icon class="icon-left" [name]="'time-outline'"></ion-icon>
        <span [innerHTML]="formatDateTime(_item['release_time'])"></span>
    </div>
    <div class="vh-center item-status">
        <ion-icon *ngIf="_item['read']" [name]="'mail-open'" [size]="'large'" [color]="'medium'"></ion-icon>
        <ion-icon *ngIf="!_item['read']" [name]="'mail-unread'" [size]="'large'" [color]="'secondary'"></ion-icon>
    </div>
</ion-card-content>
