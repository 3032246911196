import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
    selector: '[roundedCorner]'
})
export class RoundedCornerDirective implements OnInit {

    @Input() corner: string;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {
        this.corner = '20px';
    }

    ngOnInit(): void {
        this.renderer.setStyle(
            this.elementRef.nativeElement,
            'border-radius', this.corner
        );
    }

}
