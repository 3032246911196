<ion-item-divider class="tabs-header" [sticky]="true">
    <div
        class="tab-label"
        (click)="selectTab(item)"
        [class.active]="activeTab === item"
        *ngFor="let item of tabItems$ | async; trackBy: trackByFn;"
    >
        <ng-container *ngIf="item.labelComponent">
            <ng-container *ngTemplateOutlet="item.labelComponent.labelContent">
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!item.labelComponent">
            <div [innerHTML]="item.label"></div>
        </ng-container>
    </div>
</ion-item-divider>
<ion-content class="tabs-body">
    <ng-container *ngIf="activeTab && activeTab.bodyComponent">
        <ng-container *ngTemplateOutlet="activeTab.bodyComponent.bodyContent">
        </ng-container>
    </ng-container>
</ion-content>
