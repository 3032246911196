import {Injectable} from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore } from '@angular/fire/firestore';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {HymnCategory} from '../../models/hymn-category.model';
import {HymnPost} from '../../models/hymn-post.model';
import {Observable, from} from 'rxjs';
import {catchError, distinctUntilChanged, map, retry, take, tap} from 'rxjs/operators';
import { CapacitorHttp } from '@capacitor/core';

const httpOptions = {
    headers: new HttpHeaders({
    })
};

@Injectable({
    providedIn: 'root'
})
export class HymnService extends BaseService {

    constructor(
        // protected http        : HTTP,
        protected httpClient  : HttpClient,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
    }

    fetchHymns(url: string) {
        const options = { url: url};
        return from(CapacitorHttp.get(options)).pipe(        
        // return this.httpClient.get<HymnPost[]>(url, httpOptions).pipe(
            map(response => {
                const items:HymnPost[] = response.data;
                return items.map((item) => HymnPost.init(item))
            }),
            catchError(this.handleError),
            take(1),
            retry(2),
            distinctUntilChanged(),
        );
    }

    /* ==================================================================================================== */

    /* CATEGORY */
    getHymnCategories() : Observable<HymnCategory[]> {
        let url = Constants.API_HYMN + '/categories?per_page=40';
        const options = { url: url};
        return from(CapacitorHttp.get(options)).pipe(
        // return this.httpClient.get<HymnCategory[]>(url, httpOptions)
            // .pipe(
                map(response => {
                    const items:HymnCategory[] = response.data;
                    return items.map(item => HymnCategory.init(item))
                }),
                catchError(this.handleError),
                take(1),
                distinctUntilChanged(),
            );
    }

    /* ==================================================================================================== */

    /* HYMN */

    getHymns(args: any): Observable<any> {
        let url = Constants.API_HYMN + `/posts?${this.wpEncode(args.categories, args.amount)}`;
        const options = { url: url};
        return from(CapacitorHttp.get(options)).pipe(
        // return this.httpClient.get(url, httpOptions).pipe(
            map(response => this.extractData(response.data)),
            catchError(this.handleError)
        );
    }
    getRandomHymns(amount: number = 60): Observable<any> {
        // let url = `${Constants.API_HYMN}/posts?${this.wpEncode(-1, 100, '', 'asc')}`;
        let pageCount  = Math.floor(amount / 100);
        let pagination = (pageCount > 0) ? `&page=${pageCount}` : '';

        let url = `${Constants.API_HYMN}/posts?${this.wpEncode(-1, amount, '')}${pagination}`;
        return this.fetchHymns(url);
    }
    searchHymn(args: any) {
        let url = Constants.API_HYMN + `/posts?search=${args.query}&per_page=100`;
        return this.fetchHymns(url);
    }

    getHymnsByCategory(args: any) {
        let url = Constants.API_HYMN + `/posts?categories=${args.categories}&per_page=100`;
        return this.fetchHymns(url);
    }

    getHymnDetail(args: any): Observable<any> {
        let url = Constants.API_HYMN + `/posts/${args.id}`;
        const options = { url: url};
        return from(CapacitorHttp.get(options)).pipe(
        // return this.httpClient.get(url, httpOptions).pipe(
            map(response => this.extractData(response.data)),
            catchError(this.handleError)
        );
    }
}
