import { Injectable } from '@angular/core';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {HttpClient} from '@angular/common/http';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore, collection, collectionData } from '@angular/fire/firestore';

import * as Constants from '../../configs/constants';
import {BaseService} from '../../core/services/baseService';
import {DateService} from '../../core/services/dateService';
import {DataService} from '../../core/services/dataService';
import {LogService} from '../logService';
import {Alert} from '../../models/alert.model';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertService extends BaseService {

    collection_key: string;

    constructor(
//        protected http        : HTTP,
        protected httpClient  : HttpClient,
//         protected fireStore   : AngularFirestore,
        protected fireStore   : Firestore,
        protected dateService : DateService,
        protected dataService : DataService,
        protected logService  : LogService,
    ) {
        super(httpClient, fireStore, dateService, dataService, logService);
        this.collection_key = Constants.COLLECTION_ALERTS;
    }

    getAlerts(): Observable<Alert[]> {
        return collectionData(collection(this.fireStore, this.collection_key)).pipe(
            map(items => items.map(value => Alert.init(value))),
            // tap(items => this.logFSResult<Alert>(this.collection_key, items)),
            distinctUntilChanged(),
        );
        
        // return this.fireStore
        //     .collection<Alert>(this.collection_key, ref => ref.orderBy('id', 'asc'))
        //     .snapshotChanges().pipe(
        //         map(items => items.map(value => Alert.init(value.payload.doc.data()))),
        //         // tap(items => this.logFSResult<Alert>(this.collection_key, items)),
        //         distinctUntilChanged(),
        //     );
    }

}
