import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {Observable, of, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
// import {environment} from '../../../environments/environment';
/* ==================================================================================================== */
/* Models */
import * as Constants from '../../configs/constants';
import {DateService} from './dateService';
import {Alert} from '../../models/alert.model';
import {Meeting} from '../../models/meeting.model';
import {Notification} from '../../models/notification.model';
import {Banner} from '../../models/banner.model';
import {Grid} from '../../models/grid.model';
import {Poster} from '../../models/poster.model';
import {Verse} from '../../models/verse.model';
import {Devotion} from '../../models/devotion.model';
import {Announcement} from '../../models/announcement.model';
import {Sharing} from '../../models/sharing.model';
import {DevotionCollection} from '../../models/devotion-collection.model';
import {LentDate} from '../../models/lent-date.model';
import {LentCard} from '../../models/lent-card.model';
import {LentEntry} from '../../models/lent-entry.model';
import {LentArchive} from '../../models/lent-archive.model';
import {User} from '../../models/user.model';
import {ServerLog} from '../../models/server-log.model';
import {Level} from '../../models/level.model';
import {Prayer} from '../../models/prayer.model';

@Injectable({
    providedIn: 'root',
})
export class DataService {

    // ---------- Flags ---------- //
    isProduction  : boolean;
    isPrompting   : boolean;
    updatePending : boolean;
    // ---------- Local Data ---------- //
    currentUser$ : Observable<User>;
    festivalDays : LentDate[];
    lentCalendar : LentDate[][];
    serverLog$   : Observable<ServerLog[]>;
    levels       : Level[];
    api_env      : string;
    // ---------- Firestore Data ---------- //
    apiControls$   : Observable<string[]>;
    alerts$        : Observable<Alert[]>;
    meetings$      : Observable<Meeting[]>;
    notifications$ : Observable<Notification[]>;
    homeBanners$   : Observable<Banner[]>;
    homeGrids$     : Observable<Grid[]>;
    eventGrids$    : Observable<Grid[]>;
    verses$        : Observable<Verse[]>;
    lentDates      : Observable<LentDate[]>;
    lentRecords    : Observable<LentEntry[][]>;
    lentArchive    : Observable<LentArchive>;
    lentCard$      : Observable<LentCard>
    lentRecord$    : Observable<LentEntry[][]>;
    recordSub      : Subscription;
    festivalRes$   : Observable<any>;
    levels$        : Observable<any>;
    filters$       : Observable<any>;
    // ---------- Server Data ---------- //
    posters$            : Observable<Poster[]>;
    devotions$          : Observable<Devotion[]>;
    hymnDevotions$      : Observable<Devotion[]>;
    devotionCollection$ : Observable<DevotionCollection[]>;
    announcements$      : Observable<Announcement[]>;
    pastorSharing$      : Observable<Sharing[]>;
    vhSharing$          : Observable<Sharing[]>;
    weeklyPrayers$      : Observable<Prayer[]>;
    weeklyItems$        : Observable<Prayer[]>;

    constructor(
        private storage    : Storage     ,
        public dateService : DateService ,
    ) {
        this.currentUser$   = of(User.init({}));
        this.lentCard$      = of(LentCard.init(null));
        this.lentRecord$    = of(Array(Constants.LENT_LENGTH).fill([]));
        this.lentRecords    = of(Array(Constants.LENT_LENGTH).fill([]));
        this.serverLog$     = of([]);
        this.notifications$ = of([]);
        this.init();

        // ---------- Fetch Global Vars ---------- //
        // TODO : toggle admin filter, show production toggle
        // isProduction = false = admin testing
        // isProduction = true  = production
        this.isProduction = false;
        // this.isProduction  = true;
        this.isPrompting   = false;
        this.updatePending = false;
        this.api_env = Constants.APP_ENV.PROD;
        // this.api_env = Constants.APP_ENV.DEV;
    }

    // /* ================================================== */ //
    /* Basics */

    async init() {
        const storage = await this.storage.create();
        this.storage = storage;       
    }

    async setData(key, value) {
        await this.storage.set(key, value);
    }

    async getData(key) {
        return await this.storage.get(key);
    }

    async removeData(key) {
        return await this.storage.remove(key);
    }

    /* Utils */

    hasValues(content: any) {
        return content !== undefined && content !== null && content.length !== 0;
    }

    checkExists(array, value) {
        return array.includes(value);
    }

    checkEquals(array1, array2) {
        return JSON.stringify(array1)===JSON.stringify(array2);
    }

    compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    mergeArrays(array1, array2) {
        return array1.map(item => array2.find(item2 => item.id === item2.id) || item);
    }

    mergeFields(array1, array2, fields) {
        return array1.map(item => {
            let target = array2.find(item2 => item.id === item2.id) || item;
            for (let pos = 0; pos < fields.length; pos++) {
                item[fields[pos]] = target[fields[pos]];
            }
            return item;
        });
    }

    arrayDeDuplicate(array1, array2) {
        // let result = _.unionWith(array1, array2, function(a, b){ return a.id == b.id });
        // return result;
    }

    getAllUrlParams(url: string) {
        // get query string from url (optional) or window
        let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
        const obj = {};

        if (queryString) {
            queryString = queryString.split('#')[0];
            let arr = queryString.split('&');

            for (let i = 0; i < arr.length; i++) {
                let a = arr[i].split('=');

                let paramName = a[0];
                let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

                paramName = paramName.toLowerCase();
                if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

                if (paramName.match(/\[(\d+)?\]$/)) {
                    let key = paramName.replace(/\[(\d+)?\]/, '');
                    if (!obj[key]) obj[key] = [];

                    if (paramName.match(/\[\d+\]$/)) {
                        let index = /\[(\d+)\]/.exec(paramName)[1];
                        obj[key][index] = paramValue;
                    } else {
                        obj[key].push(paramValue);
                    }
                } else {
                    if (!obj[paramName]) {
                        obj[paramName] = paramValue;
                    } else if (obj[paramName] && typeof obj[paramName] === 'string'){
                        obj[paramName] = [obj[paramName]];
                        obj[paramName].push(paramValue);
                    } else {
                        obj[paramName].push(paramValue);
                    }
                }
            }
        }

        return obj;
    }

    luhn_checksum(code) {
        let len = code.length;
        let parity = len % 2;
        let sum = 0;
        for (let i = len - 1; i >= 0; i--) {
            let d = parseInt(code.charAt(i));
            if (i % 2 == parity) {
                d *= 2
            }
            if (d > 9) {
                d -= 9
            }
            sum += d
        }
        return sum % 10
    }
    luhn_caclulate(partcode) {
        let checksum = this.luhn_checksum(partcode + "0");
        return checksum == 0 ? 0 : 10 - checksum
    }
    luhn_validate(fullcode) {
        return this.luhn_checksum(fullcode) == 0;
    }

    generateID() {
        let random = Math.floor(Date.now() * Math.random()).toString();
        let checkDigit = this.luhn_caclulate(random);
        let fullDigits = `${checkDigit}${random}`;
        return `${fullDigits}_${random}`;
    }

    /**
     * Filter items based on "show" attribute and "release_time" + "expired_time".
     * For development environment, only "show" will be considered.
     * @param array Elements from firestore.
     * @returns 
     */
    filterValidElements(array: any) {
        return environment.production ? array.filter(item => item.show && this.dateService.validDate(item.release_time, item.expired_time)) : array.filter(item => item.show);
        // return array.filter(item => item.show && this.dateService.validDate(item.release_time, item.expired_time));
    }

    // /* ================================================== */ //
    /* API */

    getAPIEnv() {
        // if (this.isProduction) {
        if (environment.production) {
            this.api_env = Constants.APP_ENV.PROD;
        } else {
            this.api_env = Constants.APP_ENV.DEV;
        }
        return this.api_env;
    }

    // /* ================================================== */ //
    /* User */

    // public getCurrentUser() {
    //     return this.currentUser$
    //         .pipe(take(1))
    //         .subscribe(user => user);
    // }

    // public refreshUser(currentUser: User) {
    //     this.currentUser$
    //         .subscribe(user => {
    //             user.id          = currentUser.id;
    //             user.username    = currentUser.username;
    //             user.email       = currentUser.email;
    //             user.phone       = currentUser.phone;
    //             user.fullname    = currentUser.fullname;
    //             user.family      = currentUser.family;
    //             user.unit        = currentUser.unit;
    //             user.identity    = currentUser.identity;
    //             user.barcode     = currentUser.barcode;
    //             user.balance     = currentUser.balance;
    //             user.last_login  = currentUser.last_login;
    //             user.last_update = currentUser.last_update;
    //         });
    // }

    // public updateArray<Type>(array: Type[], key: string) : Type[] {
    //     let result = [];
    //     this.getData(key)
    //         .then((items: Type[]) => {
    //             if (items !== null) {
    //                 if (_.isEqual(classToPlain(array), items)) {
    //                     result = items;
    //                 } else {
    //                     result = Object.assign([], {...items, ...array});
    //                     this.setData(key, result).then(() => {});
    //                 }
    //                 return result;
    //             }
    //         });
    //     return result;
    // }

}
