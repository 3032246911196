export class ShareItem {
    title:   string;
    content: string;
    image:   string;
    link:    string;

    constructor(title?: string, content?: string, image?: string, link?: string) {
        this.title   = title;
        this.content = content;
        this.image   = image;
        this.link    = link;
    }
}
