import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';

import * as Constants from '../../../configs/constants';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'page-video-modal',
    templateUrl: './video-modal.html',
    styleUrls: ['./video-modal.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VideoModalPage implements OnInit {

    params : any;
    url    : SafeResourceUrl;

    constructor(
        private modalCtrl   : ModalController,
        private navParams   : NavParams,
        private sanitizer   : DomSanitizer,
    ) {
    }

    ngOnInit() {
        this.params = this.navParams.get('params');
        this.url    = this.sanitizer.bypassSecurityTrustResourceUrl(this.params['url']);
    }

    close() {
        this.modalCtrl.dismiss();
    }

}
