export class Meeting {
    doc_id         : string  ;
    id             : number  ;
    level          : number  ;
    label          : string  ;
    title          : string  ;
    thumbnail      : string  ;
    link           : string  ;
    show           : boolean ;
    release_time   : string  ;
    expired_time   : string  ;
    icon           : string  ;
    excerpt        : string  ;
    action_buttons : any     ;

    constructor(doc_id?: string, id?: number, level?: number, label?: string, title?: string, thumbnail?: string, link?: string, show?: boolean, release_time?: string, expired_time?: string) {
        this.doc_id         = doc_id       ;
        this.id             = id           ;
        this.level          = level        ;
        this.label          = label        ;
        this.title          = title        ;
        this.thumbnail      = thumbnail    ;
        this.link           = link         ;
        this.show           = show         ;
        this.release_time   = release_time ;
        this.expired_time   = expired_time ;
        this.icon           = ''           ;
        this.excerpt        = ''           ;
        this.action_buttons = []         ;
    }

    static init(value: any) {
        let instance = new Meeting();
        instance.doc_id         = value.doc_id         || '';
        instance.id             = value.id             || '';
        instance.level          = (value.level > 0) ? value.level : (value.level === 0) ? 0 : -1;
        instance.label          = value.label          || '';
        instance.title          = value.title          || '';
        instance.thumbnail      = value.thumbnail      || '';
        instance.link           = value.link           || '';
        instance.show           = value.show           || false;
        instance.release_time   = value.release_time   || '';
        instance.expired_time   = value.expired_time   || '';
        instance.icon           = value.icon           || '';
        instance.action_buttons = instance.configButtons(value.action_buttons);
        return instance;
    }

    configButtons(buttons: string) {
        let result: ActionButtons[];
        try {
            buttons = buttons.replace(/'/gm, '"');
            result = JSON.parse(buttons);
            return result;
        } catch (e) {
            result = [];
            return result;
        }
    }
}

interface ActionButtons {
    name: string;
    link: string;
}
