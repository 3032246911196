import {Moment} from 'moment';
import * as Constants from '../configs/constants';
import {LentResource} from './lent-resource.model';
import {LentMeeting} from './lent-meeting.model';

export class LentDate {
    index         : number;
    momentDate    : Moment;
    weekday       : number;
    dirty         : boolean;

    resources : LentResource[];
    meetings  : LentMeeting[];
    practices : LentResource[];

    constructor(index: number, momentDate: Moment, weekday: number, dirty: boolean) {
        this.index         = index;
        this.momentDate    = momentDate;
        this.weekday       = weekday;
        this.dirty         = dirty;
        this.resources     = [];
        this.meetings      = [];
        this.practices     = [];
    }

    getIndex(): number {
        return this.index;
    }

    static init(value: any) {
        return new LentDate(
            value.index || -1,
            value.momentDate || null,
            value.weekday || -1,
            value.dirty || false,
        );
    }

    // /* ================================================== */ //
    /* Date */

    getDateEN(): string {
        return this.momentDate.format(Constants.DATE_FORMAT_EN);
    }

    getDateTC(): string {
        return this.momentDate.format(Constants.DATE_FORMAT_TC);
    }

    getDateShort(): string {
        return this.momentDate.format(Constants.DATE_FORMAT_SHORT);
    }

    getDateID(): string {
        return this.momentDate.format(Constants.DATE_FORMAT_ID_SHORT);
    }

    getDay(): string {
        return this.momentDate.format('D');
    }

    // /* ================================================== */ //
    /* Resource */


}
