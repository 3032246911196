<ion-card *ngFor="let item of _items | filterBy: ['type']: 'list' | orderBy: '-order'; trackBy: trackById;" class="meeting-item" roundedCorner>
    <img [src]="item['thumbnail']" *ngIf="item['thumbnail']" (click)="clickLink(item['url'])">
    <ion-card-content [style.font-size.px]="_size">
        <ion-row>
            <ion-col class="title-col" (click)="clickLink(item['url'])">
                <div>
                    <ion-card-title [style.font-size.px]="_size+2" [innerHTML]="item['title']"></ion-card-title>
                    <ion-card-subtitle [style.font-size.px]="_size">
                        <div class="subtitle" [innerHTML]="item['subtitle']"></div>
                        <div class="excerpt" [innerHTML]="item['excerpt']"></div>
                    </ion-card-subtitle>
                </div>
            </ion-col>
        </ion-row>
        <ion-row class="action-row" *ngIf="item['buttons']">
            <ion-col *ngFor="let button of item['buttons']" (click)="clickLink(button['link'])"
                     [ngSwitch]="button['link'] | checkLink">
                <img class="action-icon" [src]="'assets/icons/custom/youtube.svg'" [alt]="" *ngSwitchCase="'video'">
                <img class="action-icon" [src]="'assets/icons/custom/soundcloud.svg'" [alt]="" *ngSwitchCase="'audio'">
                <img class="action-icon" [src]="'assets/icons/custom/document.svg'" [alt]="" *ngSwitchCase="'document'">
                <img class="action-icon" [src]="'assets/icons/custom/link.svg'" [alt]="" *ngSwitchCase="'link'">
                <div [innerHTML]="button['name']" *ngSwitchDefault></div>
            </ion-col>
        </ion-row>
    </ion-card-content>
</ion-card>


