import {Component, Input, OnInit} from '@angular/core';
import {FestivalService} from '../../../../providers/apiServices/festivalService';
import {LentCard} from '../../../../models/lent-card.model';

@Component({
    selector: 'app-summary-card',
    templateUrl: './summary-card.component.html',
    styleUrls: ['./summary-card.component.scss'],
})
export class SummaryCardComponent implements OnInit {

    @Input('card') card: LentCard;
    @Input('title') title: string;

    constructor(
        public festivalService: FestivalService
    ) {
    }

    ngOnInit() {
    }

}
