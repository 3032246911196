import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'image-grid',
    templateUrl: './image-grid.component.html',
    styleUrls: ['./image-grid.component.scss'],
})
export class ImageGridComponent {

    _icon: string;

    @Input()
    set icon(value: string) {
        this._icon = (value !== undefined && value !== null) ? value : '';
    }
    // @Input()
    // set link(value: string) {
    //     this._link = (value !== undefined && value !== null) ? value : '';
    // }
    @Output('openLink') linkClicked = new EventEmitter();

    constructor(
    ) {}

    openLink() {
        this.linkClicked.emit();
    }

    // openLink(link: string) {
    //     let params    = { url: link };
    //     let url_parts = link.split(',');
    //     switch (url_parts[0]) {
    //         case 'route':
    //             this.router.navigate([url_parts[1], params]);
    //             break;
    //         case 'iab':
    //             if (url_parts[1] !== '' && url_parts[1].length > 0) {
    //                 this.iab.create(url_parts[1], '_system', 'location=no');
    //             }
    //             break;
    //         case 'web':
    //             this.router.navigate(['/web', params]);
    //             break;
    //     }
    // }



}
